import React, { FC, ReactElement } from 'react';

import { CheckboxProps } from './types';
import { Label, HiddenCheckbox, StyledCheckbox, InputWrapper } from './styles';

export const Checkbox: FC<CheckboxProps> = ({ children, onChange, ...props }): ReactElement => (
  <Label>
    <InputWrapper>
      <HiddenCheckbox type="checkbox" onChange={onChange} {...props} />
      <StyledCheckbox />
    </InputWrapper>
    {children}
  </Label>
);
