import styled from 'styled-components';
import BaseButton from '../../common/BaseButton';

export const ActionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  text-align: right;

  & > div {
    flex-grow: 1;
  }
`;
export const SuccessMessage = styled.span`
  margin-top: 20px;
  color: ${({ theme }) => theme.palette.success};
`;
export const ResetPasswordForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  margin: 48px auto 0 auto;

  & > label {
    width: 100%;
  }
`;
export const ResetPasswordButton = styled(BaseButton)`
  background-color: ${({ theme }) => theme.palette.brand};
  color: ${({ theme }) => theme.palette.gray[300]};
  padding: 12px 24px;
  margin-top: 16px;
`;
