import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import Input from 'components/common/Input';
import BaseButton from 'components/common/BaseButton';
import { FormErrors, StatusCodes } from 'utils/constants';

import useAuth from 'hooks/useAuth';
import { changePasswordApi } from 'components/Authentication/ChangePassword/ChangePasswordApi';
import { ActionWrapper } from '../Login/styles';
import { useIsMounted } from 'hooks';

export const ChangePassword: React.FC<{ closeModal: () => void }> = ({ closeModal }) => {
  const isMounted = useIsMounted();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { register, handleSubmit, errors, setError } = useForm();
  const { authorize } = useAuth();

  const changePassword: SubmitHandler<{
    oldPassword: string;
    newPassword: string;
    repeatPassword: string;
  }> = async formData => {
    if (formData.newPassword !== formData.repeatPassword) {
      setError('repeatPassword', { message: 'Password do not match!' });
    } else {
      setIsLoading(true);
      try {
        await authorize(() => changePasswordApi.changePassword(formData));
        closeModal();
      } catch (error) {
        if (error === StatusCodes.UNPROCESSABLE_ENTITY) {
          setError('oldPassword', {
            type: 'manual',
            message: 'Incorrect password!',
          });
        }
      } finally {
        if (isMounted()) {
          setIsLoading(false);
        }
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(changePassword)}>
      <Input
        id="oldPassword"
        name="oldPassword"
        aria-label="old-password-input"
        placeholder="Old Password"
        autoComplete="none"
        ref={register({
          required: FormErrors.REQUIRED_FIELD,
        })}
        type="password"
        error={errors.oldPassword && errors.oldPassword.message}
      />
      <Input
        id="newPassword"
        name="newPassword"
        placeholder="New Password"
        autoComplete="none"
        aria-label="new-password-input"
        maxLength={30}
        ref={register({
          required: FormErrors.REQUIRED_FIELD,
          minLength: {
            value: 6,
            message: 'Min 6 Characters!',
          },
          maxLength: {
            value: 30,
            message: 'Max 30 Characters!',
          },
        })}
        type="password"
        error={errors.newPassword && errors.newPassword.message}
      />
      <Input
        id="repeatPassword"
        name="repeatPassword"
        placeholder="Repeat Password"
        autoComplete="none"
        aria-label="repeat-password-input"
        maxLength={30}
        ref={register({
          required: FormErrors.REQUIRED_FIELD,
        })}
        type="password"
        error={errors.repeatPassword && errors.repeatPassword.message}
      />
      <ActionWrapper>
        <BaseButton type="submit" disabled={isLoading}>
          Confirm
        </BaseButton>
      </ActionWrapper>
    </form>
  );
};
