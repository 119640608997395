import styled from 'styled-components';

export const VisibilityController = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
  text-align: center;
  max-width: 80%;
`;

export const InstrumentSwitch = styled.button<{ active: boolean }>`
  font-size: 0.9rem;
  background-color: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
  ${({ theme, active }) => `
    text-decoration: ${active ? 'none' : 'line-through'};
    color: ${theme.palette.white};
  `}
`;

export const InstrumentIndicator = styled.div<{ color: string }>`
  position: relative;
  top: 50%;
  display: inline-block;
  width: 20px;
  height: 3px;
  border-radius: 3px;
  transform: translateY(-50%);
  ${({ color, theme }) => `
    margin-right: ${theme.spacing(1)};
    background-color: ${color};
  `}
`;

export const PercentChangeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;
