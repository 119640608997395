import useSWR from 'swr';
import { IDeviationResponse } from 'api/types';
import { deviationApi } from './DeviationApi';
import { useDataVariantParam } from 'hooks';
import { IDeviationKeys } from './types';
import { SWRConfiguration } from 'swr/dist/types';

export const useDeviationApi = () => {
  const { dataVariant } = useDataVariantParam();
  const useDeviationData = (keys: IDeviationKeys, config?: SWRConfiguration) => {
    const { key, ...params } = keys;
    return useSWR<IDeviationResponse>(
      [key || 'get_deviation', ...Object.values(params), dataVariant.value],
      () => deviationApi.getDeviation(params),
      config
    );
  };
  return { useDeviationData };
};
