import styled from 'styled-components';
import BaseButton from 'components/common/BaseButton';

export const Wrapper = styled.div`
  flex: 0 0 auto;
  @media (max-width: 1439px) {
    margin-top: 32px;
    margin-left: -15px;
  }
`;

export const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(1)};

  * {
    &:first-child {
      margin-right: ${({ theme }) => theme.spacing(12)};
    }
  }
`;

export const MarketRiskButton = styled(BaseButton)`
  ${(props: any) =>
    props.active &&
    `
    color: gray;
  `}
  margin-right: 5px;
  font-size: 0.875rem;
`;

export const CreditRiskButton = styled(BaseButton)`
  margin-left: 5px;
  font-size: 0.875rem;
`;

export const Separator = styled.p`
  margin: 0 10px;
`;
