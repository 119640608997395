import React, { useState } from 'react';
import Input from '../../common/Input';
import { FormErrors } from 'utils/constants';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAuth, useQuery } from 'hooks';
import { changePasswordApi } from '../ChangePassword/ChangePasswordApi';
import { ResetPasswordButton, ResetPasswordForm } from './styles';

export const ResetPassword: React.FC = () => {
  const query = useQuery();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { register, handleSubmit, errors, setError } = useForm();
  const { authorize } = useAuth();

  const changePassword: SubmitHandler<{ password: string; repeatPassword: string }> = async (
    formData
  ): Promise<void> => {
    const { password, repeatPassword } = formData;

    if (password !== repeatPassword) {
      setError('repeatPassword', { type: 'manual', message: 'Passwords do not match!' });
    } else {
      setIsLoading(true);
      try {
        await authorize(() => changePasswordApi.resetPassword({ password, code: query.get('code') || '' }));
      } catch (error) {
        if (error && error.response) {
          setError('repeatPassword', {
            type: 'manual',
            message: error.response.data.message,
          });
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <ResetPasswordForm onSubmit={handleSubmit(changePassword)}>
      <p>Please choose your new password:</p>
      <Input
        id="password"
        name="password"
        placeholder="Password"
        aria-label="password-input"
        maxLength={30}
        ref={register({
          required: FormErrors.REQUIRED_FIELD,
          minLength: {
            value: 6,
            message: 'Min 6 Characters!',
          },
          maxLength: {
            value: 30,
            message: 'Max 30 Characters!',
          },
        })}
        type="password"
        error={errors.password && errors.password.message}
      />

      <Input
        id="repeatPassword"
        name="repeatPassword"
        placeholder="Repeat Password"
        aria-label="repeat-password-input"
        ref={register({ required: FormErrors.REQUIRED_FIELD })}
        type="password"
        error={errors.repeatPassword && errors.repeatPassword.message}
      />

      <ResetPasswordButton type="submit" disabled={isLoading}>
        Reset password
      </ResetPasswordButton>
    </ResetPasswordForm>
  );
};
