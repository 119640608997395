import React from 'react';
import {
  StyledMedianInfo,
  StyledMedianColumn,
  StyledMedianDropdownContent,
  StyledMedianWrapper,
} from './Deviation.styles';
import { MedianDropdown } from './MedianDropdown';
import { IDeviationResponse } from 'api/types';
import { formatRange } from 'utils/Date';

interface MedianInfoProps {
  data: IDeviationResponse;
}

export const MedianInfo: React.FC<MedianInfoProps> = ({ data }) => {
  const { last_weeks } = data;
  return (
    <StyledMedianWrapper>
      <StyledMedianInfo data-testid="deviation-median-column">
        <h3>Median</h3>
        <StyledMedianColumn>
          <MedianDropdown label="Last 4 weeks">
            {last_weeks.map(week => (
              <StyledMedianDropdownContent value={week.value} key={week.value}>
                {formatRange(new Date(week.start_date), new Date(week.end_date))}: <span>{week.value}</span>
              </StyledMedianDropdownContent>
            ))}
          </MedianDropdown>
          <MedianDropdown label="3 Months">
            <StyledMedianDropdownContent value={data.six_months}>
              Median value: <span>{data.three_months}</span>
            </StyledMedianDropdownContent>
          </MedianDropdown>
          <MedianDropdown label="6 Months">
            <StyledMedianDropdownContent value={data.six_months}>
              Median value: <span>{data.six_months}</span>
            </StyledMedianDropdownContent>
          </MedianDropdown>
        </StyledMedianColumn>
      </StyledMedianInfo>
    </StyledMedianWrapper>
  );
};
