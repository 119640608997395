import styled from 'styled-components';
import { YAxis as ReYAxis, XAxis as ReXAxis } from 'recharts';
import { rgba } from 'polished';

export const YAxis = styled(ReYAxis)`
  fill: #fff;
`;

export const XAxis = styled(ReXAxis)`
  fill: #fff;
`;

export const TooltipWrapper = styled.div`
  font-size: 0.9rem;
  ${({ theme }) => `
    padding: ${theme.spacing(1)};
    background-color: ${rgba(theme.palette.black, 0.8)};
  `}
`;
