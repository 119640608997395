import { Reducer, useReducer } from 'react';
import { Class, useLocalStorage } from './useLocalStorage';

export function useReducerWithLocalStorage<S, A>(
  key: string,
  initialValue: S,
  reducer: Reducer<S, A>,
  TypeConstructor?: Class<S>
) {
  const [localStorageState, setLocalStorageState] = useLocalStorage(key, initialValue, TypeConstructor);

  return useReducer((state: S, action: A) => {
    const newState = reducer(state, action);
    setLocalStorageState(newState);
    return newState;
  }, localStorageState);
}
