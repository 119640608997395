import Api from 'utils/Api';

class LoginApi extends Api {
  async login({ email, password }: { email: string; password: string }) {
    const response = await this.api.post('/v1/login', { email, password });
    return response.data;
  }
}

export default new LoginApi();
