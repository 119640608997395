import { useAuth, useDataVariantParam } from 'hooks';
import { ISWRKeys } from '../../types';
import { SWRConfiguration } from 'swr/dist/types';
import useSWR from 'swr';
import { IWatchlistResponse } from 'api/types';
import api from './WatchlistApi';
import { useMemo } from 'react';

export const useWatchlistApi = () => {
  const { dataVariant } = useDataVariantParam();
  const { authorized } = useAuth();
  const useWatchlistsData = (keys?: ISWRKeys, config?: SWRConfiguration) => {
    const { data, ...rest } = useSWR<IWatchlistResponse[]>(
      [keys?.key || 'get_watchlists', dataVariant.value],
      () => (authorized ? api.listWatchLists() : api.getDemoWatchLists()),
      config
    );

    const filtered = useMemo(() => {
      if (!data) return undefined;
      return data.map(el => ({
        ...el,
        instruments: el.instruments.filter(instruments => instruments.instrument_x && instruments.instrument_y),
      }));
    }, [data]);
    return { data: filtered, ...rest };
  };
  return { useWatchlistsData };
};
