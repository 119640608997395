import { IMetaDataResponse } from 'api/types';
import Api from 'utils/Api';

class MetadataApi extends Api {
  async getMetaData(): Promise<IMetaDataResponse> {
    try {
      const response = await this.api.get<IMetaDataResponse>('/v1/data_info');
      return response.data;
    } catch (error) {
      throw error.response.status;
    }
  }
}

export const metadataApi = new MetadataApi();
