import styled from 'styled-components';
import { Container } from 'react-grid-system';

export const MainContainer = styled(Container)`
  width: 1430px;
  margin: 0 auto;
  padding: 0 15px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
