import { IReturnsVsVolatilityResponse } from 'api/types';
import zipWith from 'lodash.zipwith';
import { min } from 'date-fns';

interface ReturnsVsVolatilityChartData {
  date: string;
  returns: string;
  volatility: string;
  priceChanges: string;
}

export class ReturnsVsVolatilityData {
  public static fromReturnsVsVolatilityResponse(returnsVsVolatilityResponse?: IReturnsVsVolatilityResponse) {
    if (returnsVsVolatilityResponse) {
      const { returns, volatility, price_changes: priceChanges } = returnsVsVolatilityResponse;
      const mergedReturnsVsVolatility = zipWith(returns, volatility, priceChanges, (r, v, p) => {
        return {
          date: r.date,
          returns: r.value,
          volatility: v.value,
          priceChanges: p.value,
        };
      }).reverse();
      return new ReturnsVsVolatilityData(mergedReturnsVsVolatility);
    }
    return new ReturnsVsVolatilityData([]);
  }

  private constructor(public data: ReturnsVsVolatilityChartData[]) {}

  get chartData() {
    return this.data;
  }

  get minDate() {
    return min(this.data.map(element => new Date(element.date)));
  }

  get csvData() {
    return this.data.map(element => [...Object.values(element)]);
  }

  get priceChanges() {
    return this.data.map(element => ({
      date: element.date,
      priceChanges: element.priceChanges,
    }));
  }

  get returns() {
    return this.data.map(element => ({
      date: element.date,
      returns: element.returns,
    }));
  }

  get volatility() {
    return this.data.map(element => ({
      date: element.date,
      volatility: element.volatility,
    }));
  }

  get priceChangesValues() {
    return this.priceChanges.map(e => parseFloat(e.priceChanges));
  }

  get returnsValues() {
    return this.returns.map(e => parseFloat(e.returns));
  }

  get volatilityValues() {
    return this.volatility.map(e => parseFloat(e.volatility));
  }

  get minPriceChange() {
    return Math.min(...this.priceChangesValues);
  }

  get maxPriceChange() {
    return Math.max(...this.priceChangesValues);
  }

  get minReturn() {
    return Math.min(...this.returnsValues);
  }

  get maxReturn() {
    return Math.max(...this.returnsValues);
  }

  get minVolatility() {
    return Math.min(...this.volatilityValues);
  }

  get maxVolatility() {
    return Math.max(...this.volatilityValues);
  }
}
