import React, { Suspense } from 'react';
import { lazyImport } from '../utils/lazyImport';
import { Navigate, Outlet } from 'react-router-dom';
import { About } from 'components/About';
import { HeatMapCorrelationSection } from 'components/common/HeatMapCorrelationSection';
import { MainContainer } from 'components/App/styles';
import { ResetPassword } from '../components/Authentication';

const { Contact } = lazyImport(() => import('components/Contact/Contact'), 'Contact');
const { Trial } = lazyImport(() => import('components/Trial/Trial'), 'Trial');
const { Tutorials } = lazyImport(() => import('components/Tutorials/Tutotrials'), 'Tutorials');
const { Team } = lazyImport(() => import('components/Team'), 'Team');

const PublicApp = () => {
  return (
    <MainContainer>
      <Suspense fallback="">
        <Outlet />
      </Suspense>
    </MainContainer>
  );
};

export const publicRoutes = [
  {
    path: '/',
    element: <PublicApp />,
    children: [
      {
        path: '/about',
        element: (
          <HeatMapCorrelationSection>
            <About />
          </HeatMapCorrelationSection>
        ),
      },
      {
        path: '/trial',
        element: (
          <HeatMapCorrelationSection>
            <Trial />
          </HeatMapCorrelationSection>
        ),
      },
      {
        path: '/team',
        element: (
          <HeatMapCorrelationSection>
            <Team />
          </HeatMapCorrelationSection>
        ),
      },
      {
        path: '/contact',
        element: (
          <HeatMapCorrelationSection>
            <Contact />
          </HeatMapCorrelationSection>
        ),
      },
      {
        path: '/tutorials',
        element: <Tutorials />,
      },
      {
        path: '/',
        element: <Navigate to="/about" />,
      },
    ],
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
  },
  {
    path: '*',
    element: <Navigate to="/about" />,
  },
];
