import useSWR from 'swr';
import { ITrendingResponse } from 'api/types';
import { trendingApi } from './TrendingApi';
import { ITrendingParams } from './types';
import { useDataVariantParam } from 'hooks';

export const useTrendingApi = () => {
  const { dataVariant } = useDataVariantParam();
  const useTrendingData = (keys: ITrendingParams) => {
    const { key, ...params } = keys;
    return useSWR<ITrendingResponse>([key || 'get_trending', ...Object.values(params), dataVariant.value], () =>
      trendingApi.getTrending(params.lowerRolling, params.higherRolling, params.watchlistId)
    );
  };

  return { useTrendingData };
};
