import React, { FC, ReactElement } from 'react';
import { Wrapper, Value, HeatMapTileRow } from './styles';
import { HeatMapTileProps } from './types';
import { useCalculatorFilter } from 'components/common/HeatMapCorrelation/FilterContext';
import ReactTooltip from 'react-tooltip';

const HeatMapTile: FC<HeatMapTileProps> = ({ data }): ReactElement => {
  const { selectedInstrument, setSelectedInstrument } = useCalculatorFilter();
  const { instrument_x, instrument_y, value, positive } = data;

  return (
    <HeatMapTileRow>
      <Wrapper
        positive={positive}
        onClick={() => setSelectedInstrument({ instrument_x, instrument_y })}
        active={
          selectedInstrument?.instrument_x.ticker === instrument_x.ticker &&
          selectedInstrument?.instrument_y.ticker === instrument_y.ticker
        }
        data-for="tool-tip"
        data-tip={`${instrument_x.full_name} <br/> ${Number(value).toFixed(2)} <br/>  ${instrument_y.full_name}`}
      >
        <div>{instrument_x.short_name}</div>
        <Value positive={positive}>{Number(value).toFixed(2)}</Value>
        <div>{instrument_y.short_name}</div>
      </Wrapper>
      <ReactTooltip
        place="top"
        type="dark"
        effect="float"
        id="tool-tip"
        className="heatmap-tooltip"
        html={true}
        getContent={dataTip => dataTip}
      />
    </HeatMapTileRow>
  );
};

export default HeatMapTile;
