interface MenuLink {
  name: string;
  path: string;
  target?: string;
  external: boolean;
}

export const burgerPrivateLinks: MenuLink[] = [
  {
    name: 'FAQ',
    path: '/faq',
    target: undefined,
    external: false,
  },
  {
    name: 'Tutorials',
    path: '/tutorials',
    target: undefined,
    external: false,
  },
  {
    name: 'Settings',
    path: '/settings',
    target: undefined,
    external: false,
  },
  {
    name: 'Alerts',
    path: '/alerts',
    target: undefined,
    external: false,
  },
];

export const privateLinks: MenuLink[] = [
  {
    name: 'The cutting edge',
    path: 'https://www.excaliburcuttingedge.com',
    target: '_blank',
    external: true,
  },
  {
    name: 'Pair Trading',
    path: '/pair-trading/cointegration',
    target: undefined,
    external: false,
  },
];

export const publicLinks: MenuLink[] = [
  {
    name: 'About',
    path: '/about',
    target: undefined,
    external: false,
  },
  {
    name: 'The cutting edge',
    path: 'https://www.excaliburcuttingedge.com',
    target: '_blank',
    external: true,
  },
];

export const burgerPublicLinks: MenuLink[] = [
  {
    name: 'Team',
    path: '/team',
    target: undefined,
    external: false,
  },
  {
    name: '14-day trial',
    path: '/trial',
    target: undefined,
    external: false,
  },
  {
    name: 'Contact us',
    path: '/contact',
    target: undefined,
    external: false,
  },
];
