import styled from 'styled-components';
import { Container, Row } from 'react-grid-system';
import themeBase from 'styles/theme';

export const StyledSelect = styled.div`
  margin-left: 10px;
`;

export const FiltersText = styled.span`
  padding-top: 9px;
`;
export const StyledCategoriesWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  @media (min-width: 1200px) and (max-width: 1500px) {
    min-width: 670px;
  }
`;
export const StyledCategoriesFilter = styled.div`
  margin-left: 10px;
  display: flex;
  flex-wrap: wrap;
`;

export const StyledContainer = styled(Container)`
  width: 100%;
  padding: 50px 0 50px 0;
`;

export const Title = styled.h1`
  padding: 0;
  color: ${({ theme }) => theme.palette.white};
  font-weight: 500;
  font-size: 1.25rem;
  font-family: ${({ theme }) => theme.fonts.body};
  text-align: left;
  text-transform: uppercase;
`;

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-left: ${({ theme }) => theme.spacing(2)};
  }

  margin-right: ${({ theme }) => theme.spacing(2)};
`;

export const chartStyles = {
  axis: {
    fill: themeBase.palette.white,
    fontSize: '0.75rem',
    color: themeBase.palette.white,
  },
};

export const Subtitle = styled(Row)`
  margin-bottom: 15px;
`;

export const SelectTitle = styled.div`
  color: ${({ theme }) => theme.palette.brand};
`;

export const selectStyles = {
  option: (styles: any, state: any) => {
    const nonSelectedBackground = state.isFocused ? '#aaa' : 'black';
    return {
      ...styles,
      background: state.isSelected ? 'white' : nonSelectedBackground,
      color: state.isSelected ? 'black' : 'white',
    };
  },
  control: (styles: any, state: any) => {
    return {
      ...styles,
      width: state.selectProps.width || '140px',
      background: 'black',
      border: '1px solid #aaa',
      borderRadius: '0',
    };
  },
  singleValue: (styles: any) => {
    return {
      ...styles,
      color: 'white',
    };
  },
  menu: (styles: any) => {
    return {
      ...styles,
      marginTop: 0,
      borderRadius: 0,
      background: 'black',
    };
  },
  input: (styles: any) => {
    return {
      ...styles,
      color: 'white',
    };
  },
};
