import useSWR from 'swr';
import { SWRConfiguration } from 'swr/dist/types';
import { useDataVariantParam } from 'hooks';
import { IInstrumentsResponse, regimeChangeApi } from './RegimeChangeApi';
import {
  IFrequencyResponse,
  IMACDResponse,
  IMarkovStateResponse,
  IMovingAveragesResponse,
  IMovingAveragesRSIResponse,
  IReturnsVsVolatilityResponse,
} from 'api/types';
import {
  IFrequencyDataParams,
  IMACDParams,
  IMarkovStateDataParams,
  IMovingAverageOptions,
  IMovingAveragesDataParams,
  IMovingAveragesRSIParams,
  IReturnsVsVolatilityParams,
  ISWRKeys,
} from './types';
import { useMemo } from 'react';
import { MACD } from './MACD/helpers';
import { MovingAveragesRSIData } from './MovingAveragesRSI/helpers';
import { ReturnsVsVolatilityData } from './ReturnsVsVolatility/helpers';
import { MovingAveragesData } from './MovingAverages/helpers';
import { MarkovState } from './MarkovState/helpers';
import { FrequencyData } from './Frequency/helpers';
import { InstrumentData } from './helpers';

const DEFAULT_OPTIONS: SWRConfiguration = {
  dedupingInterval: 20000,
};

export const useRegimeChangeApi = () => {
  const { dataVariant } = useDataVariantParam();

  const useInstrumentsData = (keys?: ISWRKeys, config?: SWRConfiguration) => {
    const { data, ...rest } = useSWR<IInstrumentsResponse[]>(
      keys?.key || 'get_instruments_for_regime_change',
      () => regimeChangeApi.getInstruments(),
      config
    );

    const instruments = InstrumentData.fromInstrumentResponse(data);

    return { data: instruments, ...rest };
  };

  const useMACDData = (keys: IMACDParams, config?: SWRConfiguration) => {
    const { key, ...params } = keys;
    const { data, ...rest } = useSWR<IMACDResponse>(
      [key || 'get_macd', ...Object.values(params), dataVariant.value],
      () => regimeChangeApi.getMACD(params),
      {
        ...DEFAULT_OPTIONS,
        ...config,
      }
    );
    const macd = useMemo(() => MACD.fromMACDResponse(data), [data]);

    return { data: macd, ...rest };
  };

  const useMovingAveragesRSIData = (keys: IMovingAveragesRSIParams, config?: SWRConfiguration) => {
    const { key, ...params } = keys;

    const { data, ...rest } = useSWR<IMovingAveragesRSIResponse>(
      [key || 'get_moving_averages_rsi', ...Object.values(params), dataVariant.value],
      () =>
        regimeChangeApi.getMovingAveragesRSI({
          ...params,
          movingAverages: params.movingAverages?.map((m: IMovingAverageOptions) => m.value),
        }),
      {
        ...DEFAULT_OPTIONS,
        ...config,
      }
    );

    const movingAveragesRSI = useMemo(() => MovingAveragesRSIData.fromMovingAveragesRSIResponse(data), [data]);

    return { data: movingAveragesRSI, ...rest };
  };
  const useReturnsVsVolatilityData = (keys: IReturnsVsVolatilityParams, config?: SWRConfiguration) => {
    const { key, ...params } = keys;

    const { data, ...rest } = useSWR<IReturnsVsVolatilityResponse>(
      [key || 'get_returns_volatility', ...Object.values(params), dataVariant.value],
      () => regimeChangeApi.getReturnsVolatility(params),
      {
        ...DEFAULT_OPTIONS,
        ...config,
      }
    );

    const returnsVolatilityData = useMemo(() => ReturnsVsVolatilityData.fromReturnsVsVolatilityResponse(data), [data]);

    return { data: returnsVolatilityData, ...rest };
  };
  const useMovingAveragesData = (keys: IMovingAveragesDataParams, config?: SWRConfiguration) => {
    const { key, ...params } = keys;
    const { data, ...rest } = useSWR<IMovingAveragesResponse>(
      [key || 'get_moving_averages', ...Object.values(params), dataVariant.value],
      () => regimeChangeApi.getMovingAverages(params),
      {
        ...DEFAULT_OPTIONS,
        ...config,
      }
    );

    const movingAverages = useMemo(() => MovingAveragesData.fromMovingAveragesResponse(data), [data]);

    return { data: movingAverages, ...rest };
  };
  const useMarkovStateData = (keys: IMarkovStateDataParams, config?: SWRConfiguration) => {
    const { key, ...params } = keys;

    const { data, ...rest } = useSWR<IMarkovStateResponse>(
      [key || 'get_markov_state', ...Object.values(params), dataVariant.value],
      () => regimeChangeApi.getMarkovState(params),
      {
        ...DEFAULT_OPTIONS,
        ...config,
      }
    );

    const regimeChange = useMemo(() => MarkovState.fromMarkovStateResponse(data), [data]);

    return { data: regimeChange, ...rest };
  };
  const useFrequencyData = (keys: IFrequencyDataParams, config?: SWRConfiguration) => {
    const { key, ...params } = keys;
    const { data, ...rest } = useSWR<IFrequencyResponse>(
      [key || 'get_frequency', ...Object.values(params), dataVariant.value],
      () => regimeChangeApi.getFrequency(params),
      {
        ...DEFAULT_OPTIONS,
        ...config,
      }
    );

    const frequency = useMemo(() => FrequencyData.fromFrequencyResponse(data), [data]);

    return { data: frequency, ...rest };
  };

  return {
    useMACDData,
    useMovingAveragesRSIData,
    useReturnsVsVolatilityData,
    useMovingAveragesData,
    useMarkovStateData,
    useFrequencyData,
    useInstrumentsData,
  };
};
