import Api from 'utils/Api';
import { IDeviationResponse } from 'api/types';
import { getDateString } from 'components/dateHelpers';
import { IDeviationParams } from './types';

class DeviationApi extends Api {
  async getDeviation(params: IDeviationParams): Promise<IDeviationResponse> {
    const formattedParams: { [key: string]: string | string[] | null | undefined } = {
      end_date: params.endDate && getDateString(params.endDate),
      rolling: params.rolling || '5',
      time_window: params.timeWindow || '22',
      time_decay: params.timeDecay,
      left: params.instruments?.instrument_x.ticker,
      right: params.instruments?.instrument_y.ticker,
    };

    return this.getWithDataVariantParam<IDeviationResponse>('/v1/deviation', { params: formattedParams }).then(
      res => res.data
    );
  }
}

export const deviationApi = new DeviationApi();
