import { ICorrelationResponse } from 'api/types';
import { format, parseISO } from 'date-fns';
import { InstrumentPair } from 'types';

export class CorrelationData {
  public static fromCorrelationResponse(correlationResponse: ICorrelationResponse | undefined) {
    if (correlationResponse) {
      const result = correlationResponse.data.filter(
        (item: { date: string; price_change__instrument_x: string; price_change__instrument_y: string }) => {
          return item.price_change__instrument_x !== 'NaN' && item.price_change__instrument_y !== 'NaN';
        }
      );
      return new CorrelationData(result.reverse(), {
        instrument_y: correlationResponse.instrument_y,
        instrument_x: correlationResponse.instrument_x,
      });
    }
    return new CorrelationData([]);
  }

  private constructor(
    public readonly data: ICorrelationResponse['data'],
    public readonly instrumentsPair?: InstrumentPair
  ) {}

  public get percentMaxValue() {
    return Math.ceil(Math.max.apply(null, [this.leftMaxValue, this.rightMaxValue].map(Math.abs)));
  }

  public get sinceDate() {
    return format(parseISO(this.data[0].date), 'MM/dd/yyyy');
  }

  public get csvData() {
    return this.data.map(element => [...Object.values(element)]);
  }

  private get xData() {
    return this.data.map(item => Number(item.price_change__instrument_x));
  }

  private get yData() {
    return this.data.map(item => Number(item.price_change__instrument_y));
  }

  private get leftMaxValue() {
    return Math.max.apply(null, this.xData.map(Math.abs));
  }

  private get rightMaxValue() {
    return Math.max.apply(null, this.yData.map(Math.abs));
  }

  formatChartLabels(category?: string) {
    if (category === 'bond') return 'Basis Point Change';
    return 'Percent Change';
  }
}
