import Api from 'utils/Api';
import { CalculatorFilterValue } from 'components/common/HeatMapCorrelation/types';
import { IRollingHeatMapResponse } from 'api/types';
import { getDateString } from 'components/dateHelpers';

class HeatMapApi extends Api {
  async getHeatMap(params: {
    calculator: CalculatorFilterValue;
    endDate?: Date;
    startDate?: Date;
    rolling?: string;
    timeWindow: string;
    timeDecay?: string;
    categories: string[];
  }): Promise<IRollingHeatMapResponse> {
    const { calculator } = params;
    try {
      const url = calculator === CalculatorFilterValue.Rolling ? '/v1/rolling_heat_map' : '/v1/heat_map';
      const formattedParams: { [key: string]: string | string[] | undefined } = {
        end_date: params.endDate && getDateString(params.endDate),
        start_date: params.startDate ? getDateString(params.startDate) : undefined,
        rolling: !params.startDate ? params.rolling : undefined,
        time_window: !params.startDate ? params.timeWindow : undefined,
        time_decay: params.timeDecay,
        categories: params.categories,
      };
      const response = await this.getWithDataVariantParam<IRollingHeatMapResponse>(url, { params: formattedParams });
      return response.data;
    } catch (error) {
      throw error.response.status;
    }
  }

  async getFilteredHeatMap(params: {
    calculator: CalculatorFilterValue;
    endDate?: Date;
    startDate?: Date;
    rolling?: string;
    timeWindow: string;
    timeDecay?: string | undefined;
    watchlistId?: number;
  }): Promise<IRollingHeatMapResponse> {
    const { calculator } = params;
    try {
      const url =
        calculator === CalculatorFilterValue.Rolling ? '/v1/filtered_rolling_heat_map' : '/v1/filtered_heat_map';
      const formattedParams: { [key: string]: string | string[] | undefined | number } = {
        end_date: params.endDate && getDateString(params.endDate),
        start_date: params.startDate ? getDateString(params.startDate) : undefined,
        rolling: !params.startDate ? params.rolling : undefined,
        time_window: !params.startDate ? params.timeWindow : undefined,
        time_decay: params.timeDecay,
        watchlist_id: params.watchlistId,
      };
      const response = await this.getWithDataVariantParam<IRollingHeatMapResponse>(url, { params: formattedParams });
      return response.data;
    } catch (error) {
      throw error.response.status;
    }
  }
}

export default new HeatMapApi();
