import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  fonts: {
    body: '"Lato", sans-serif',
  },
  palette: {
    brand: '#ff0',
    text: '#ffffff',
    error: '#f20000',
    success: '#00a651',
    white: '#ffffff',
    black: '#000000',
    background: '#000000',
    pink: '#e2165f',
    lightblue: '#a7e6ff',
    blue: '#5fcaff',
    browngray: '#5a5553',
    bluegray: '#3c525e',
    gray: {
      50: '#f2f2f8',
      100: '#d7d9da',
      200: '#bebfbf',
      300: '#a5a5a5',
      400: '#8b8c8c',
      500: '#727273',
      600: '#58595a',
      700: '#3f4040',
      800: '#252628',
      900: '#040f0f',
    },
    green1: '#00a652',
    green2: '#1bb26b',
    green3: '#67c18d',
    green4: '#9bd3ae',
    green5: '#cce7d4',
    red1: '#eb1c24',
    red2: '#f25b40',
    red3: '#f58466',
    red4: '#faab8d',
    red5: '#fcd1c0',
  },
  spacing: (spacing: number, horizontalSpacing?: number) => {
    if (!horizontalSpacing) return `${spacing * 8}px`;
    return `${spacing * 8}px ${horizontalSpacing * 8}px`;
  },
};

export default theme;
