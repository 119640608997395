import { DownArrow, DownloadButton } from 'components/common/DataExport/styles';
import { Col, Row } from 'react-grid-system';
import arrowDown from 'assets/icons/arrow-down.svg';
import React, { useEffect, useState } from 'react';
import Papa from 'papaparse';
import html2canvas from 'html2canvas';
import img from 'assets/full_logo.png';
import ReactDOM from 'react-dom';
import Spinner from '../Spinner';
import { toast } from 'react-toastify';

const download = (content: string, fileName: string, mimeType: string) => {
  const a = document.createElement('a');
  mimeType = mimeType || 'application/octet-stream';

  if (navigator.msSaveBlob) {
    // IE10
    navigator.msSaveBlob(
      new Blob([content], {
        type: mimeType,
      }),
      fileName
    );
  } else if (URL && 'download' in a) {
    a.href = URL.createObjectURL(
      new Blob([content], {
        type: mimeType,
      })
    );
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    // eslint-disable-next-line no-restricted-globals
    location.href = `data:application/octet-stream,${encodeURIComponent(content)}`; // only this mime type is supported
  }
};

interface ICSVIMGExport {
  fileName: string;
  chartRef?: any;
  data: Papa.UnparseObject | any[];
  csvHeading?: string;
  action?: () => void;
  downloadCsv?: () => Promise<unknown>;
}

const ChartHeading = () => (
  <div
    id="header"
    style={{
      height: '15px',
      backgroundColor: '#000',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-end',
      position: 'relative',
      top: '25px',
    }}
  >
    <div
      style={{
        width: '140px',
        height: '35px',
        backgroundImage: `url(${img})`,
        backgroundSize: '140px',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        color: 'transparent',
        paddingTop: '6px',
      }}
    >
      !
    </div>
    <p
      style={{
        fontSize: '13px',
        position: 'relative',
        top: '-15px',
        right: '5px',
        color: '#ddd',
      }}
    >
      https://excaliburpro.net
    </p>
  </div>
);
const createImgFromElement = async (element: HTMLElement) => {
  //Get graph width and set it to graph wrapper in case of visible scrollbar and overflow auto style.
  const graph = element.querySelector('.recharts-surface') as SVGElement;

  const canvas = await html2canvas(element, {
    scrollY: -window.scrollY,
    backgroundColor: '#000',
    width: graph?.clientWidth + 10 || element.offsetWidth + 30,
    height: element.offsetHeight + 40,
    logging: false,
    onclone: cloned => {
      //add excalibur logo to canvas
      if (!element.id) return;
      const doc = cloned.querySelector(`#${element.id}`) as HTMLElement;

      if (!doc) return;

      doc.style.width = `${graph?.clientWidth + 10 || element.offsetWidth + 30}px`;

      const chartWrapper = doc.querySelector('#chart-wrapper') as HTMLElement;

      if (chartWrapper) {
        chartWrapper.style.overflow = 'visible';
      }

      const rootEl = cloned.createElement('div');

      cloned.body.appendChild(rootEl);
      ReactDOM.render(<ChartHeading />, rootEl);

      const footer = cloned.querySelector('#header') as HTMLElement;

      doc.insertAdjacentHTML('afterbegin', footer.outerHTML);
    },
  });

  return canvas.toDataURL('image/jpeg', 1.0);
};

const addHeadingToCSV = (csv: string, heading?: string) => {
  if (!heading) return csv;
  return `${heading}\r\n${csv}`;
};
export const CSVIMGExport: React.FC<ICSVIMGExport> = ({
  fileName,
  chartRef,
  data,
  csvHeading,
  action,
  downloadCsv,
}) => {
  const [imgLoading, setImgLoading] = useState(false);
  const [csvLoading, setCsvLoading] = useState(false);
  const generateCSV = async (chartData: Papa.UnparseObject | any[]) => {
    setCsvLoading(true);
    try {
      let csv: string;
      if (downloadCsv) {
        csv = (await downloadCsv()) as string;
      } else {
        csv = Papa.unparse(chartData);
      }
      if (!csv) throw new Error('Something went wrong');

      download(addHeadingToCSV(csv, csvHeading), `${fileName}.csv`, 'text/csv;encoding:utf-8');
      setCsvLoading(false);
    } catch (err) {
      setCsvLoading(false);
      toast.error('Something went wrong!');
    }
  };

  const generateIMG = async () => {
    try {
      if (action) action();
      const image = await createImgFromElement(chartRef.current);

      const saveAs = (blob: any, name: string) => {
        const elem = window.document.createElement('a');
        elem.href = blob;
        elem.download = name;

        elem.style.cssText = 'display:none;';
        (document.body || document.documentElement).appendChild(elem);
        if (typeof elem.click === 'function') {
          elem.click();
        } else {
          elem.target = '_blank';
          elem.dispatchEvent(
            new MouseEvent('click', {
              view: window,
              bubbles: true,
              cancelable: true,
            })
          );
        }
        URL.revokeObjectURL(elem.href);
        elem.remove();
      };
      saveAs(image, `${fileName}.jpg`);
      setImgLoading(false);
    } catch (err) {
      setImgLoading(false);
    }
  };
  useEffect(() => {
    if (imgLoading) {
      generateIMG();
    }
  }, [imgLoading]);
  return (
    <Row justify="between" style={{ minHeight: '35px', width: '60px', margin: '0 0 0 auto' }}>
      <div data-html2canvas-ignore style={{ margin: '0 8px' }}>
        <DownloadButton
          disabled={imgLoading}
          onClick={() => {
            setImgLoading(true);
          }}
        >
          <Row>
            <Col style={{ padding: 0 }}>
              <span> IMG</span>
            </Col>
          </Row>
          <Row>
            <Col style={{ padding: 0 }}>
              {imgLoading ? (
                <Row>
                  <Spinner size="sm" />
                </Row>
              ) : (
                <DownArrow src={arrowDown} />
              )}
            </Col>
          </Row>
        </DownloadButton>
      </div>
      <div data-html2canvas-ignore style={{ margin: '0 8px' }}>
        <DownloadButton onClick={() => generateCSV(data)}>
          <Row>
            <Col style={{ padding: 0 }}>
              <span> CSV</span>
            </Col>
          </Row>
          <Row>
            <Col style={{ padding: 0 }}>
              {csvLoading ? (
                <Row>
                  <Spinner size="sm" />
                </Row>
              ) : (
                <DownArrow src={arrowDown} alt="down arrow image" />
              )}
            </Col>
          </Row>
        </DownloadButton>
      </div>
    </Row>
  );
};
