import BaseButton from 'components/common/BaseButton';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;
`;

export const Label = styled(BaseButton)<{ disabled: boolean; open?: boolean }>`
  position: relative;
  font-size: inherit;
  text-decoration: underline;

  ${({ theme, disabled }) => `
    color: ${theme.palette.brand};
    cursor: ${disabled ? 'unset' : 'pointer'};
  `}
  &::before {
    position: absolute;
    top: -4px;
    left: -6px;
    z-index: -1;
    display: ${({ open }) => (open ? 'block' : 'none')};
    width: 100%;
    height: 40px;
    padding: 6px;
    background-color: ${({ theme }) => theme.palette.gray[800]};
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    content: '';
  }
`;

export const Content = styled.div<{ position?: 'right' | 'left' | number; top?: number }>`
  position: absolute;
  top: ${({ top }) => (top ? `${top}% ` : '101%')};
  right: ${props =>
    props.position === 'left' ? '15px' : props.position === 'right' ? 'right' : `${props.position}px`};
  z-index: 9;
`;
export const PopoverStatusIcon = styled.span`
  position: absolute;
  top: 50%;
  right: 6px;
  transform: translateY(-50%);
  font-size: 16px;
`;
