import {
  AverageCalculatorFilter,
  AveragePeriodFilterValue,
  CalculatorFilterValue,
  EquallyWeightedFilter,
  ExponentiallyWeightedFilter,
  MethodOfCalculationFilterValue,
  PeriodFilterValue,
  RollingCalculatorFilter,
  TimeWindowFilterValue,
} from 'components/common/HeatMapCorrelation/types';

export const defaultEquallyWeightedMethodOfCalculation: EquallyWeightedFilter = {
  method: MethodOfCalculationFilterValue.EquallyWeighted,
};

export const defaultExponentiallyWeightedMethodOfCalculation: ExponentiallyWeightedFilter = {
  method: MethodOfCalculationFilterValue.ExponentiallyWeighted,
  decayFactor: '0.91',
};

export const defaultRollingCalculatorFilter: RollingCalculatorFilter = {
  calculator: CalculatorFilterValue.Rolling,
  period: PeriodFilterValue.FiveDay,
  timeWindow: TimeWindowFilterValue.OneMonth,
  methodOfCalculation: {
    method: MethodOfCalculationFilterValue.EquallyWeighted,
  },
};

export const defaultAverageCalculatorFilter: AverageCalculatorFilter = {
  calculator: CalculatorFilterValue.Average,
  averagePeriod: AveragePeriodFilterValue.FiveDay,
};
