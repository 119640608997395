import React from 'react';
import { useRoutes } from 'react-router-dom';
import { publicRoutes } from './PublicRoutes';
import { privateRoutes } from './PrivateRoutes';
import useAuth from 'hooks/useAuth';

const AppRoutes = () => {
  const { authorized } = useAuth();
  const routes = authorized ? privateRoutes : publicRoutes;
  const element = useRoutes([...routes]);

  return <>{element}</>;
};

export default AppRoutes;
