import styled from 'styled-components';

export const Wrapper = styled.div`
  color: white;
`;

export const Navigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: ${({ theme }) => theme.spacing(1)};

  * {
    &:first-child {
      margin-right: auto;
      margin-left: 0;
    }

    &:last-child {
      margin-left: 6px;
    }
  }
`;
