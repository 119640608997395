import { Col, Row } from 'react-grid-system';
import styled from 'styled-components';

export const Wrapper = styled(Row)`
  ${({ theme }) => `
    padding-top: ${theme.spacing(2)};
    padding-bottom: ${theme.spacing(6)};
  `}
`;

export const Links = styled(Col)`
  a,
  button {
    margin: ${({ theme }) => theme.spacing(0, 2)};

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const HamburgerIcon = styled.img`
  width: 20px;
  color: white;
  border-color: white;
`;

export const CrossIcon = styled.img`
  width: 15px;
  color: white;
  border-color: white;
`;

export const Logo = styled.img`
  display: block;
  width: 270px;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;
export const BurgerWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const BurgerMenuContent = styled(Row)`
  display: flex;
  flex-direction: column;
  width: 250px;
  margin-top: 10px;
  padding: 14px 0 10px 0;
  background-color: ${({ theme }) => theme.palette.gray[800]};
  border: 1px solid ${({ theme }) => theme.palette.gray[800]};
  border-radius: 3px;
`;
export const MenuContentLink = styled.div`
  display: flex;
  margin-bottom: 6px;
  padding: 3px 0;

  &:hover {
    background-color: black;
  }

  & > a,
  button {
    width: 100%;
    padding: 0 24px;
    text-transform: capitalize;
  }
`;
export const MenuRow = styled(Row)`
  margin-top: 15px;
`;

export const Menu = styled(Col)`
  text-align: end;
`;
export const SideMenuWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  gap: 4px;
  margin-right: -15px;
  @media (min-width: 768px) {
    gap: 12px;
  }
  @media (min-width: 992px) {
    gap: 30px;
  } ;
`;

export const LinkWrapper = styled.div``;
