import React from 'react';
import { useDataVariantParam } from 'hooks';
import { DataVariant } from '../../hooks/useDataVariantParam/types';

interface ChartTitleProps {
  title: string;
  instrumentName?: string;
  instrumentVariants?: DataVariant[];
}

export const hasSmoothedVariant = (instrumentVariants?: DataVariant[]) => {
  if (!instrumentVariants) return false;
  const index = instrumentVariants.findIndex(variant => variant === DataVariant.SMOOTHED);
  return index > -1;
};
export const ChartTitle: React.FC<ChartTitleProps> = ({ title, instrumentName, instrumentVariants }) => {
  const { dataVariant } = useDataVariantParam();

  if (!instrumentName)
    return (
      <>
        {hasSmoothedVariant(instrumentVariants) && `(${dataVariant.label})`} {title}
      </>
    );
  return (
    <>
      {title} - {instrumentName} {hasSmoothedVariant(instrumentVariants) && `(${dataVariant.label})`}
    </>
  );
};
