import { IsDefined, IsEnum, IsString, ValidateNested } from 'class-validator';
import { DataVariant } from './hooks/useDataVariantParam/types';

export interface IHeatMap {
  instrument_x: Instrument;
  positive: boolean;
  instrument_y: Instrument;
  value: string;
}

export interface IOption<T = unknown> {
  value: T;
  label: string;
}

export interface IOptionGroup<T> extends IOption<T> {
  options: IOption<T>[];
}

export interface IModalOption<T = any> {
  value: T;
  label: string;
  modal: any;
}

export type CheckboxOption<T = string> = IOption<T> | IOptionGroup<T>;

export function isOptionGroup<T = string>(option: CheckboxOption<T>): option is IOptionGroup<T> {
  return 'options' in option;
}

export class Instrument {
  @IsString()
  full_name: string;

  @IsString()
  short_name: string;

  replacement: string | null;

  @IsString()
  ticker: string;

  @IsString()
  instrument_type: string;

  @IsEnum(DataVariant, { each: true })
  data_variants: DataVariant[];

  constructor(
    full_name: string,
    short_name: string,
    replacement: string | null,
    ticker: string,
    instrument_type: string,
    data_variants: DataVariant[]
  ) {
    this.full_name = full_name;
    this.short_name = short_name;
    this.replacement = replacement;
    this.ticker = ticker;
    this.instrument_type = instrument_type;
    this.data_variants = data_variants;
  }
}

export class InstrumentPair {
  @IsDefined()
  @ValidateNested()
  instrument_x: Instrument;

  @IsDefined()
  @ValidateNested()
  instrument_y: Instrument;

  constructor(instruments: { instrument_x: Instrument; instrument_y: Instrument }) {
    this.instrument_x = instruments.instrument_x;
    this.instrument_y = instruments.instrument_y;
  }
}
