import { useEffect, useCallback } from 'react';

import { UseOutsideClickProps } from './types';

const useOutsideClick: UseOutsideClickProps = (ref, onOutsideClick) => {
  const handleClickOutside = useCallback(
    (event: any) => {
      if (ref && ref.current && !ref.current.contains(event.target as Node)) {
        onOutsideClick();
      }
    },
    [ref, onOutsideClick]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);
};

export default useOutsideClick;
