import styled from 'styled-components';

export const StyledDeviationHeader = styled.header`
  display: flex;
  align-items: flex-start;
`;
export const StyledFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  margin-top: 6px;
`;
export const StyledFilterInfo = styled.p`
  margin: 3px 0;
  font-size: 14px;
`;
export const StyledMedianWrapper = styled.div`
  flex-grow: 1;
`;
export const StyledMedianInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  & > h3 {
    margin: 4px 0;
    color: ${({ theme }) => theme.palette.brand};
    font-size: 14px;
  }
`;
export const StyledMedianColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 130px;
  border: 1px solid #fff;

  & > div {
    width: 100%;
  }

  button {
    width: 100%;
    color: #fff;
    text-decoration: none;
    border-bottom: 1px solid #fff;
  }
`;
export const StyledMedianDropdown = styled.div`
  width: 130px;
  background-color: #000;
  border: 1px solid #fff;

  padding: 3px;
`;
export const StyledMedianDropdownContent = styled.div`
  margin-bottom: 4px;
  font-size: 14px;
  text-align: right;

  & > span {
    display: block;
    width: 100%;
    color: ${({ value }: { value: string }) => handleColorType(value)};
  }
`;

export const StyledDeviationChartWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 30px;
`;
export const DeviationChartImg = styled.img`
  width: 100%;
`;
export const StyledArrow = styled.img`
  position: absolute;
  width: 56%;

  top: 60%;
  left: 50%;
  transform: translateX(-50%);
`;

interface IStyledValue {
  top: number;
  left: number;
  value: string;
  color?: string;
}

export const StyledValue = styled.span`
  position: absolute;
  top: ${({ top }: IStyledValue) => `${top}%`};
  left: ${({ left }: IStyledValue) => `${left}%`};
  color: ${({ value, color }: IStyledValue) => color || handleColorType(value)};
  transform: translateX(-50%);

  font-size: 14px;
`;
export const StyledChartInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 69%;
  margin: 3px auto;
`;
const handleColorType = (value: string): ((a: { theme: any }) => string) => {
  if (!Number(value) || Number(value) < 0) return ({ theme }) => theme.palette.red1;
  return ({ theme }) => theme.palette.green1;
};
