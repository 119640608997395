import styled from 'styled-components';
import Input from 'components/common/Input';

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing(6)};
  padding: ${({ theme }) => theme.spacing(0, 2)};
`;

export const LoginInput = styled(Input)`
  :last-of-type {
    margin-top: 15px;
  }
`;
export const ErrorNotification = styled.div`
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #000;
  color: ${({ theme }) => theme.palette.brand};
  font-size: 18px;
`;
