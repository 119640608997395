import React, { useState, Suspense } from 'react';
import { HeatMapCorrelation } from 'components/common/HeatMapCorrelation';
import { Col, Container, Row } from 'react-grid-system';
import { RegimeChange } from 'components/RegimeChange/RegimeChange';
import { Riskometer } from 'components/Riskometer/Riskometer';
import { MainContainer } from './App/styles';
import { MetadataContext, MetadataProvider } from './common/MetadataContext';
import RiskContext from 'components/RiskyBusiness/RiskContext';

const RelationshipBuilding = React.lazy(() => import('./RelationshipBuilding/RelationshipBuilding'));
const RiskyBusiness = React.lazy(() => import('./RiskyBusiness/RiskyBusiness'));

export const Dashboard = () => {
  const [marketRiskIsValidating, setMarketRiskIsValidating] = useState(true);
  const [creditRiskIsValidating, setCreditRiskIsValidating] = useState(true);
  const [marketRisk24hData, setMarketRisk24hData] = useState<
    { date: string; [key: string]: string }[] | undefined | null
  >(null);

  const [creditRisk24hData, setCreditRisk24hData] = useState<
    { date: string; [key: string]: string }[] | undefined | null
  >(null);

  return (
    <MainContainer>
      <MetadataProvider>
        <MetadataContext.Consumer>
          {({ latestDate, setLatestDate }) => (
            <RiskContext.Provider
              value={{
                marketRisk24hData,
                creditRisk24hData,
                setMarketRisk24hData,
                setCreditRisk24hData,
                latestDate,
                setLatestDate,
                marketRiskIsValidating,
                setMarketRiskIsValidating,
                creditRiskIsValidating,
                setCreditRiskIsValidating,
                isValidating: marketRiskIsValidating || creditRiskIsValidating,
              }}
            >
              <Container style={{ width: '100%' }}>
                <Row>
                  <Col>
                    <HeatMapCorrelation disabled={false} last={true}>
                      <Riskometer />
                    </HeatMapCorrelation>
                  </Col>
                </Row>
                <Row>
                  <RegimeChange />
                </Row>
                <Row>
                  <Suspense fallback="">
                    <RiskyBusiness />
                  </Suspense>
                </Row>
                <Row>
                  <Suspense fallback="">
                    <RelationshipBuilding />
                  </Suspense>
                </Row>
              </Container>
            </RiskContext.Provider>
          )}
        </MetadataContext.Consumer>
      </MetadataProvider>
    </MainContainer>
  );
};
