import { IMovingAveragesResponse } from 'api/types';
import zipWith from 'lodash.zipwith';
import { min } from 'date-fns';
import { RESPONSE_DATE_REGEX } from '../RegimeChangeApi';

interface IMovingAveragesChartData {
  date: string;
  bull: string;
  bear: string;
  priceChanges: string;
}

export class MovingAveragesData {
  public static fromMovingAveragesResponse(movingAveragesResponse?: IMovingAveragesResponse) {
    if (movingAveragesResponse) {
      const movingAverages = movingAveragesResponse.moving_averages;
      const priceChanges = movingAveragesResponse.price_change;
      const mergedMovingAverages = zipWith(movingAverages, priceChanges, (m, p) => {
        if (!m || !RESPONSE_DATE_REGEX.test(m.date) || !p || !RESPONSE_DATE_REGEX.test(p.date)) return undefined;
        return {
          date: m.date,
          bull: m.bull,
          bear: m.bear,
          priceChanges: p.value,
        };
      });
      const filteredMergedMovingAverages = mergedMovingAverages.filter((el): el is IMovingAveragesChartData => !!el);
      return new MovingAveragesData(filteredMergedMovingAverages);
    }
    return new MovingAveragesData([]);
  }

  private constructor(public data: IMovingAveragesChartData[]) {}

  get chartData() {
    return this.data;
  }

  get minDate() {
    return min(this.data.map(element => new Date(element.date)));
  }

  get csvData() {
    return this.data.map(element => [...Object.values(element)]);
  }

  get priceChanges() {
    return this.data.map(element => ({
      date: element.date,
      priceChanges: element.priceChanges,
    }));
  }

  get priceChangesValues() {
    return this.priceChanges.map(e => parseFloat(e.priceChanges));
  }

  get minPriceChange() {
    return Math.min(...this.priceChangesValues);
  }

  get maxPriceChange() {
    return Math.max(...this.priceChangesValues);
  }
}
