import React from 'react';

export const RegimeChangeHelpModal = () => (
  <p>
    The six charts in <strong>REGIME CHANGE</strong> help determine when instruments or pairs have switched to a
    different state. There is a control panel from which you choose the instrument you are researching. The first graph
    shows the actual bull/bear signals generated from the Markov process, a stochastic process used to model randomly
    changing data. The second graph charts returns vs. volatility and can help understand whether an instrument is
    moving toward a bullish or bearish state. Because raw Markov data can be volatile, we have created several ways to
    smooth the data to better detect trends. The first is looking at the 60-day moving average of the Markov data. The
    next looks at when an instrument gives very strong Markov bull or bear signals (when a value is above 0.8). We look
    at the frequency of those strong signals over a one-month and three-month time period. Finally, we allow for the
    charting of moving averages, the Relative Strength Index (RSI) and Moving Average Convergence/Divergence (MACD).
  </p>
);
