import React, { FC, ReactElement, useContext } from 'react';
import {
  CorrelationChartTooltipProps,
  FrequencyChartTooltipProps,
  MACDTooltipProps,
  MarkovStateChartTooltipProps,
  MovingAveragesRSIChartTooltipProps,
  RelationshipBuildingTooltipProps,
  ReturnsVolatilityChartTooltipProps,
  RiskyBusinessTooltipProps,
} from 'components/common/Charts/types';
import { ThemeContext } from 'styled-components';
import { TooltipWrapper } from 'styles/Recharts';
import { LabelIndicator, Wrapper } from 'components/common/Charts/styles';

export const ChartTooltip: FC<
  | CorrelationChartTooltipProps
  | ReturnsVolatilityChartTooltipProps
  | FrequencyChartTooltipProps
  | MarkovStateChartTooltipProps
  | MovingAveragesRSIChartTooltipProps
  | MACDTooltipProps
  | RelationshipBuildingTooltipProps
  | RiskyBusinessTooltipProps
> = ({ label, payload, toolTipLabels }): ReactElement => {
  const theme = useContext(ThemeContext);
  return (
    <TooltipWrapper>
      <strong>{label}</strong>
      <div id="chart-tooltip-wrapper">
        {payload &&
          payload.map(({ dataKey, value, color }) => {
            if (['riskOn', 'riskOff', 'zero', 'shortSpread', 'longSpread'].includes(dataKey as string)) {
              return null;
            }
            return (
              <Wrapper key={dataKey}>
                <LabelIndicator
                  data-testid="tooltip-color-indicator"
                  color={color || (value && value > 0 ? theme.palette.success : theme.palette.error)}
                />
                {toolTipLabels[dataKey as string]} {Number(value).toFixed(4)}
              </Wrapper>
            );
          })}
      </div>
    </TooltipWrapper>
  );
};
