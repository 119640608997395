import styled from 'styled-components';
import ModalAdapter from '../ModalAdapter';

export const StyledModal = styled(ModalAdapter)`
  position: relative;
  z-index: 10;

  &__overlay {
    position: fixed;
    background-color: transparent;
    inset: 0;
  }

  .ReactModal__Body--open {
    overflow-y: hidden;
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 95%;
    ${({ fullWidth }: { fullWidth: boolean }) => (fullWidth ? 'max-width:1450px;' : 'max-width: 750px;')};
    max-height: 98vh;
    overflow: auto;
    outline: none;
    transform: translate(-50%, -50%);
    ${({ theme }) => `
      background-color: ${theme.palette.gray['800']};
      border: 1px solid ${theme.palette.gray['300']};
      padding: ${theme.spacing(3)};
    `}
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  width: 32px;
  height: 32px;
  padding: 8px;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;

  ${({ theme }) => `
    top: ${theme.spacing(3)};
    right: ${theme.spacing(3)};
  `}
  img {
    display: block;
    width: 100%;
  }
`;

export const Title = styled.div`
  font-size: 3.25rem;
  text-transform: uppercase;
  ${({ theme }) => `
    color: ${theme.palette.brand};
    margin-bottom: ${theme.spacing(4)};
  `}
`;
