import React, { useMemo } from 'react';
import { MarkovStateChart } from '../MarkovState/MarkovStateChart';
import { MomentumPopupHeader, MomentumPopupWrapper, MomentumPopupChartsWrapper } from './MomentumPopup.styles';
import { MovingAveragesRSI } from '../MovingAveragesRSI/MovingAveragesRSIChart';
import { MACDChart } from '../MACD/MACDChart';
import { ReturnsVsVolatility } from '../ReturnsVsVolatility/ReturnsVSVolatilityChart';
import { useWindowSize } from 'hooks';

interface IMomentumPopup {
  instrument: { label: string; value: string } | null;
  label: string;
}

const options = {
  xAxisHide: true,
  header: false,
  margin: 10,
  width: 1300,
  syncId: 'momentum-charts',
  withFilterValues: true,
  overflow: false,
};
export const MomentumPopup: React.FC<IMomentumPopup> = ({ instrument, label }) => {
  const { height: windowHeight } = useWindowSize();
  const height = useMemo(() => {
    if (!windowHeight) return 200;
    const tmpHeight = windowHeight / 9;
    return tmpHeight < 65 ? 65 : tmpHeight;
  }, [windowHeight]);
  return (
    <MomentumPopupWrapper>
      <MomentumPopupHeader>Momentum charts - {instrument?.label}</MomentumPopupHeader>
      <MomentumPopupChartsWrapper>
        <MarkovStateChart instrument={instrument} options={{ ...options, height }} label={label} />
        <MovingAveragesRSI instrument={instrument} options={{ ...options, height }} />
        <MACDChart instrument={instrument} options={{ ...options, height }} />
        <ReturnsVsVolatility
          instrument={instrument}
          options={{ ...options, xAxisHide: false, height: height + 25 }}
          label={label}
        />
      </MomentumPopupChartsWrapper>
    </MomentumPopupWrapper>
  );
};
