import styled from 'styled-components';
import ModalAdapter from '../ModalAdapter';

export const StyledModal = styled(ModalAdapter)`
  position: relative;
  z-index: 10;

  &__overlay {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    inset: 0;
  }

  &__body {
    overflow: hidden;
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 550px;
    overflow: auto;
    outline: none;
    transform: translate(-50%, -50%);
    ${({ theme }) => `
      background-color: ${theme.palette.gray['800']};
      border: 1px solid ${theme.palette.gray['300']};
      padding: ${theme.spacing(3)};
    `}
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  width: 32px;
  height: 32px;
  padding: 8px;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;

  ${({ theme }) => `
    top: ${theme.spacing(3)};
    right: ${theme.spacing(3)};
  `}
  img {
    display: block;
    width: 100%;
  }
`;

export const Title = styled.div`
  font-size: 3.25rem;
  text-transform: uppercase;
  ${({ theme }) => `
    color: ${theme.palette.brand};
    margin-bottom: ${theme.spacing(4)};
  `}
`;
export const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  padding: 0 6px;
`;
export const Button = styled.button`
  border: none;
  background-color: ${({ color }) => color};
  color: #fff;
  padding: 4px 12px;
  border-radius: 2px;
  transition: 0.1s ease-in-out;

  &:hover,
  &:disabled {
    background-color: ${({ hoverColor }: { hoverColor: string }) => hoverColor};
  }
`;
