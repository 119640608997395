import Api from 'utils/Api';

class ForgotPasswordApi extends Api {
  async resetPassword({ email }: { email: string }) {
    const response = await this.api.post('/v1/password/forgot', { email });
    return response.data;
  }
}

export const forgotPasswordApi = new ForgotPasswordApi();
