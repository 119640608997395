import { Instrument } from 'types';

export interface IWatchlistRowIds {
  left: string;
  right: string;
  positive?: boolean;
  value?: string;
}

export interface WatchlistRowProps {
  data: IWatchlistRow;
  onChange: (row: IWatchlistRowIds) => void;
  onDelete?: () => void;
}

export interface IWatchlistRow {
  instrument_x: Instrument | null;
  instrument_y: Instrument | null;
  value?: string;
  positive?: boolean;
}

export interface IFilledWatchlistRow {
  instrument_x: Instrument;
  instrument_y: Instrument;
}

export function assertFilledRow(row: IWatchlistRow): asserts row is IFilledWatchlistRow {
  if (!row.instrument_x || !row.instrument_y) {
    throw new Error('Row is not filled');
  }
}
