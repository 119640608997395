import Api from 'utils/Api';
import {
  IFrequencyResponse,
  IMACDResponse,
  IMarkovStateResponse,
  IMovingAveragesResponse,
  IMovingAveragesRSIResponse,
  IReturnsVsVolatilityResponse,
} from 'api/types';
import { getDateString } from 'components/dateHelpers';
import { ICalculationOptionsInterface } from './types';
import { DataVariant } from '../../hooks/useDataVariantParam/types';

export interface IInstrumentsResponse {
  ticker: string;
  short_name: string;
  full_name: string;
  replacement: string;
  instrument_type: string;
  data_variants: DataVariant[];
}

export const RESPONSE_DATE_REGEX = /^\d{4}-\d{1,2}-\d{1,2}$/;

class RegimeChangeChartsApi extends Api {
  async getInstruments(): Promise<IInstrumentsResponse[]> {
    try {
      const response = await this.api.get<IInstrumentsResponse[]>('/v1/instruments');
      return response.data;
    } catch (error) {
      throw error.response.status;
    }
  }

  async getMarkovState(params: { instrument?: string }): Promise<IMarkovStateResponse> {
    const { instrument } = params;
    try {
      const response = await this.getWithDataVariantParam<IMarkovStateResponse>('/v1/regime_change', {
        params: {
          instrument,
        },
      });
      return response.data;
    } catch (error) {
      throw error.response.status;
    }
  }

  async getReturnsVolatility(params: { instrument?: string }): Promise<IReturnsVsVolatilityResponse> {
    const { instrument } = params;
    try {
      const response = await this.getWithDataVariantParam<IReturnsVsVolatilityResponse>('/v1/returns_vs_volatility', {
        params: {
          instrument: instrument || 'Gold',
          end_date: getDateString(new Date()),
        },
      });

      return response.data;
    } catch (error) {
      throw error.response.status;
    }
  }

  async getMovingAverages(params: { instrument?: string }): Promise<IMovingAveragesResponse> {
    const { instrument } = params;
    try {
      const response = await this.getWithDataVariantParam<IMovingAveragesResponse>('/v1/moving_averages_regime', {
        params: {
          instrument: instrument || 'Gold',
        },
      });
      return response.data;
    } catch (error) {
      throw error.response.status;
    }
  }

  async getFrequency(params: { instrument?: string; period: number }): Promise<IFrequencyResponse> {
    const { instrument, period } = params;
    try {
      const response = await this.getWithDataVariantParam<IFrequencyResponse>('/v1/frequency', {
        params: {
          instrument,
          period,
        },
      });
      return response.data;
    } catch (error) {
      throw error.response.status;
    }
  }

  async getMovingAveragesRSI(params: {
    instrument?: string;
    rsi?: number;
    movingAverages?: number[];
  }): Promise<IMovingAveragesRSIResponse> {
    const { instrument, rsi, movingAverages } = params;
    try {
      const response = await this.getWithDataVariantParam<IMovingAveragesRSIResponse>('/v1/moving_averages_rsi', {
        params: {
          instrument,
          end_date: getDateString(new Date()),
          rsi_days: rsi,
          moving_averages_days: movingAverages,
        },
      });
      return response.data;
    } catch (error) {
      throw error.response.status;
    }
  }

  async getMACD(params: {
    instrument?: string;
    calculation: ICalculationOptionsInterface | null;
  }): Promise<IMACDResponse> {
    const { instrument, calculation } = params;
    try {
      const response = await this.getWithDataVariantParam<IMACDResponse>('/v1/macd', {
        params: {
          instrument,
          end_date: getDateString(new Date()),
          first_period: calculation?.value.firstPeriod,
          second_period: calculation?.value.secondPeriod,
          third_period: calculation?.value.thirdPeriod,
        },
      });

      return response.data;
    } catch (error) {
      throw error.response.status;
    }
  }
}

export const regimeChangeApi = new RegimeChangeChartsApi();
