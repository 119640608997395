export const selectStyles = {
  option: (styles: any, state: any) => {
    const nonSelectedBackground = state.isFocused ? '#aaa' : 'black';
    return {
      ...styles,
      background: state.isSelected ? 'white' : nonSelectedBackground,
      color: state.isSelected ? 'black' : 'white',
    };
  },
  control: (styles: any, state: any) => {
    return {
      ...styles,
      width: '200px',
      background: 'black',
      border: '1px solid #aaa',
      borderRadius: '0',
    };
  },
  singleValue: (styles: any) => {
    return {
      ...styles,
      color: 'white',
    };
  },
  menu: (styles: any) => {
    return {
      ...styles,
      width: '200px',
      marginTop: 0,
      borderRadius: 0,
      background: 'black',
    };
  },
  input: (styles: any) => {
    return {
      ...styles,
      color: 'white',
    };
  },
};
