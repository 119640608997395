import { IsEnum, IsString } from 'class-validator';

export enum DataVariant {
  'RAW' = 'raw',
  'SMOOTHED' = 'smoothed',
}

export const dataVariantOptions = [
  { label: 'Daily Exchange Data', value: DataVariant.RAW },
  { label: 'Smoothed Data', value: DataVariant.SMOOTHED },
];

export class DataVariantOption {
  @IsString()
  label: string;

  @IsEnum(DataVariant, { each: true })
  value: DataVariant;

  constructor(dataVariant: { value: DataVariant; label: string }) {
    this.value = dataVariant.value;
    this.label = dataVariant.label;
  }
}

export interface DataVariantContextData {
  dataVariant: DataVariantOption;
  setDataVariant: (value: DataVariantOption) => void;
}
