import React, { FC, ReactElement } from 'react';
import { StyledLink } from 'styles/StyledLink';

import { LinkButtonProps } from './types';

export const LinkButton: FC<LinkButtonProps> = ({ children, onClick, disabled, active }): ReactElement => (
  <StyledLink disabled={disabled} as="button" onClick={onClick} active={active}>
    {children}
  </StyledLink>
);
