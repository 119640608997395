import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import BaseButton from 'components/common/BaseButton';
import { emailPattern, FormErrors } from 'utils/constants';
import { useModal } from 'hooks/useModal';
import { useAuth, useIsMounted } from 'hooks';
import LoginApi from './LoginApi';
import { ForgotPassword } from '../ForgotPassword';
import { ActionWrapper, ErrorNotification, LoginInput } from './styles';

const appContainer = document.querySelector('#root');
const Login = () => {
  const isMounted = useIsMounted();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { register, handleSubmit, errors } = useForm();
  const { setModal, onCloseModal } = useModal();
  const { authorize } = useAuth();

  const navigate = useNavigate();

  const onForgotPasswordClick = () => {
    setModal(<ForgotPassword />, { title: 'Forgot password' });
  };

  const logIn: SubmitHandler<{ email: string; password: string }> = async (formData): Promise<void> => {
    setIsLoading(true);
    setIsError(false);
    try {
      await authorize(() => LoginApi.login(formData));
      onCloseModal();
      navigate('/dashboard');
    } catch (error) {
      setIsError(true);
    } finally {
      if (isMounted()) {
        setIsLoading(false);
      }
    }
  };
  useEffect(() => {
    if (!isMounted()) return;
    const id = setTimeout(() => setIsError(false), 5000);

    return () => {
      clearTimeout(id);
    };
  }, [isError]);
  return (
    <form onSubmit={handleSubmit(logIn)}>
      <LoginInput
        id="email"
        name="email"
        placeholder="Username"
        aria-label="email-input"
        ref={register({
          required: FormErrors.REQUIRED_FIELD,
          pattern: {
            value: emailPattern,
            message: FormErrors.INCORRECT_EMAIL,
          },
        })}
        error={errors.email && errors.email.message}
      />
      <LoginInput
        id="password"
        name="password"
        placeholder="Password"
        aria-label="password-input"
        ref={register({ required: FormErrors.REQUIRED_FIELD })}
        type="password"
        error={errors.password && errors.password.message}
      />
      <ActionWrapper>
        <BaseButton type="button" onClick={onForgotPasswordClick}>
          Forgot password
        </BaseButton>
        <BaseButton type="submit" disabled={isLoading}>
          Login
        </BaseButton>
      </ActionWrapper>
      {isError && appContainer
        ? ReactDOM.createPortal(
            <ErrorNotification>
              Invalid email or password. Please try again with different credentials.
            </ErrorNotification>,
            appContainer
          )
        : ''}
    </form>
  );
};

export default Login;
