import useOutsideClick from 'hooks/useOutsideClick';
import React, { ReactElement, useCallback, useRef, useState } from 'react';
import { PopoverContext } from './PopoverContext';
import { Content, Label, Wrapper, PopoverStatusIcon } from './styles';
import { PopoverProps } from './types';

export function Popover({
  label,
  disabled = false,
  closeable = false,
  position,
  top,
  children,
  showArrow = false,
  menu,
  ...props
}: PopoverProps): ReactElement {
  const [open, toggleOpen] = useState<boolean>(false);
  const listRef = useRef(document.createElement('div'));
  const togglePopover = (): void => {
    toggleOpen(prevState => !prevState);
  };

  const closePopover = useCallback(() => {
    toggleOpen(false);
  }, []);

  useOutsideClick(listRef, closePopover);

  return (
    <PopoverContext.Provider value={{ closePopover }}>
      <Wrapper {...props}>
        <Label disabled={disabled} onClick={togglePopover} open={menu ? open : false}>
          {label}
          {showArrow ? open ? <PopoverStatusIcon>-</PopoverStatusIcon> : <PopoverStatusIcon>+</PopoverStatusIcon> : ''}
        </Label>
        {open && (
          <Content ref={listRef} position={position} top={top}>
            {children}
          </Content>
        )}
      </Wrapper>
    </PopoverContext.Provider>
  );
}
