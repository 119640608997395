import React from 'react';
import { Navigation } from './styles';
import { LinkButton, HelpButton, useHeatMapView } from 'components/common';
import { Views } from './HeatMap/constants';
import { MatrixLinkWrapper } from './HeatMap/styles';
import Link from '../Link';
import { useModal } from 'hooks';
import HeatMapHelpModal from './HeatMap/HeatMapHelpModal';

interface HeatMapHeaderProps {
  disabled?: boolean;
}

const HeatMapHeader: React.FC<HeatMapHeaderProps> = ({ disabled = false }) => {
  const { setInfoModal } = useModal();
  const onModalOpen = () => {
    setInfoModal(<HeatMapHelpModal />);
  };
  const { view, setView, setPreviousView } = useHeatMapView();
  return (
    <Navigation>
      <LinkButton
        onClick={() => {
          setView(Views.HEAT_MAP);
          setPreviousView(Views.HEAT_MAP);
        }}
        active={view === Views.HEAT_MAP}
      >
        Heat&nbsp;map
      </LinkButton>
      <LinkButton
        onClick={() => {
          setView(Views.WATCHLIST);
          setPreviousView(Views.WATCHLIST);
        }}
        active={view === Views.WATCHLIST}
      >
        Watchlist
      </LinkButton>
      <MatrixLinkWrapper disabled={disabled}>
        <Link disabled={disabled} to="/matrix">
          Matrix
        </Link>
      </MatrixLinkWrapper>
      <LinkButton disabled={disabled} onClick={() => setView(Views.TRENDING)} active={view === Views.TRENDING}>
        Trending
      </LinkButton>
      <HelpButton onClick={onModalOpen} />
    </Navigation>
  );
};
export default HeatMapHeader;
