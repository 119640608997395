import { LinkButton, HelpButton } from 'components/common';
import Spinner from 'components/common/Spinner';
import { chartStyles } from 'components/RegimeChange/styles';
import { RiskometerHelpModal } from 'components/Riskometer/RiskometerHelpModal';
import { useRisk } from 'components/RiskyBusiness/RiskContext';
import { useModal } from 'hooks';
import React, { FC, ReactElement, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import {
  Area,
  ComposedChart,
  Label,
  LabelList,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  TooltipProps,
  YAxis,
} from 'recharts';
import { TooltipWrapper } from 'styles/Recharts';
import theme from 'styles/theme';
import { CreditRiskButton, MarketRiskButton, Navigation, Separator, Wrapper } from './styles';

const ChartTooltip: FC<TooltipProps<number, string>> = ({ payload }): ReactElement => {
  if (!payload || (payload && payload.length === 0)) {
    return <></>;
  }
  const instrument = payload && payload.find(i => i.dataKey === 'value');
  const tooltipData = instrument && <Wrapper key={instrument.value}>{Number(instrument.value).toFixed(2)}</Wrapper>;
  return <TooltipWrapper>{tooltipData}</TooltipWrapper>;
};

interface IRiskChart {
  data: { date: string; [p: string]: string }[];

  minValue: number;
  maxValue: number;
  payloadUniqBy: (key: any) => boolean;
  topLabel: string;
  bottomLabel: string;
}

const RiskChart: React.FC<IRiskChart> = props => {
  const maxDomain = props.maxValue > Math.abs(props.minValue) ? props.maxValue : Math.abs(props.minValue);
  return (
    <ResponsiveContainer width="100%" height={345}>
      <ComposedChart
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 30,
        }}
        data={props.data}
      >
        <YAxis
          yAxisId="risk"
          dataKey="value"
          style={chartStyles.axis}
          ticks={[-maxDomain, maxDomain]}
          tickLine={false}
          axisLine={false}
          domain={[Math.floor(-maxDomain - 3), Math.floor(maxDomain + 3)]}
          tickFormatter={() => ''}
          orientation="right"
        >
          <Label
            value={`${props.bottomLabel} \xa0 \xa0 \xa0 \xa0 \xa0 \xa0 \xa0 \xa0 \xa0 \xa0 \xa0 ${props.topLabel}`}
            style={{ fill: theme.palette.brand, textAnchor: 'middle', fontSize: '1rem' }}
            offset={70}
            angle={-90}
            position="insideLeft"
          />
        </YAxis>

        <YAxis
          type="number"
          dataKey="value"
          domain={[Math.floor(-maxDomain - 3), Math.floor(maxDomain + 3)]}
          tickSize={10}
          tickCount={15}
          tickLine={false}
          axisLine={false}
          style={chartStyles.axis}
          dx={-37}
          offset={100}
        />
        <Scatter dataKey="value" data={props.data} fill={theme.palette.brand} dy={100} isAnimationActive={false}>
          <LabelList
            name="label"
            dataKey="label"
            fill="#fff"
            content={({ x, y, ...rest }) => {
              const X = Number(x);
              const Y = Number(y);

              return (
                <foreignObject
                  width="100"
                  height="1"
                  overflow="visible"
                  x={X ? X - 45 : x}
                  y={Y ? Y + 10 : y}
                  style={{ position: 'relative', zIndex: 3 }}
                >
                  <span
                    style={{
                      fontSize: '0.8rem',
                      textAlign: 'center',
                      display: 'block',
                      wordBreak: 'break-all',
                    }}
                  >
                    {rest.value}
                  </span>
                </foreignObject>
              );
            }}
          />
        </Scatter>
        <Area
          type="monotone"
          dataKey="riskOn"
          fill="gray"
          stroke="transparent"
          fillOpacity={0.4}
          dot={false}
          activeDot={false}
          isAnimationActive={false}
        />
        <Area
          type="monotone"
          dataKey="riskOff"
          fill="gray"
          stroke="transparent"
          fillOpacity={0.4}
          activeDot={false}
          isAnimationActive={false}
        />
        <Tooltip
          payloadUniqBy={props.payloadUniqBy}
          cursor={{ stroke: theme.palette.brand, 'aria-orientation': 'vertical' }}
          active={false}
          filterNull={true}
          content={<ChartTooltip />}
          isAnimationActive={false}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
export const Riskometer = () => {
  const { marketRisk24hData, creditRisk24hData, latestDate, isValidating } = useRisk();

  const { setInfoModal } = useModal();

  const [riskChartVisible, setRiskChartVisible] = useState<string>('marketRisk');

  const openModal = () => {
    setInfoModal(<RiskometerHelpModal />);
  };

  if (isValidating) return <Spinner />;
  if (!creditRisk24hData || !marketRisk24hData) return <p>No data for given instruments</p>;

  const getNumbers = (data: Array<any>): number[] =>
    data.map(instrument => Number(instrument.value)).filter(num => !isNaN(num));

  const marketRiskMinValue = Math.min(...getNumbers(marketRisk24hData));
  const marketRiskMaxValue = Math.max(...getNumbers(marketRisk24hData));
  const creditRiskMinValue = Math.min(...getNumbers(creditRisk24hData));
  const creditRiskMaxValue = Math.max(...getNumbers(creditRisk24hData));

  const toolTipLabels: any = [];

  marketRisk24hData.forEach(instrument => {
    toolTipLabels.push({ [instrument.label]: instrument.label });
  });

  return (
    <Wrapper>
      <Navigation>
        <LinkButton>Riskometer</LinkButton>
        <HelpButton onClick={openModal} />
      </Navigation>
      <Row justify="between">
        <Col style={{ fontSize: '0.9rem' }}>
          <div>Latest Available Date:</div>
          <div>{latestDate}</div>
        </Col>
        <Col>
          <Row justify="end">
            <MarketRiskButton
              onClick={() => setRiskChartVisible('marketRisk')}
              style={riskChartVisible !== 'marketRisk' ? { color: 'gray' } : {}}
            >
              Market Risk
            </MarketRiskButton>
            <Separator>|</Separator>
            <CreditRiskButton
              onClick={() => setRiskChartVisible('creditRisk')}
              style={riskChartVisible !== 'creditRisk' ? { color: 'gray' } : {}}
            >
              Credit Risk
            </CreditRiskButton>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={12} style={{ padding: 0 }}>
          {riskChartVisible === 'marketRisk' && (
            <RiskChart
              data={marketRisk24hData}
              minValue={marketRiskMinValue}
              maxValue={marketRiskMaxValue}
              payloadUniqBy={key => key === 'value'}
              topLabel="Risk On"
              bottomLabel="Risk Off"
            />
          )}
          {riskChartVisible === 'creditRisk' && (
            <RiskChart
              data={creditRisk24hData}
              minValue={creditRiskMinValue}
              maxValue={creditRiskMaxValue}
              payloadUniqBy={key => key === 'value'}
              topLabel="Risk Off"
              bottomLabel="Risk On"
            />
          )}
        </Col>
      </Row>
    </Wrapper>
  );
};
