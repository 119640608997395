import React, { createContext, useContext, useEffect } from 'react';
import {
  CalculatorFilter,
  CalculatorFilterValue,
  CategoriesFilter,
  DateFilter,
  FilterContextData,
  MethodOfCalculationFilterValue,
  PeriodFilterValue,
  TimeWindowFilterValue,
} from './types';
import { InstrumentPair } from 'types';
import { useImmer } from 'use-immer';
import { defaultRollingCalculatorFilter } from 'components/common/Filters/filtersDefaults';
import {
  changeAveragePeriod,
  changeCalculatorFilterType,
  changeMethodOfCalculation,
  changeTimeDecay,
  changeTimePeriod,
  changeTimeWindow,
} from 'components/common/HeatMapCorrelation/HeatMapCorrelation';
import { useLocalStorage, useAuth } from 'hooks';
import { useMetadata } from '../MetadataContext';
import { replaceDashToSlash } from 'utils/Date';

export const FilterContext = createContext<FilterContextData>({
  dateFilter: {
    startDate: undefined,
    endDate: undefined,
  },
  updateDateFilter: () => {},
  calculatorFilter: {
    calculator: CalculatorFilterValue.Rolling,
    period: PeriodFilterValue.FiveDay,
    timeWindow: TimeWindowFilterValue.OneMonth,
    methodOfCalculation: {
      method: MethodOfCalculationFilterValue.EquallyWeighted,
    },
  },
  updateCalculatorFilter: () => {},
  changeCalculatorFilterType: () => {},
  changeTimePeriod: () => {},
  changeTimeWindow: () => {},
  changeAveragePeriod: () => {},
  changeMethodOfCalculation: () => {},
  changeTimeDecay: () => {},
  calculatorName: '',
  rolling: '',
  timeWindow: '',
  timeDecay: '',
  categoriesFilter: new Set(),
  updateCategoriesFilter: () => {},
});

export interface InstrumentPairContextData {
  selectedInstrument: InstrumentPair | null;
  setSelectedInstrument: (selectedInstrument: InstrumentPair | null) => void;
}

export const InstrumentPairContext = createContext<InstrumentPairContextData>({
  selectedInstrument: null,
  setSelectedInstrument: () => {},
});

interface InstrumentContextData {
  selectedInstrument: { label: string; value: string } | null;
  setSelectedInstrument: (selectedInstrument: { label: string; value: string }) => void;
}

const InstrumentContext = createContext<InstrumentContextData>({
  selectedInstrument: null,
  setSelectedInstrument: () => {},
});

export const FilterProvider: React.FC<{ cacheKey?: string }> = ({ children, cacheKey }) => {
  const { authorized } = useAuth();

  const savedState = cacheKey && localStorage.getItem(cacheKey);

  const [calculatorFilter, updateCalculatorFilter] = useImmer<CalculatorFilter>(
    authorized ? (savedState ? JSON.parse(savedState) : defaultRollingCalculatorFilter) : defaultRollingCalculatorFilter
  );

  const savedCategories = cacheKey && localStorage.getItem(`${cacheKey}Categories`);
  const [categoriesFilter, updateCategoriesFilter] = useImmer<CategoriesFilter>(
    authorized && savedCategories ? new Set(JSON.parse(savedCategories)) : new Set()
  );
  const { latestDate } = useMetadata();

  const savedDate = cacheKey && sessionStorage.getItem(`${cacheKey}Date`);
  const parsedSavedDate = savedDate
    ? JSON.parse(savedDate)
    : {
        startDate: undefined,
        endDate: undefined,
      };

  const [dateFilter, updateDateFilter] = useImmer<DateFilter>({
    startDate: parsedSavedDate.startDate && authorized ? new Date(parsedSavedDate.startDate) : undefined,
    endDate: parsedSavedDate.endDate && authorized ? new Date(parsedSavedDate.endDate) : undefined,
  });

  useEffect(() => {
    if (cacheKey) {
      localStorage.setItem(`${cacheKey}Categories`, JSON.stringify(Array.from(categoriesFilter)));
    }
  }, [categoriesFilter, cacheKey]);
  useEffect(() => {
    if (cacheKey) {
      localStorage.setItem(cacheKey, JSON.stringify(calculatorFilter));
    }
  }, [calculatorFilter, cacheKey]);
  useEffect(() => {
    if (cacheKey) {
      sessionStorage.setItem(`${cacheKey}Date`, JSON.stringify(dateFilter));
    }
  }, [dateFilter, cacheKey]);
  useEffect(() => {
    if (!dateFilter.endDate) {
      if (latestDate) {
        updateDateFilter((prevState: DateFilter) => ({ ...prevState, endDate: replaceDashToSlash(latestDate) }));
      }
    }
  }, [latestDate]);
  return (
    <FilterContext.Provider
      value={{
        dateFilter,
        updateDateFilter,
        calculatorFilter,
        updateCalculatorFilter,
        changeCalculatorFilterType,
        changeAveragePeriod,
        changeTimePeriod,
        changeTimeWindow,
        changeMethodOfCalculation,
        changeTimeDecay,
        get rolling() {
          if (this.calculatorFilter.calculator === CalculatorFilterValue.Rolling) {
            return this.calculatorFilter.period;
          }
          return this.calculatorFilter.averagePeriod;
        },
        get timeWindow() {
          if (this.calculatorFilter.calculator === CalculatorFilterValue.Rolling) {
            return this.calculatorFilter.timeWindow;
          }
          return '0';
        },
        get calculatorName() {
          return this.calculatorFilter.calculator;
        },
        get timeDecay() {
          if (calculatorFilter.calculator === CalculatorFilterValue.Rolling) {
            if (calculatorFilter.methodOfCalculation.method === MethodOfCalculationFilterValue.ExponentiallyWeighted) {
              return calculatorFilter.methodOfCalculation.decayFactor;
            }
            return '0';
          }
          return undefined;
        },
        categoriesFilter,
        updateCategoriesFilter,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const CalculatorProvider: React.FC<{ cacheKey: string }> = ({ children, cacheKey }) => {
  const [selectedInstrument, setSelectedInstrument] = useLocalStorage<InstrumentPair | null>(
    `${cacheKey}InstrumentPair`,
    null,
    InstrumentPair
  );

  return (
    <InstrumentPairContext.Provider value={{ selectedInstrument, setSelectedInstrument }}>
      <FilterProvider cacheKey={cacheKey}>{children}</FilterProvider>
    </InstrumentPairContext.Provider>
  );
};

export const useCalculatorFilter = (): FilterContextData & InstrumentPairContextData => {
  const instrumentContext = useContext(InstrumentPairContext);
  const filterContext = useContext(FilterContext);
  return { ...filterContext, ...instrumentContext };
};

export const RelationshipProvider: React.FC<
  {
    cacheKey: string;
  } & InstrumentContextData
> = ({ children, cacheKey, selectedInstrument, setSelectedInstrument }) => {
  return (
    <InstrumentContext.Provider value={{ selectedInstrument, setSelectedInstrument }}>
      <FilterProvider cacheKey={cacheKey}>{children}</FilterProvider>
    </InstrumentContext.Provider>
  );
};

export const useRelationshipProvider = (): FilterContextData & InstrumentContextData => {
  const instrumentContext = useContext(InstrumentContext);
  const filterContext = useContext(FilterContext);
  return { ...filterContext, ...instrumentContext };
};
