import React, { FC, ReactElement } from 'react';

import { InfoCloseButton, StyledInfoModal } from './styles';
import { ModalProps } from '../types';

const InfoModal: FC<ModalProps> = ({ isOpen, onClose, children, fullWidth = false }): ReactElement => (
  <StyledInfoModal isOpen={isOpen} onRequestClose={onClose} ariaHideApp={false} fullWidth={fullWidth}>
    <InfoCloseButton onClick={onClose}>X</InfoCloseButton>
    <div className="info-modal-content">{children}</div>
  </StyledInfoModal>
);

export default InfoModal;
