import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { Validator } from 'class-validator';

type SetValue<T> = Dispatch<SetStateAction<T>>;
export type Class<T> = new (...args: any[]) => T;

export function useLocalStorage<T>(key: string, initialValue: T, TypeConstructor?: Class<T>): [T, SetValue<T>] {
  const readValue = useCallback((): T => {
    if (typeof window === 'undefined') {
      return initialValue;
    }

    try {
      const item = window.localStorage.getItem(key);

      if (TypeConstructor) {
        const validator = new Validator();

        const newObj = new TypeConstructor(parseJSON(item));

        const res = validator.validateSync(newObj as Object);

        if (res.length > 0) throw Error('Object validation fail');

        return newObj;
      }
      return item ? (parseJSON(item) as T) : initialValue;
    } catch (error) {
      return initialValue;
    }
  }, [initialValue, key]);

  const [storedValue, setStoredValue] = useState<T>(readValue);

  const setValue: SetValue<T> = useCallback(
    value => {
      const newValue = value instanceof Function ? value(storedValue) : value;

      window.localStorage.setItem(key, JSON.stringify(newValue));

      setStoredValue(newValue);

      window.dispatchEvent(new Event('local-storage'));
    },
    [key, storedValue]
  );

  useEffect(() => {
    setStoredValue(readValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [storedValue, setValue];
}

function parseJSON<T>(value: string | null): T | undefined {
  try {
    return value === 'undefined' ? undefined : JSON.parse(value ?? '');
  } catch {
    return undefined;
  }
}
