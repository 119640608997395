import React, { FC, ReactElement } from 'react';
import { ModalTypes } from 'hooks/useModal/constants';
import DefaultModal from './DefaultModal';
import InfoModal from './InfoModal';
import { ModalProps } from './types';
import ConfirmationModal from './ConfirmationModal';

const Modal: FC<ModalProps> = ({ children, title, isOpen, onClose, fullWidth, type, ...rest }): ReactElement => {
  switch (type) {
    case ModalTypes.INFO:
      return (
        <InfoModal isOpen={isOpen} onClose={onClose} fullWidth={fullWidth}>
          {children}
        </InfoModal>
      );
    case ModalTypes.CONFIRM:
      return (
        <ConfirmationModal isOpen={isOpen} onClose={onClose} {...rest}>
          {children}
        </ConfirmationModal>
      );
    default:
      return (
        <DefaultModal title={title} isOpen={isOpen} onClose={onClose} fullWidth={fullWidth}>
          {children}
        </DefaultModal>
      );
  }
};

export default Modal;
