import { ComposedChart, Label, Line, Tooltip, XAxis, YAxis } from 'recharts';
import { format, parseISO } from 'date-fns';
import React, { memo, useRef } from 'react';
import theme from 'styles/theme';
import { ChartTooltip } from 'components/common/Charts/ChartToolTip';
import { ChartLegend } from 'components/Charts/ChartLegend';
import { chartStyles } from 'components/RegimeChange/styles';
import { useBoolean } from 'hooks/useBoolean';
import { IInstrument } from 'components/common/Charts/types';
import Spinner from 'components/common/Spinner';
import { Chart } from 'components/RegimeChange/Chart';
import { CSVIMGExport } from 'components/common/DataExport/CSVIMGExport';
import { DataExportRow } from 'components/common/DataExport/styles';
import { Col } from 'react-grid-system';
import { IGraph } from '../types';
import { DEFAULT_OPTIONS } from '../ChartOptions';
import { useRegimeChangeApi } from '../useRegimeChangeApi';
import { ChartTitle } from '../ChartTitle';

interface IReturns extends IGraph {
  label: string;
}

export const ReturnsVsVolatility: React.FC<IReturns> = memo(
  ({ instrument, options = DEFAULT_OPTIONS, label, instrumentVariants }) => {
    const { useReturnsVsVolatilityData } = useRegimeChangeApi();
    const [rollingReturnVisible, toggleRollingReturn] = useBoolean(true);
    const [rollingVolatilityVisible, toggleRollingVolatility] = useBoolean(true);
    const [percentChangeVisible, togglePercentChange] = useBoolean(true);

    const { data, isValidating } = useReturnsVsVolatilityData({ instrument: instrument?.value });

    const chartRef = useRef(null);

    if (isValidating || !data) return <Spinner />;
    if (!data.chartData.length) return <div>No data</div>;

    const toolTipLabels = {
      returns: '1 Month Rolling Return:',
      volatility: '1 Month Rolling Volatility:',
      priceChanges: `${label} ${format(data.minDate, 'MM/dd/yyyy')}:`,
    };

    const instrumentsChartConfig: IInstrument[] = [
      {
        name: 'returns',
        label: '1 Month Rolling Return',
        color: '#66b3ff',
        isVisible: rollingReturnVisible,
        onClick: toggleRollingReturn,
        yAxisId: 'right',
      },
      {
        name: 'volatility',
        label: '1 Month Rolling Volatility',
        color: '#cc0000',
        isVisible: rollingVolatilityVisible,
        onClick: toggleRollingVolatility,
        yAxisId: 'left',
      },
      {
        name: 'priceChanges',
        label: `${label} ${format(data.minDate, 'MM/dd/yyyy')}`,
        color: theme.palette.brand,
        isVisible: percentChangeVisible,
        onClick: togglePercentChange,
        yAxisId: 'right',
      },
    ];

    const chartTitle = 'Returns Vs. Volatility';
    const csvHeading = `Instrument, ${instrument?.label}`;
    const csvData = {
      fields: ['Date', 'Returns', 'Volatility', 'Price Changes'],
      data: data.csvData,
    };
    return (
      <>
        {options?.header && (
          <DataExportRow justify="end">
            <Col xs={2}>
              <CSVIMGExport data={csvData} fileName={chartTitle} chartRef={chartRef} csvHeading={csvHeading} />
            </Col>
          </DataExportRow>
        )}
        <Chart
          overflow={options.overflow}
          margin={options?.margin}
          chartName="returns-vs-volatility-chart"
          ref={chartRef}
          title={
            options?.header && (
              <ChartTitle
                title={chartTitle}
                instrumentName={instrument?.label}
                instrumentVariants={instrumentVariants}
              />
            )
          }
          legend={<ChartLegend instruments={instrumentsChartConfig} />}
          chart={
            <ComposedChart
              data={data.chartData}
              width={options?.width}
              height={options?.height}
              syncId={options?.syncId}
            >
              {instrumentsChartConfig.map(i => (
                <Line
                  key={i.name}
                  dataKey={i.name}
                  hide={!i.isVisible}
                  dot={false}
                  activeDot={false}
                  yAxisId={i.yAxisId}
                  stroke={i.color}
                  isAnimationActive={false}
                />
              ))}
              <YAxis
                yAxisId="left"
                dataKey="rollingVolatility"
                domain={[Math.floor(data.minVolatility), Math.ceil(data.maxVolatility)]}
                tickCount={11}
                tickLine={false}
                axisLine={false}
                dx={-10}
                style={chartStyles.axis}
              >
                {options?.sideLabels && (
                  <Label
                    value="1 Month Rolling Volatility"
                    offset={10}
                    style={{ fill: 'white', textAnchor: 'middle', fontSize: '0.75rem' }}
                    angle={-90}
                    position="insideLeft"
                  />
                )}
              </YAxis>
              <YAxis
                yAxisId="right"
                dataKey="price_change"
                domain={[
                  Math.floor(Math.min(data.minReturn, data.minPriceChange)),
                  Math.ceil(Math.max(data.maxReturn, data.maxPriceChange) / 5) * 5,
                ]}
                orientation="right"
                tickLine={false}
                axisLine={false}
                dx={10}
                style={chartStyles.axis}
              >
                {options?.sideLabels && (
                  <Label
                    value={`${label} ${format(data.minDate, 'MM/dd/yyyy')}`}
                    offset={10}
                    style={{ fill: 'white', textAnchor: 'middle', fontSize: '0.75rem' }}
                    angle={90}
                    position="insideRight"
                  />
                )}
              </YAxis>

              <XAxis
                hide={options?.xAxisHide}
                dataKey="date"
                tickLine={false}
                axisLine={false}
                tickFormatter={value => {
                  return value && format(parseISO(value), "MMM ''yy");
                }}
                dy={15}
                dx={30}
                interval={30}
                style={chartStyles.axis}
              />

              <Tooltip
                cursor={{ stroke: theme.palette.brand }}
                content={<ChartTooltip toolTipLabels={toolTipLabels} />}
                isAnimationActive={false}
              />
            </ComposedChart>
          }
        />
      </>
    );
  }
);
