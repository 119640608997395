import { IWatchlistResponse, IWatchlistUpdate } from 'api/types';
import Dropdown from 'components/common/Dropdown';
import { StringListItem } from 'components/common/Dropdown/Dropdown';
import { useCalculatorFilter } from 'components/common/HeatMapCorrelation/FilterContext';
import WatchlistContext, {
  useWatchlist,
} from 'components/common/HeatMapCorrelation/HeatMap/Watchlist/WatchlistContext';
import { IWatchlistRowIds } from 'components/common/HeatMapCorrelation/HeatMap/Watchlist/WatchlistRow/types';
import { useHeatMapView } from 'components/common/HeatMapCorrelation/HeatMapCorrelation';
import Spinner from 'components/common/Spinner';
import immer from 'immer';
import isEqual from 'lodash.isequal';
import sortBy from 'lodash.sortby';
import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import useSWR from 'swr';
import { Button, Row as RowWrapper, WatchlistName } from './styles';
import api from './WatchlistApi';
import WatchlistRow from './WatchlistRow';
import Filters from 'components/common/Filters/Filters';
import { Views } from '../constants';
import HeatMapHeader from '../../HeatMapHeader';
import { useModal } from 'hooks';
import { InstrumentPair } from 'types';
import { useHeatMapApi } from '../useHeatMapApi';
import { useWatchlistApi } from './useWatchlistApi';

interface WatchListProps {
  disabled?: boolean;
}

export const convertInstrumentsToVersionWithLeftRight = (instruments: InstrumentPair[]) => {
  return instruments
    .filter(elements => elements.instrument_x && elements.instrument_y)
    .map(elements => ({
      left: elements.instrument_x.ticker,
      right: elements.instrument_y.ticker,
    }));
};
const Watchlist: React.FC<WatchListProps> = ({ disabled = false }) => {
  const { useWatchlistsData } = useWatchlistApi();
  const { setConfirmModal } = useModal();
  const onModalOpen = async () => {
    setConfirmModal(<h3>Do you want to delete?</h3>, { onYes: deleteWatchlist });
  };
  const { data, mutate } = useWatchlistsData();

  const { selectedWatchlistId, setSelectedWatchlistId } = useHeatMapView();

  const { data: selectedWatchlist } = useSWR<IWatchlistResponse | null>(
    ['get_watchlist', data, selectedWatchlistId],
    async (_, dataParam: IWatchlistResponse[] | undefined, selectedWatchlistIdParam: number | null) => {
      return dataParam?.find(watchlist => watchlist.id === selectedWatchlistIdParam) || dataParam?.[0] || null;
    },
    {
      fallbackData: null,
    }
  );
  const { setSelectedInstrument, selectedInstrument } = useCalculatorFilter();

  async function createWatchlist(watchlistData: { instruments: { left: string; right: string }[]; name: string }) {
    await mutate(async () => {
      const watchlist = await api.createWatchlist(watchlistData);
      setSelectedWatchlistId(watchlist.id);
      return [...data!, watchlist];
    });
  }

  async function createNewWatchlist() {
    await createWatchlist({ instruments: [{ left: 'EURUSD', right: 'USDJPY' }], name: 'watchlist' });
  }

  async function deleteWatchlist() {
    await mutate(async () => {
      await api.deleteWatchlist(selectedWatchlist!.id);
      const newData = data!.filter(watchlist => watchlist.id !== selectedWatchlist!.id);
      if (newData[0]) {
        setSelectedWatchlistId(newData[0].id);
      }
      return newData;
    });
  }

  const updateWatchlist = async (newWatchlist: IWatchlistUpdate) => {
    await mutate(async previousData => {
      const updatedWatchlist = await api.updateWatchlist(selectedWatchlist!.id, newWatchlist);
      return immer(previousData!, draft => {
        draft[draft.findIndex(watchlist => watchlist.id === updatedWatchlist.id)] = updatedWatchlist;
      });
    });
  };

  useEffect(() => {
    if (!data) {
      return;
    }
    if (data.length === 0) {
      createNewWatchlist();
    }
    if (selectedWatchlist) {
      setSelectedWatchlistId(selectedWatchlist.id);
      const index = selectedWatchlist.instruments.findIndex(
        el =>
          el.instrument_y.ticker === selectedInstrument?.instrument_y.ticker &&
          el.instrument_x.ticker === selectedInstrument.instrument_x.ticker
      );
      if (index > -1) {
        setSelectedInstrument(selectedWatchlist.instruments[index]);
        return;
      }

      setSelectedInstrument(selectedWatchlist.instruments[0]);
    }
  }, [selectedWatchlist, data]);

  return (
    <>
      <WatchlistContext.Provider value={{ watchlist: selectedWatchlist!, updateWatchlist }}>
        <Row>
          <HeatMapHeader disabled={disabled} />
          <Filters view={Views.WATCHLIST} disabled={disabled} />
        </Row>
        <Row>
          <Col>
            <Row>
              <RowWrapper>
                <WatchlistOptions
                  disabled={disabled}
                  createNewWatchlist={createNewWatchlist}
                  deleteWatchlist={onModalOpen}
                  watchlists={data || []}
                  selectWatchlist={watchlist => setSelectedWatchlistId(watchlist.id)}
                />
              </RowWrapper>
            </Row>
            {selectedWatchlist && (
              <Row>
                <WatchlistInstruments disabled={disabled} />
              </Row>
            )}
          </Col>
        </Row>
      </WatchlistContext.Provider>
    </>
  );
};

const WatchlistInstruments = ({ disabled = false }: { disabled: boolean }) => {
  const { useFilteredHeatMapData } = useHeatMapApi();
  const { dateFilter, calculatorFilter, rolling, timeWindow, timeDecay } = useCalculatorFilter();
  const { watchlist: selectedWatchlist, updateWatchlist } = useWatchlist();

  const { data, mutate } = useFilteredHeatMapData({
    calculator: calculatorFilter.calculator,
    endDate: dateFilter.endDate,
    startDate: dateFilter.startDate,
    rolling,
    timeWindow,
    timeDecay,
    watchlistId: selectedWatchlist?.id,
  });

  const updateWatchlistInstrument = async (index: number, watchlistRow: IWatchlistRowIds) => {
    if (selectedWatchlist) {
      const tmpSelectedWatchlist = {
        ...selectedWatchlist,
        instruments: convertInstrumentsToVersionWithLeftRight(selectedWatchlist.instruments),
      };

      const newWatchlist = immer(tmpSelectedWatchlist, draft => {
        draft.instruments[index] = watchlistRow;
      });
      if (isEqual(tmpSelectedWatchlist, newWatchlist)) return;

      await updateWatchlist({ ...newWatchlist, instruments: newWatchlist.instruments.filter(el => el) });
      await mutate();
    }
  };

  const deleteWatchlistInstrument = async (index: number) => {
    const newWatchlist = immer(selectedWatchlist!, draft => {
      draft.instruments.splice(index, 1);
    });
    await updateWatchlist({
      ...newWatchlist,
      instruments: convertInstrumentsToVersionWithLeftRight(newWatchlist.instruments),
    });
    await mutate();
  };

  const splittedData = useMemo(() => {
    const instruments = Array.from({ length: 10 }).map((_, index) => {
      return (selectedWatchlist?.instruments! || [])[index];
    });
    const heatMapInstruments = instruments.map((instrument, index) => {
      let tmp = {
        instrument_x: instrument?.instrument_x,
        instrument_y: instrument?.instrument_y,
      };
      if (instrument) {
        tmp = {
          ...data?.heat_map?.[index],
          ...tmp,
        };
      }
      return tmp;
    });
    return {
      firstHalf: heatMapInstruments.slice(0, 5),
      secondHalf: heatMapInstruments.slice(5, 10),
    };
  }, [data, selectedWatchlist]);

  return (
    <>
      <Col style={{ paddingLeft: '0' }}>
        {splittedData.firstHalf.map((item, index) => (
          <WatchlistRow
            key={index}
            data={item}
            disabled={disabled}
            onChange={watchlistRow => {
              updateWatchlistInstrument(index, watchlistRow);
            }}
            onDelete={() => deleteWatchlistInstrument(index)}
          />
        ))}
      </Col>
      <Col>
        {splittedData.secondHalf.map((item, index) => (
          <Row key={index}>
            <WatchlistRow
              key={index}
              data={item}
              disabled={disabled}
              onChange={watchlistRow => {
                updateWatchlistInstrument(index + 5, watchlistRow);
              }}
              onDelete={() => deleteWatchlistInstrument(index + 5)}
            />
          </Row>
        ))}
      </Col>
    </>
  );
};

const WatchlistOptions = ({
  watchlists,
  createNewWatchlist,
  deleteWatchlist,
  selectWatchlist,
  disabled = false,
}: {
  watchlists: IWatchlistResponse[];
  selectWatchlist(watchlist: IWatchlistResponse): void;
  createNewWatchlist(): Promise<void>;
  deleteWatchlist(): Promise<void>;
  disabled: boolean;
}) => {
  const { watchlist: selectedWatchlist, updateWatchlist } = useWatchlist();
  const [value, updateValue] = useState<string>('');

  useEffect(() => {
    if (selectedWatchlist) {
      updateValue(selectedWatchlist.name);
    }
  }, [selectedWatchlist]);

  if (!selectedWatchlist) {
    return <Spinner />;
  }

  const sortedWatchlists = sortBy(watchlists, 'name');

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Button onClick={createNewWatchlist} disabled={disabled}>
        Add new
      </Button>{' '}
      &nbsp; | &nbsp;
      <Dropdown
        disabled={disabled}
        label="My watchlists"
        options={sortedWatchlists.map(watchlist => ({ value: watchlist.id, label: watchlist.name }))}
      >
        {sortedWatchlists.map(watchlist => (
          <StringListItem
            key={watchlist.id}
            option={{ value: watchlist.id, label: watchlist.name }}
            onChange={() => selectWatchlist(watchlist)}
          />
        ))}
      </Dropdown>
      <span> &nbsp; - &nbsp; </span>
      <WatchlistName>
        <input
          key={selectedWatchlist.name}
          data-testid="selected-watchlist"
          data-value={value}
          value={value}
          disabled={disabled}
          onChange={event => updateValue(event.target.value)}
          onBlur={event => {
            updateWatchlist({
              ...selectedWatchlist,
              instruments: convertInstrumentsToVersionWithLeftRight(selectedWatchlist.instruments),
              name: event.target.value,
            });
          }}
        />
      </WatchlistName>
      &nbsp; | &nbsp;
      <Button onClick={deleteWatchlist} disabled={disabled}>
        Delete
      </Button>
    </div>
  );
};
export default Watchlist;
