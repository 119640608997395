import styled, { css } from 'styled-components';

export const ChartPointer = styled.div<{ variant: PointerVariant; value: number }>`
  position: absolute;
  top: 26px;
  left: 70px;
  display: block;
  width: 0;
  height: 0;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  transform: ${({ value }) => `rotate(${value * 100}deg)`};
  transform-origin: 50% 100%;
  transition: transform 0.5s ease;
  ${({ variant }) =>
    variant === PointerVariant.WHITE
      ? css`
          z-index: 1;
          border-bottom: 36px solid #fff;
        `
      : css`
          z-index: 2;
          border-bottom: 36px solid #66b3ff;
        `}
`;

export enum PointerVariant {
  WHITE,
  BLUE,
}

export const Chart = styled.div`
  position: relative;
  margin-top: auto;
`;

export const ChartImage = styled.img`
  max-height: 60px;
  margin: 10px 20px;
`;

export const ChartFill = styled.svg<{ value: number }>`
  position: absolute;
  top: 26px;
  left: 38px;
  transform: rotate(${({ value }) => value * 100 - 90}deg);
`;
export const ChartWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-bottom: 24px;
`;
