import React, { FC, ReactElement } from 'react';
import ReactModal from 'react-modal';

import { ModalAdapterProps } from './types';

const ModalAdapter: FC<ModalAdapterProps> = ({ className, ...props }): ReactElement => (
  <ReactModal
    portalClassName={className}
    className={`${className}__content`}
    bodyOpenClassName={`${className}__body`}
    overlayClassName={`${className}__overlay`}
    testId="modal-component"
    {...props}
  />
);

export default ModalAdapter;
