import { createContext, useContext } from 'react';

interface IRiskContext {
  marketRisk24hData: { date: string; [key: string]: string }[] | undefined | null;
  creditRisk24hData: { date: string; [key: string]: string }[] | undefined | null;
  setMarketRisk24hData: any;
  setCreditRisk24hData: any;
  latestDate: string | null;
  setLatestDate: any;
  marketRiskIsValidating: boolean;
  setMarketRiskIsValidating: (val: boolean) => void;
  creditRiskIsValidating: boolean;
  setCreditRiskIsValidating: (val: boolean) => void;
  isValidating: boolean;
}

export const RiskContext = createContext<IRiskContext>({
  marketRisk24hData: null,
  creditRisk24hData: null,
  setMarketRisk24hData: () => {},
  setCreditRisk24hData: () => {},
  setLatestDate: () => {},
  latestDate: null,
  marketRiskIsValidating: false,
  setMarketRiskIsValidating: () => null,
  creditRiskIsValidating: false,
  setCreditRiskIsValidating: () => null,
  isValidating: false,
});

export const useRisk = () => useContext(RiskContext);

export default RiskContext;
