import { IDataPoint, IMovingAverages, IMovingAveragesRSIResponse } from 'api/types';
import { min } from 'date-fns';
import merge from 'lodash.merge';
import keyBy from 'lodash.keyby';

export enum PossibleDays {
  Days5 = '5',
  Days10 = '10',
  Days20 = '20',
  Days50 = '50',
  Days100 = '100',
  Days200 = '200',
}

interface IMovingAveragesRSIChartData {
  days: string[];
  movingAverages: { date: string; value: string }[];
  data: {
    date: string;
    moving_averages50: string;
    moving_averages100: string;
    moving_averages200: string;
    prices: string;
    rsi: string;
  }[];
}

export class MovingAveragesRSIData {
  public static fromMovingAveragesRSIResponse(movingAveragesRSIResponse?: IMovingAveragesRSIResponse) {
    if (movingAveragesRSIResponse) {
      const movingAverages: IMovingAverages = movingAveragesRSIResponse.moving_averages;
      const { prices, rsi } = movingAveragesRSIResponse;

      let movingAverageRSIData: any = [];
      let movingAveragesValues: IDataPoint[] = [];
      for (const [days, average] of Object.entries(movingAverages)) {
        movingAveragesValues = movingAveragesValues.concat(average as IDataPoint[]);
        if (movingAverageRSIData.length === 0) {
          movingAverageRSIData = movingAverageRSIData.concat(
            average!.map(({ date, value }) => ({ date, [`moving_averages${days}`]: value }))
          );
        } else {
          movingAverageRSIData = movingAverageRSIData.map((averages: any) => {
            return {
              ...averages,
              [`moving_averages${days}`]: average!.find(({ date }) => averages.date === date)!.value,
            };
          });
        }
      }
      const mappedRsi = rsi.map(({ date, value }) => ({ date, rsi: value }));
      const mappedPrices = prices.map(({ date, value }) => ({ date, prices: value }));
      const merged = merge(keyBy(movingAverageRSIData, 'date'), keyBy(mappedRsi, 'date'), keyBy(mappedPrices, 'date'));
      const movingAverageRSI = {
        days: Object.keys(movingAverages),
        movingAverages: movingAveragesValues,
        data: Object.values(merged).reverse(),
      };
      return new MovingAveragesRSIData(movingAverageRSI);
    }
    return new MovingAveragesRSIData({ days: [], movingAverages: [], data: [] });
  }

  private constructor(public data: IMovingAveragesRSIChartData) {}

  get chartData() {
    return this.data;
  }

  get minDate() {
    return min(this.data.data.map(element => new Date(element.date)));
  }

  get priceValues() {
    return this.data.data.map(e => parseFloat(e.prices));
  }

  get csvData() {
    return this.data.data.map(element => [...Object.values(element)]);
  }

  get minPrice() {
    return Math.min(...this.priceValues);
  }

  get maxPrice() {
    return Math.max(...this.priceValues);
  }

  get minMovingAverage() {
    const a = this.data.movingAverages.map(e => parseFloat(e.value));
    return Math.min(...a);
  }

  get maxMovingAverage() {
    const a = this.data.movingAverages.map(e => parseFloat(e.value));
    return Math.max(...a);
  }

  get minRSI() {
    const a = this.data.data.map(e => e.rsi);
    return Math.min(Number(...a));
  }

  get maxRSI() {
    const a = this.data.data.map(e => e.rsi);
    return Math.max(Number(...a));
  }
}
