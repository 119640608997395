import React, { FC, useEffect, useState } from 'react';

import crossIcon from 'assets/icons/cross.svg';
import BaseButton from 'components/common/BaseButton';
import { useCalculatorFilter } from 'components/common/HeatMapCorrelation/FilterContext';

import { assertFilledRow, WatchlistRowProps } from './types';
import { ActiveResult, CrossIcon, instrumentSelectStyles, Row, Value, Wrapper, InstrumentValue } from './styles';
import { InstrumentSelect } from 'components/common/InstrumentSelect/InstrumentSelect';

const WatchlistRow: FC<WatchlistRowProps & { disabled: boolean }> = ({
  data,
  onChange,
  onDelete,
  disabled = false,
}) => {
  const { setSelectedInstrument, selectedInstrument } = useCalculatorFilter();
  const [isResultActive, setIsResultActive] = useState(false);
  const { instrument_x, instrument_y, positive, value } = data;

  useEffect(() => {
    if (!selectedInstrument) return;
    if (
      selectedInstrument.instrument_x.ticker === instrument_x?.ticker &&
      selectedInstrument.instrument_y.ticker === instrument_y?.ticker
    )
      setIsResultActive(true);
    else setIsResultActive(false);
  }, [selectedInstrument]);

  if (!data.value) {
    return (
      <EmptyWatchlistRow
        key={`${data.instrument_x?.ticker}${data.instrument_y?.ticker}`}
        left={data.instrument_x?.ticker}
        right={data.instrument_y?.ticker}
        onChange={onChange}
      />
    );
  }
  const onLeftValueChange = (newValue: string | null) => {
    const { instrument_y: instrumentY, instrument_x: instrumentX, ...rest } = data;
    onChange?.({
      ...rest,
      right: instrumentY!.ticker,
      left: newValue!,
    });
  };
  const onRightValueChange = (newValue: string | null) => {
    const { instrument_y: instrumentY, instrument_x: instrumentX, ...rest } = data;
    onChange?.({
      ...rest,
      left: instrumentX!.ticker,
      right: newValue!,
    });
  };
  const onValueClick = (): void => {
    assertFilledRow(data);
    setSelectedInstrument(data);
    setIsResultActive(true);
  };
  return (
    <Wrapper data-testid="watchlist-row">
      <Row>
        <InstrumentSelect
          value={instrument_x?.ticker}
          hover={instrument_x?.full_name}
          onChange={onLeftValueChange}
          styles={instrumentSelectStyles}
          disabled={disabled}
        />
      </Row>
      <Row>
        <InstrumentSelect
          value={instrument_y?.ticker}
          hover={instrument_y?.full_name}
          onChange={onRightValueChange}
          styles={instrumentSelectStyles}
          disabled={disabled}
        />
      </Row>
      <InstrumentValue>
        <BaseButton onClick={onDelete} disabled={disabled}>
          <CrossIcon src={crossIcon} alt="Cross" />
        </BaseButton>
        <Value positive={positive} onClick={onValueClick} data-testid="correlation-index">
          {isResultActive ? (
            <ActiveResult data-testid="watchlist-row-active">{Number(value).toFixed(2)}</ActiveResult>
          ) : (
            Number(value).toFixed(2)
          )}
        </Value>
      </InstrumentValue>
    </Wrapper>
  );
};

const EmptyWatchlistRow: React.FC<{
  onChange: (row: { left: string; right: string }) => void;
  left?: string | null;
  right?: string | null;
}> = ({ onChange, left, right }) => {
  const [leftState, setLeft] = useState<string | null | undefined>(left);
  const [rightState, setRight] = useState<string | null | undefined>(right);

  useEffect(() => {
    if (leftState && rightState) {
      onChange({ left: leftState, right: rightState });
    }
  }, [leftState, rightState]);

  return (
    <Wrapper>
      <Row>
        <InstrumentSelect value={leftState} onChange={setLeft} styles={instrumentSelectStyles} />
      </Row>
      <Row>
        <InstrumentSelect value={rightState} onChange={setRight} styles={instrumentSelectStyles} />
      </Row>
      <InstrumentValue>
        <div>&nbsp;</div>
      </InstrumentValue>
    </Wrapper>
  );
};

export default WatchlistRow;
