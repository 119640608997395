import React, { useState } from 'react';
import { useModal } from 'hooks';
import CorrelationHelpModal from './CorrelationHelpModal';
import { Navigation } from './styles';
import { useCalculatorFilter, LinkButton, HelpButton, CorrelationChart, DeviationChart } from 'components/common';
import { Col, Row } from 'react-grid-system';

interface ICorrelation {
  disabled?: boolean;
}

enum CorrelationTab {
  PERCENTILE = 'percentile',
  CORRELATION = 'correlation',
}

const Correlation: React.FC<ICorrelation> = ({ disabled = false }) => {
  const [selectedTab, setSelectedTab] = useState(CorrelationTab.CORRELATION);
  const { selectedInstrument } = useCalculatorFilter();
  const { setInfoModal } = useModal();

  const openModal = () => {
    setInfoModal(<CorrelationHelpModal />);
  };
  const handleSetSelectedTab = (selected: CorrelationTab) => {
    setSelectedTab(selected);
  };
  return (
    <>
      <Row align="center" justify="center" style={{ padding: 0 }}>
        <Col style={{ padding: 0 }}>
          <Navigation>
            <LinkButton
              onClick={() => handleSetSelectedTab(CorrelationTab.CORRELATION)}
              active={selectedTab === CorrelationTab.CORRELATION}
            >
              {selectedInstrument && selectedInstrument.instrument_x && selectedInstrument.instrument_y && (
                <>
                  {selectedInstrument.instrument_x.short_name}-{selectedInstrument.instrument_y.short_name}
                </>
              )}
            </LinkButton>
            <LinkButton
              disabled={disabled}
              onClick={() => handleSetSelectedTab(CorrelationTab.PERCENTILE)}
              active={selectedTab === CorrelationTab.PERCENTILE}
            >
              Percentile ranking
            </LinkButton>
            <HelpButton onClick={openModal} />
          </Navigation>
        </Col>
      </Row>
      <Row>
        <Col style={{ padding: 0 }}>
          {selectedTab === CorrelationTab.CORRELATION ? <CorrelationChart /> : <DeviationChart />}
        </Col>
      </Row>
    </>
  );
};
export default Correlation;
