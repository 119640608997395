import Api from 'utils/Api';
import { ICorrelationResponse } from 'api/types';
import { InstrumentPair } from 'types';
import { getDateString } from 'components/dateHelpers';

class CorrelationApi extends Api {
  async getCorrelationAndPriceChange(params: {
    endDate?: Date;
    rolling?: string;
    timeWindow: string;
    instruments: InstrumentPair | null;
  }): Promise<ICorrelationResponse> {
    try {
      const formattedParams: { [key: string]: string | string[] | null | undefined } = {
        end_date: params.endDate && getDateString(params.endDate),
        rolling: params.rolling,
        time_window: params.timeWindow,
        period: '6m',
        instruments: [params.instruments!.instrument_x.ticker, params.instruments!.instrument_y.ticker],
      };
      const response = await this.getWithDataVariantParam<ICorrelationResponse>('/v2/correlation_and_price_change', {
        params: formattedParams,
      });
      return response.data;
    } catch (error) {
      throw error.response.status;
    }
  }
}

export const correlationApi = new CorrelationApi();
