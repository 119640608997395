import React from 'react';
import { StyledDeviationHeader, StyledFilterInfo, StyledFilterWrapper } from './Deviation.styles';
import { useCalculatorFilter } from '../HeatMapCorrelation';
import { formatRange } from 'utils/Date';
import { CalculatorFilterValue, MethodOfCalculationFilterValue, TimeWindowValue } from '../HeatMapCorrelation/types';
import { MedianInfo } from './MedianInfo';
import { IDeviationResponse } from 'api/types';

interface DeviationHeaderProps {
  data: IDeviationResponse;
}

const methodOfCalculationOptions = {
  [MethodOfCalculationFilterValue.EquallyWeighted]: {
    label: 'Equally weighted',
    value: MethodOfCalculationFilterValue.EquallyWeighted,
  },
  [MethodOfCalculationFilterValue.ExponentiallyWeighted]: {
    label: 'Exponentially weighted',
    value: MethodOfCalculationFilterValue.ExponentiallyWeighted,
  },
};

export const DeviationHeader: React.FC<DeviationHeaderProps> = ({ data }) => {
  const {
    dateFilter,
    rolling,
    timeWindow,
    selectedInstrument,
    timeDecay,
    calculatorName,
    calculatorFilter,
  } = useCalculatorFilter();

  const renderRollingValue = () => {
    if (calculatorName === CalculatorFilterValue.Average) return '5 Day | Time Window: 1 Month |';
    return `${rolling} Day | Time Window: ${TimeWindowValue[timeWindow]} Month |`;
  };
  return (
    <StyledDeviationHeader>
      <StyledFilterWrapper>
        <StyledFilterInfo>
          {selectedInstrument?.instrument_x.short_name} - {selectedInstrument?.instrument_y.short_name} Correlation
        </StyledFilterInfo>
        <StyledFilterInfo>
          Date: {dateFilter.endDate && formatRange(dateFilter.startDate, dateFilter.endDate)}
        </StyledFilterInfo>
        <StyledFilterInfo>Rolling period: {renderRollingValue()}</StyledFilterInfo>
        <StyledFilterInfo>
          Method of weighting:{' '}
          {calculatorFilter.calculator === CalculatorFilterValue.Rolling
            ? methodOfCalculationOptions[calculatorFilter.methodOfCalculation.method].label
            : methodOfCalculationOptions[MethodOfCalculationFilterValue.EquallyWeighted].label}{' '}
          |
        </StyledFilterInfo>
        {calculatorFilter.calculator === CalculatorFilterValue.Rolling &&
        calculatorFilter.methodOfCalculation.method === MethodOfCalculationFilterValue.ExponentiallyWeighted ? (
          <StyledFilterInfo>Decay Factor: {timeDecay}</StyledFilterInfo>
        ) : null}
      </StyledFilterWrapper>
      <MedianInfo data={data} />
    </StyledDeviationHeader>
  );
};
