import { createContext, useContext } from 'react';
import { IWatchlistResponse, IWatchlistUpdate } from 'api/types';

export const WatchlistContext = createContext<IWatchlistContext>({
  watchlist: null,
  async updateWatchlist(_newWatchlist: IWatchlistUpdate) {},
});

interface IWatchlistContext {
  watchlist: IWatchlistResponse | null;

  updateWatchlist(newWatchlist: IWatchlistUpdate): Promise<void>;
}

export const useWatchlist = () => useContext(WatchlistContext);

export default WatchlistContext;
