import styled from 'styled-components';
import BaseButton from 'components/common/BaseButton';

export const Box = styled.div`
  width: 48%;
`;

export const Row = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const Button = styled(BaseButton)`
  font-size: inherit;
  text-decoration: underline;
`;

export const WatchlistName = styled.div`
  input {
    width: 140px;
    color: ${({ theme }) => theme.palette.white};
    background-color: black;
    border: none;
    border-color: black !important;
    box-shadow: none;
  }
`;
