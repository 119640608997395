import { format } from 'date-fns';

export const replaceDashToSlash = (date?: string | null) => {
  if (date) return new Date(date.replace(/-/g, '/'));
  return undefined;
};
export const formatDate = (date: Date) => {
  const pattern = 'E MMM dd yyyy';
  return format(date, pattern);
};
export const formatRange = (startDate: Date | undefined, endDate: Date) => {
  return startDate ? `${formatDate(startDate)} - ${formatDate(endDate)}` : formatDate(endDate);
};
