import React from 'react';
import { Popover } from 'components/common';
import { StyledMedianDropdown } from './Deviation.styles';

interface MedianDropdownProps {
  label: string;
}

export const MedianDropdown: React.FC<MedianDropdownProps> = ({ children, label }) => {
  return (
    <Popover closeable label={label} position={-1} showArrow>
      <StyledMedianDropdown>{children}</StyledMedianDropdown>
    </Popover>
  );
};
