import { Bar, ComposedChart, Label, Line, Tooltip, XAxis, YAxis } from 'recharts';
import { format, parseISO } from 'date-fns';
import React, { memo, useRef } from 'react';
import Select from 'react-select';
import theme from 'styles/theme';
import Spinner from 'components/common/Spinner';
import { ChartTooltip } from 'components/common/Charts/ChartToolTip';
import { ChartLegend } from 'components/Charts/ChartLegend';
import { chartStyles, FilterName, FilterRow } from 'components/RegimeChange/styles';
import { useBoolean, useLocalStorage } from 'hooks';
import { IInstrument } from 'components/common/Charts/types';
import { MACD } from 'components/RegimeChange/MACD/helpers';
import { Chart } from 'components/RegimeChange/Chart';
import { CSVIMGExport } from 'components/common/DataExport/CSVIMGExport';
import { DataExportRow } from 'components/common/DataExport/styles';
import { Col, Row } from 'react-grid-system';
import { selectStyles } from './styles';
import { ICalculationOptionsInterface, IGraph } from '../types';
import { DEFAULT_OPTIONS } from '../ChartOptions';
import { useRegimeChangeApi } from '../useRegimeChangeApi';
import { ChartTitle } from '../ChartTitle';

export const calculationOptions: ICalculationOptionsInterface[] = [
  {
    label: '(12,26,9)',
    value: { firstPeriod: 12, secondPeriod: 26, thirdPeriod: 9 },
  },
  {
    label: '(3,10,16)',
    value: { firstPeriod: 3, secondPeriod: 10, thirdPeriod: 16 },
  },
  {
    label: '(5,44,5)',
    value: { firstPeriod: 5, secondPeriod: 44, thirdPeriod: 5 },
  },
];

const MACD_KEY = 'macdCalculationFilterState';

export const MACDChart: React.FC<IGraph> = memo(({ instrument, options = DEFAULT_OPTIONS, instrumentVariants }) => {
  const { useMACDData } = useRegimeChangeApi();
  const [isMACDLineVisible, toggleMACDLine] = useBoolean(true);
  const [isMACDSignalVisible, toggleMACDSignal] = useBoolean(true);
  const [isHistogramVisible, toggleHistogram] = useBoolean(true);

  const [calculation, setCalculation] = useLocalStorage<ICalculationOptionsInterface | null>(
    MACD_KEY,
    calculationOptions[0]
  );
  const { data, isValidating } = useMACDData({ calculation, instrument: instrument?.value });

  const chartRef = useRef(null);

  if (isValidating || !data) return <Spinner />;
  if (!data.chartData.length) return <div>No data</div>;

  const toolTipLabels = {
    macd: options?.withFilterValues ? `MACD ${calculation?.label} Line: ` : 'MACD Line: ',
    signal: options?.withFilterValues ? `MACD ${calculation?.label} Signal: ` : 'MACD Signal: ',
    histogram: 'Histogram: ',
  };

  const instrumentsChartConfig = [
    {
      name: 'macd',
      label: 'MACD Line',
      color: '#22c8e6',
      isVisible: isMACDLineVisible,
      onClick: toggleMACDLine,
      yAxisId: 'left',
    },
    {
      name: 'signal',
      label: 'MACD Signal',
      color: 'white',
      isVisible: isMACDSignalVisible,
      onClick: toggleMACDSignal,
      yAxisId: 'left',
    },
    {
      name: 'histogram',
      label: 'Histogram',
      color: '#ec008c',
      isVisible: isHistogramVisible,
      onClick: toggleHistogram,
      yAxisId: 'right',
    },
  ];

  const chartTitle = 'MACD';
  const csvHeading = `Instrument,${instrument?.label}\r\nCalculation,"${calculation?.label}"`;
  const csvData = {
    fields: ['Date', 'Histogram', 'MACD Line', 'MACD Signal'],
    data: data.csvData,
  };

  return (
    <>
      {options?.header && (
        <>
          <DataExportRow justify="end">
            <Col xs={2}>
              <CSVIMGExport data={csvData} fileName={chartTitle} chartRef={chartRef} csvHeading={csvHeading} />
            </Col>
          </DataExportRow>
          <Row>
            <ChartTitle title={chartTitle} instrumentName={instrument?.label} instrumentVariants={instrumentVariants} />
          </Row>
          <FilterRow align="center">
            <FilterName>Calculation:</FilterName>
            <Select
              value={calculation}
              name="calculations"
              options={calculationOptions}
              styles={selectStyles}
              onChange={setCalculation}
            />
          </FilterRow>
        </>
      )}

      {data && (
        <Chart
          overflow={options.overflow}
          margin={options?.margin}
          chartName="macd-chart"
          ref={chartRef}
          legend={<ChartLegend instruments={instrumentsChartConfig as IInstrument[]} />}
          chart={
            <>
              <ComposedChart
                data={data.chartData}
                width={options?.width}
                height={options?.height}
                syncId={options?.syncId}
              >
                {isHistogramVisible && <Bar dataKey="histogram" fill="#ec008c" yAxisId="right" />}
                <Line
                  dataKey="macd"
                  hide={!isMACDLineVisible}
                  dot={false}
                  activeDot={false}
                  yAxisId="left"
                  stroke="#22c8e6"
                  isAnimationActive={false}
                />
                <Line
                  dataKey="signal"
                  hide={!isMACDSignalVisible}
                  dot={false}
                  activeDot={false}
                  yAxisId="left"
                  stroke="white"
                  isAnimationActive={false}
                />
                <YAxis
                  yAxisId="left"
                  dataKey="macd"
                  domain={[data.minMACD, data.maxMACD]}
                  tickLine={false}
                  axisLine={false}
                  width={75}
                  dx={0}
                  style={chartStyles.axis}
                >
                  {options?.sideLabels && (
                    <Label
                      value="MACD Values"
                      offset={0}
                      style={{ fill: 'white', textAnchor: 'middle', fontSize: '0.75rem' }}
                      angle={-90}
                      position="insideLeft"
                    />
                  )}
                </YAxis>
                <YAxis
                  yAxisId="right"
                  dataKey="histogram"
                  domain={[data.minHistogram, data.maxHistogram]}
                  orientation="right"
                  tickLine={false}
                  axisLine={false}
                  width={75}
                  dx={0}
                  style={chartStyles.axis}
                >
                  {options?.sideLabels && (
                    <Label
                      value="Histogram"
                      offset={0}
                      style={{ fill: 'white', textAnchor: 'middle', fontSize: '0.75rem' }}
                      angle={90}
                      position="insideRight"
                    />
                  )}
                </YAxis>

                <XAxis
                  hide={options?.xAxisHide}
                  dataKey="date"
                  tickLine={false}
                  axisLine={false}
                  tickFormatter={value => {
                    return value && format(parseISO(value), "MMM ''yy");
                  }}
                  dy={15}
                  dx={30}
                  interval={30}
                  style={chartStyles.axis}
                />

                <Tooltip
                  cursor={{ stroke: theme.palette.brand }}
                  content={<ChartTooltip toolTipLabels={toolTipLabels} />}
                  isAnimationActive={false}
                />
              </ComposedChart>
            </>
          }
        />
      )}
    </>
  );
});
