import { IChartOptions } from './types';

export const DEFAULT_OPTIONS: IChartOptions = {
  xAxisHide: false,
  height: 300,
  width: 1400,
  sideLabels: true,
  header: true,
  margin: 40,
  withFilterValues: false,
};
