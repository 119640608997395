import Api from 'utils/Api';
import { DataVariant } from '../../../hooks/useDataVariantParam/types';

export interface IInstrument {
  ticker: string;
  replacement: string;
  short_name: string;
  full_name: string;
  instrument_type: string;
  data_variants: DataVariant[];
}

export interface IGetInstrumentsQueryParams {
  quoteCurrency?: string;
  instrumentTypes?: string[];
}

class InstrumentSelectApi extends Api {
  public async getInstruments(params?: IGetInstrumentsQueryParams): Promise<IInstrument[]> {
    try {
      const response = await this.api.get<IInstrument[]>('/v1/instruments', {
        params: params && {
          quote_currency: params.quoteCurrency,
          instrument_types: params.instrumentTypes,
        },
      });
      return response.data;
    } catch (error) {
      throw error.response.status;
    }
  }
}

export const instrumentApi = new InstrumentSelectApi();
