import React, { ReactElement, forwardRef } from 'react';

import { StyledInput, Wrapper, Error, Label } from './styles';
import { InputProps } from './types';

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ label = null, error, ...props }, ref): ReactElement => (
    <Wrapper htmlFor={props.id}>
      {label && <Label>{label}</Label>}
      <StyledInput id={props.id} ref={ref} {...props} />
      {error && <Error>{error}</Error>}
    </Wrapper>
  )
);

export default Input;
