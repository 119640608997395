import styled from 'styled-components';
import { Row } from 'react-grid-system';

export const Wrapper = styled.div<{ positive: boolean; active: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100px;
  font-size: 0.75rem;
  text-align: center;
  text-transform: uppercase;
  border-style: solid;
  border-width: 3px;
  cursor: pointer;

  ${({ positive, active, theme }) => `
    background-color: ${active ? theme.palette.white : 'transparent'};
    color: ${active ? theme.palette.black : theme.palette.white};
    border-color: ${positive ? theme.palette.success : theme.palette.error};
  `}
`;

export const Value = styled.div<{ positive: boolean }>`
  color: ${({ positive, theme }) => (positive ? theme.palette.success : theme.palette.error)};
  font-size: 1rem;
`;
export const HeatMapTileRow = styled(Row)`
  .heatmap-tooltip {
    text-align: center;
  }
`;
