import React from 'react';
import { Info } from 'components/common/Info';

export const About = () => (
  <Info>
    <p>ABOUT</p>
    <p>
      Excalibur Pro features unique insight into specific macro-economic instruments across all asset classes. These
      instruments are those that are most sensitive to global and regional geo-political and economic shocks.
    </p>
    <p>
      We begin by discovering correlation relationships between all major currencies, emerging markets currencies, major
      government bonds, the largest stock exchanges and commodities that often drive economic outlooks. We recently
      added 20 cryptocurrencies to the mix to help understand the relationships between them and more traditional
      financial instruments. Each day, we compare these 143 instruments against each other, offering lists that total
      more than 10,000 correlated pairs.
    </p>
    <p>
      We help you explore these correlation relationships through different sets of calculations – giving you looks at
      correlation through both short-term and long-term windows – to help you detect trends in those relationships as
      they begin to develop. Our charts and graphs also help you determine which instrument in a pair might be
      responsible for a break in a traditional correlation relationship. To help better understand the more volatile
      relationships, we offer a distribution curve that lets you see how values over a given amount of time stack up
      against the median value.
    </p>
    <p>
      For those investors and traders concerned about risk in financial markets, we have developed metrics that help you
      understand when markets are moving closer to “risk on” or risk off” environments. Or when they appear to be
      risk-neutral.
    </p>
    <p>
      Many investors and traders try to capture momentum in individual instruments. We offer a unique guide to momentum
      through the Markov Process, an approach that looks to define future results through analysis of a current state,
      not historical data. It employs probability when trying to determine future value of random states.
    </p>
    <p>
      Like any momentum analysis, the trader or investor is best served by reviewing several different methods. To that
      end, we offer traditional Moving Average analysis alongside our Markov charts as well as Relative Strength Index
      information. Combined, all three can offer an insightful look into how an instrument is being driven.
    </p>
    <p>
      Finally, we provide a tool that helps the investor better understand the potential profits that can be generated
      from several pair-trading statistical arbitrage approaches – Cointegration, Correlation and Distance. For each of
      these, the investor can back-test an approach or monitor one in real time.
    </p>
  </Info>
);
