import React, { FC, ReactElement, useEffect, useRef, useState } from 'react';
import crossIcon from 'assets/icons/cross.svg';
import { ButtonWrapper, CloseButton, StyledModal, Title, Button } from './styles';
import { ModalProps } from '../types';
import theme from '../../../../styles/theme';

const DEFAULT_CONFIRM_MESSAGE = 'Are you sure?';
const ConfirmationModal: FC<ModalProps> = ({
  isOpen,
  title,
  onClose,
  children,
  cancelText = 'No',
  yesText = 'Yes',
  onYes,
}): ReactElement => {
  const [loading, setLoading] = useState(false);
  const ref = useRef(false);

  const handleYes = async () => {
    try {
      setLoading(true);
      if (onYes) await onYes();

      onClose();
    } finally {
      if (!ref) {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    return () => {
      ref.current = true;
    };
  }, []);

  return (
    <StyledModal isOpen={isOpen} contentLabel={title as string} onRequestClose={onClose} ariaHideApp={false}>
      <CloseButton onClick={onClose}>
        <img src={crossIcon} alt="Close" />
      </CloseButton>

      {title && <Title>{title}</Title>}
      {children || <h3>{DEFAULT_CONFIRM_MESSAGE}</h3>}
      <ButtonWrapper>
        <Button
          type="button"
          color={theme.palette.gray['600']}
          hoverColor={theme.palette.gray['500']}
          onClick={onClose}
          data-testid="cancel"
          disabled={loading}
        >
          {cancelText}
        </Button>
        <Button
          type="button"
          color={theme.palette.red1}
          hoverColor={theme.palette.red2}
          onClick={handleYes}
          data-testid="confirm"
          disabled={loading}
        >
          {yesText}
        </Button>
      </ButtonWrapper>
    </StyledModal>
  );
};

export default ConfirmationModal;
