import { Updater } from 'use-immer';

export interface FilterContextData {
  dateFilter: DateFilter;
  updateDateFilter: (updater: (prevState: DateFilter) => DateFilter) => void;
  calculatorFilter: CalculatorFilter;
  updateCalculatorFilter: Updater<CalculatorFilter>;
  changeCalculatorFilterType: (type: CalculatorFilterValue, updateCalculatorFilter: Updater<CalculatorFilter>) => void;
  changeTimePeriod: (period: PeriodFilterValue, updateCalculatorFilter: Updater<CalculatorFilter>) => void;
  changeTimeWindow: (timeWindow: TimeWindowFilterValue, updateCalculatorFilter: Updater<CalculatorFilter>) => void;
  changeAveragePeriod: (
    averagePeriod: AveragePeriodFilterValue | undefined,
    updateCalculatorFilter: Updater<CalculatorFilter>
  ) => void;
  changeMethodOfCalculation: (
    method: MethodOfCalculationFilterValue,
    updateCalculatorFilter: Updater<CalculatorFilter>
  ) => void;
  changeTimeDecay: (timeDecay: string, updateCalculatorFilter: Updater<CalculatorFilter>) => void;
  calculatorName: string;
  rolling: string | undefined;
  timeWindow: string;
  timeDecay?: string;
  categoriesFilter: CategoriesFilter;
  updateCategoriesFilter: Updater<CategoriesFilter>;
}

export interface DateFilter {
  startDate: Date | undefined;
  endDate: Date | undefined;
}

export type CalculatorFilter = RollingCalculatorFilter | AverageCalculatorFilter;

export type CategoriesFilter = Set<string[]>;

export interface RollingCalculatorFilter {
  calculator: CalculatorFilterValue.Rolling;
  period: PeriodFilterValue;
  timeWindow: TimeWindowFilterValue;
  methodOfCalculation: MethodOfCalculationFilter;
}

export interface AverageCalculatorFilter {
  calculator: CalculatorFilterValue.Average;
  averagePeriod: AveragePeriodFilterValue | undefined;
}

export function assertCalculatorIsRolling(val: CalculatorFilter): asserts val is RollingCalculatorFilter {
  if (val.calculator !== CalculatorFilterValue.Rolling) {
    throw new Error('Not a rolling calculator');
  }
}

export function assertCalculatorIsAverage(val: CalculatorFilter): asserts val is AverageCalculatorFilter {
  if (val.calculator !== CalculatorFilterValue.Average) {
    throw new Error('Not an average calculator');
  }
}

export enum CalculatorFilterValue {
  Rolling = 'rolling',
  Average = 'average',
}

export enum PeriodFilterValue {
  FiveDay = '5',
  TenDay = '10',
}

export enum TimeWindowFilterValue {
  OneMonth = '22',
  ThreeMonths = '65',
  SixMonths = '130',
}

export enum AveragePeriodFilterValue {
  FiveDay = '5',
  TenDay = '10',
  OneMonth = '1m',
  ThreeMonths = '3m',
  SixMonths = '6m',
}

export const AveragePeriodTime: { [key: string]: string } = {
  [AveragePeriodFilterValue.FiveDay]: '5',
  [AveragePeriodFilterValue.TenDay]: '10',
  [AveragePeriodFilterValue.OneMonth]: TimeWindowFilterValue.OneMonth,
  [AveragePeriodFilterValue.ThreeMonths]: TimeWindowFilterValue.ThreeMonths,
  [AveragePeriodFilterValue.SixMonths]: TimeWindowFilterValue.SixMonths,
};
export const TimeWindowValue: { [key: string]: string } = {
  [TimeWindowFilterValue.OneMonth]: '1',
  [TimeWindowFilterValue.ThreeMonths]: '3',
  [TimeWindowFilterValue.SixMonths]: '6',
};
type MethodOfCalculationFilter = EquallyWeightedFilter | ExponentiallyWeightedFilter;

export enum MethodOfCalculationFilterValue {
  EquallyWeighted = 'equallyWeighted',
  ExponentiallyWeighted = 'exponentiallyWeighted',
}

export interface EquallyWeightedFilter {
  method: MethodOfCalculationFilterValue.EquallyWeighted;
}

export interface ExponentiallyWeightedFilter {
  method: MethodOfCalculationFilterValue.ExponentiallyWeighted;
  decayFactor: DecayFactorFilter;
}

export function assertMethodOfCalculationIsExponentiallyWeighted(
  val: MethodOfCalculationFilter
): asserts val is ExponentiallyWeightedFilter {
  if (val.method !== MethodOfCalculationFilterValue.ExponentiallyWeighted) {
    throw new Error('Not an exponentially weighted method');
  }
}

export type DecayFactorFilter = string;

export interface ISWRKeys {
  key?: string;
}

export interface IHeatMapParams extends ISWRKeys {
  calculator: CalculatorFilterValue;
  endDate?: Date;
  startDate?: Date;
  rolling?: string;
  timeWindow: string;
  timeDecay?: string;
  categories: string[];
}

export interface IFilteredHeatMapParams extends ISWRKeys {
  calculator: CalculatorFilterValue;
  endDate?: Date;
  startDate?: Date;
  rolling?: string;
  timeWindow: string;
  timeDecay?: string;
  watchlistId?: number;
}
