import styled from 'styled-components';
import { Link, PathMatch } from 'react-router-dom';

export const StyledLink = styled(Link)<{ active?: PathMatch | boolean | null; disabled?: boolean }>`
  padding: 0;
  color: ${({ active, theme, disabled }) => {
    if (disabled) return theme.palette.gray[400];
    if (active) return theme.palette.brand;
    return theme.palette.white;
  }};
  font-size: 1.25rem;
  text-align: left;
  text-transform: uppercase;
  text-decoration: none;
  background-color: transparent;
  border: 0;
  outline: none;
  transition: all 0.3s linear;
  ${({ theme, disabled }) => `
    cursor: ${disabled ? 'unset' : 'pointer'};
    
    &:hover {
      color: ${!disabled ? theme.palette.brand : theme.palette.gray[400]};
    }
  `}
`;
