import React from 'react';
import Spinner from '../Spinner';
import { useDeviationApi } from './useDeviationApi';
import { useCalculatorFilter } from '../HeatMapCorrelation';
import { CalculatorFilterValue } from '../HeatMapCorrelation/types';
import { DeviationHeader } from './DeviationHeader';
import { DeviationChartBody } from './DeviationChartBody';
import { SliderChart } from '../SliderChart';

export const DeviationChart: React.FC = () => {
  const { useDeviationData } = useDeviationApi();
  const { dateFilter, rolling, timeWindow, selectedInstrument, timeDecay, calculatorName } = useCalculatorFilter();

  const { data, isValidating } = useDeviationData({
    endDate: dateFilter.endDate,
    rolling: calculatorName === CalculatorFilterValue.Average || !rolling ? '5' : rolling,
    instruments: selectedInstrument,
    timeWindow: calculatorName === CalculatorFilterValue.Average ? '22' : timeWindow,
    timeDecay,
  });

  if (isValidating) return <Spinner />;
  if (!data) return <p>No data</p>;

  return (
    <section data-testid="deviation-chart-wrapper">
      <DeviationHeader data={data} />
      <DeviationChartBody data={data} />
      <SliderChart value={data.persistence} />
      <h2 style={{ fontSize: '14px', margin: 0, textTransform: 'uppercase' }}>Crossing the median</h2>
    </section>
  );
};
