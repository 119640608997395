import React from 'react';
import { InstrumentIndicator, InstrumentSwitch, VisibilityController } from 'components/common/CorrelationChart/styles';
import { IInstrumentDataProps } from 'components/common/Charts/types';

export const ChartLegend = ({ instruments }: IInstrumentDataProps) => {
  return (
    <VisibilityController data-testid="visibility-controller">
      {instruments.map(instrument => (
        <InstrumentSwitch
          key={instrument.name}
          active={instrument.isVisible}
          type="button"
          onClick={instrument.onClick}
        >
          <InstrumentIndicator color={instrument.color} /> {instrument.label}
        </InstrumentSwitch>
      ))}
    </VisibilityController>
  );
};
