import React, { ButtonHTMLAttributes, FC, ReactElement } from 'react';

import { Button } from './styles';

const BaseButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({ children, onClick, ...rest }): ReactElement => (
  <Button onClick={onClick} {...rest}>
    {children}
  </Button>
);

export default BaseButton;
