import styled from 'styled-components';
import ModalAdapter from '../ModalAdapter';

export const StyledInfoModal = styled(ModalAdapter)`
  position: relative;
  z-index: 3;

  &__overlay {
    position: fixed;
    background-color: rgba(128, 128, 128, 0.77);
    inset: 0;
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    ${({ fullWidth }: { fullWidth: boolean }) => (fullWidth ? 'width:95%;max-width:1450px;' : 'width: 750px;')};
    font-size: 0.9rem;
    text-align: center;
    outline: none;
    transform: translate(-50%, -50%);

    ${({ theme }) => `
      border-radius: ${theme.spacing(1)};
      color: ${theme.palette.black};
      background-color: ${theme.palette.white};
      box-shadow: 0 0 10px ${theme.palette.gray['800']};
      padding: ${theme.spacing(3)};
    `}
    .info-modal-content {
      max-height: 90vh;
      overflow-y: auto;
    }
  }
`;

export const InfoCloseButton = styled.button`
  position: absolute;
  width: 23px;
  height: 23px;
  background: transparent;
  border-radius: 50%;
  outline: none;
  cursor: pointer;

  ${({ theme }) => `
    top: ${theme.spacing(-1)};
    right: ${theme.spacing(-1)};
    color: ${theme.palette.black};
    border: 1px solid ${theme.palette.black};
    background-color: ${theme.palette.white};
  `}
  img {
    display: block;
    width: 100%;
  }
`;
