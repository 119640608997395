import { IInstrumentsResponse } from './RegimeChangeApi';
import { Instrument } from '../../types';

export class InstrumentData {
  static fromInstrumentResponse(response?: IInstrumentsResponse[]) {
    return new InstrumentData(response || []);
  }

  constructor(private readonly instruments: Instrument[]) {}

  get allInstruments() {
    return this.instruments;
  }

  getSelectOptions() {
    return this.instruments.map((instrument: Instrument) => ({
      value: instrument.ticker,
      label: instrument.full_name,
    }));
  }

  getCategoryByTicker(ticker?: string) {
    if (!ticker) return undefined;
    return this.instruments.find(instrument => instrument.ticker === ticker)?.instrument_type;
  }

  getVariantsByTicker(ticker?: string) {
    if (!ticker) return undefined;
    return this.instruments.find(instrument => instrument.ticker === ticker)?.data_variants;
  }

  formatChartLabels(category?: string) {
    if (category === 'bond') return 'Basis Point Change Since';
    return 'Percent Change Since';
  }
}
