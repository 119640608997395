import React from 'react';
import { StyledSlider, StyledSliderChart, StyledSliderCharValue } from './Slider.styles';

interface SliderChartProps {
  value: string;
  arr?: string[];
}

const DEFAULT_ARR = ['0.10', '0.20', '0.30', '0.40', '0.50', '0.60', '0.70', '0.80', '0.90'];
export const SliderChart: React.FC<SliderChartProps> = ({ value, arr }) => {
  const current = arr || DEFAULT_ARR;
  return (
    <StyledSliderChart>
      {current.map((el, index) => (
        <StyledSliderCharValue key={el} left={(100 / current.length) * (index + 1)}>
          {el}
        </StyledSliderCharValue>
      ))}
      <StyledSlider data-testid="chart-slider-value" data-value={Number(value)} value={Number(value)} />
    </StyledSliderChart>
  );
};
