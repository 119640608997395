import styled from 'styled-components';

export const Button = styled.button`
  padding: 0;
  color: ${({ theme }) => theme.palette.brand};
  font-size: 1.25rem;
  background-color: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
`;
