import trendingChart from 'assets/trending-chart.png';
import { useHeatMapView } from 'components/common/HeatMapCorrelation/HeatMapCorrelation';
import Spinner from 'components/common/Spinner';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { Chart, ChartFill, ChartImage, ChartPointer, ChartWrapper, PointerVariant } from './styles';
import { Views } from '../common/HeatMapCorrelation/HeatMap/constants';
import HeatMapHeader from '../common/HeatMapCorrelation/HeatMapHeader';
import { useTrendingApi } from './useTrendingApi';
import { Instrument } from '../../types';

const Dot = styled.span<{ color: string }>`
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 15px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
`;

const lowerRollingOptions = [
  {
    label: '5 day',
    value: '5',
  },
  {
    label: '10 day',
    value: '10',
  },
  {
    label: '1 Month',
    value: '22',
  },
  {
    label: '3 Months',
    value: '65',
  },
];

const higherRollingOptions = [
  {
    label: '10 day',
    value: '10',
  },
  {
    label: '1 Month',
    value: '22',
  },
  {
    label: '3 Months',
    value: '65',
  },
  {
    label: '6 Months',
    value: '130',
  },
];

const TRENDING_PARAMS_STATE_KEY = 'trendingParamsState';

const TrendingWatches: React.FC<{ lowerRolling: string; higherRolling: string }> = ({
  lowerRolling,
  higherRolling,
}) => {
  const { selectedWatchlistId, previousView } = useHeatMapView();
  const { useTrendingData } = useTrendingApi();

  const { data, isValidating } = useTrendingData({
    lowerRolling,
    higherRolling,
    watchlistId: previousView === Views.HEAT_MAP ? null : selectedWatchlistId,
  });

  if (isValidating || !data) return <Spinner />;

  return (
    <ChartWrapper>
      {!data.positives &&
        !data.negatives &&
        data.results.map(({ instrument_x, instrument_y, five_day_correlation, ten_day_correlation, value }) => (
          <TrendingChart
            key={`${value}-${instrument_x.ticker}-${instrument_y.ticker}`}
            white={Number(ten_day_correlation)}
            blue={Number(five_day_correlation)}
            leftInstrument={instrument_x}
            rightInstrument={instrument_y}
            value={Number(value)}
          />
        ))}
      {data.positives &&
        data.negatives &&
        data.results.map(({ instrument_x, instrument_y, five_day_correlation, ten_day_correlation, value }) => (
          <TrendingChart
            key={`${value}-${instrument_x.ticker}-${instrument_y.ticker}`}
            white={Number(ten_day_correlation)}
            blue={Number(five_day_correlation)}
            leftInstrument={instrument_x}
            rightInstrument={instrument_y}
            value={Number(value)}
          />
        ))}
    </ChartWrapper>
  );
};

export const Trending = () => {
  const savedState = localStorage.getItem(TRENDING_PARAMS_STATE_KEY);

  const [lowerRolling, setLowerRolling] = useState<{ value: string; label: string } | null>(
    savedState ? JSON.parse(savedState).lowerRolling : lowerRollingOptions[0]
  );
  const [higherRolling, setHigherRolling] = useState<{ value: string; label: string } | null>(
    savedState ? JSON.parse(savedState).higherRolling : higherRollingOptions[0]
  );

  const [isLowerMenuOpen, setIsLowerMenuOpen] = useState<boolean>(false);
  const [isHigherMenuOpen, setIsHigherMenuOpen] = useState<boolean>(false);

  useEffect(() => {
    localStorage.setItem(TRENDING_PARAMS_STATE_KEY, JSON.stringify({ lowerRolling, higherRolling }));
  }, [lowerRolling, higherRolling]);

  return (
    <>
      <HeatMapHeader />
      <Row align="center" justify="start">
        <Col style={{ maxWidth: '135px' }}>
          <p>Trend Setter:</p>
        </Col>
        <Col>
          <Row align="center" justify="start">
            <Dot color="#66b3ff" />
            <Select
              value={lowerRolling}
              defaultValue={lowerRollingOptions[0]}
              options={lowerRollingOptions}
              isOptionDisabled={option => Number(option.value) >= Number(higherRolling!.value)}
              placeholder="1st Time Period"
              styles={instrumentSelectStyles}
              onChange={value => {
                setLowerRolling(value);
                if (!isHigherMenuOpen) {
                  setIsLowerMenuOpen(false);
                }
              }}
              menuIsOpen={isLowerMenuOpen}
              onMenuOpen={() => {
                setIsHigherMenuOpen(true);
                setIsLowerMenuOpen(true);
              }}
              onBlur={() => {
                setIsLowerMenuOpen(false);
              }}
            />
          </Row>
        </Col>
        <Col>
          <Row align="center" justify="start">
            <Dot color="#bebebe" />
            <Select
              value={higherRolling}
              defaultValue={higherRollingOptions[0]}
              options={higherRollingOptions}
              isOptionDisabled={option => Number(option.value) <= Number(lowerRolling!.value)}
              placeholder="2nd Time Period"
              styles={instrumentSelectStyles}
              onChange={value => {
                setHigherRolling(value);
                if (!isLowerMenuOpen) {
                  setIsHigherMenuOpen(false);
                }
              }}
              menuIsOpen={isHigherMenuOpen}
              onMenuOpen={() => {
                setIsHigherMenuOpen(true);
                setIsLowerMenuOpen(true);
              }}
              onBlur={() => {
                setIsHigherMenuOpen(false);
              }}
            />
          </Row>
        </Col>
        <Col offset={{ md: 1 }} />
      </Row>
      {lowerRolling && higherRolling && (
        <TrendingWatches lowerRolling={lowerRolling.value} higherRolling={higherRolling.value} />
      )}
    </>
  );
};

function TrendingChart({
  white,
  blue,
  leftInstrument,
  rightInstrument,
  value,
}: {
  white: number;
  blue: number;
  leftInstrument: Instrument;
  rightInstrument: Instrument;
  value: number;
}) {
  const strokeDasharray = (Math.abs(blue - white) * 31.4 * 100) / 360;

  return (
    <B>
      <J>
        <ReactTooltip place="top" type="dark" effect="float" id="tool-tip" getContent={dataTip => `${dataTip}`} />
        <div data-tip={leftInstrument.full_name} data-for="tool-tip" className="trending-instrument">
          {leftInstrument.short_name}
        </div>
        <div data-tip={rightInstrument.full_name} data-for="tool-tip" className="trending-instrument">
          {rightInstrument.short_name}
        </div>
      </J>
      <Chart>
        <ChartImage src={trendingChart} alt="trending" />
        <ChartPointer variant={PointerVariant.WHITE} value={white} />
        <ChartPointer variant={PointerVariant.BLUE} value={blue} />
        <ChartFill height="70" width="70" viewBox="0 0 20 20" value={Math.min(white, blue)}>
          <circle r="10" cx="10" cy="10" fill="transparent" />
          <circle
            r="5"
            cx="10"
            cy="10"
            fill="transparent"
            stroke="gray"
            strokeWidth="10"
            strokeDasharray={`${strokeDasharray}px 31.4px`}
          />
        </ChartFill>
      </Chart>
      <H>
        <LeftValue>{blue.toFixed(2)}</LeftValue>
        <CenterValue value={value}>{value.toFixed(2)}</CenterValue>
        <RightValue>{white.toFixed(2)}</RightValue>
      </H>
    </B>
  );
}

const B = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 180px;
  padding: 10px 0;
`;

const J = styled.div`
  display: flex;
  gap: 14px;
  justify-content: space-between;
  width: 100%;
  padding: 8px;
  text-align: center;

  .trending-instrument {
    width: 50%;
    font-size: 15px;
  }
`;

const H = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const LeftValue = styled.div`
  padding: 0 10px;
  color: #66b3ff;
  border-right: 1px solid #bebebe;
`;

const CenterValue = styled.div<{ value: number }>`
  padding: 0 10px;
  color: ${({ value }) => (value > 0 ? '#00a651' : '#f20000')};
  border: 1px solid;
  border-color: ${({ value }) => (value > 0 ? '#00a651' : '#f20000')};
  border-radius: 10px;
`;

const RightValue = styled.div`
  padding: 0 10px;
  border-left: 1px solid #bebebe;
`;

export const instrumentSelectStyles = {
  option: (styles: any, state: any) => {
    const nonSelectedBackground = state.isFocused ? '#aaa' : 'black';
    const disabledColor = state.isDisabled ? 'gray' : 'white';
    return {
      ...styles,
      background: state.isSelected ? 'white' : nonSelectedBackground,
      color: state.isSelected ? 'black' : disabledColor,
    };
  },
  control: (styles: any) => {
    return {
      ...styles,
      width: '150px',
      background: 'black',
      border: '1px solid #aaa',
      borderRadius: '0',
    };
  },
  singleValue: (styles: any) => {
    return {
      ...styles,
      color: 'white',
    };
  },
  placeholder: (styles: any) => {
    return {
      ...styles,
      color: ({ theme }: any) => theme.palette.brand,
    };
  },
  menu: (styles: any) => {
    return {
      ...styles,
      zIndex: 1000,
      border: '1px solid gray',
      marginTop: 0,
      borderRadius: 0,
      background: 'black',
    };
  },
  input: (styles: any) => {
    return {
      ...styles,
      color: 'white',
    };
  },
};
