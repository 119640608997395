import React from 'react';

import { StyledSpinner } from './styles';

interface ISpinner {
  size?: 'sm' | 'lg';
}

const renderStrokeWidth = (size: 'sm' | 'lg') => {
  switch (size) {
    case 'sm':
      return '5';
    case 'lg':
      return '2';
    default:
      return '2';
  }
};

const Spinner = ({ size = 'lg' }: ISpinner) => (
  <StyledSpinner viewBox="0 0 50 50" data-testid="spinner" size={size}>
    <circle cx="25" cy="25" r="20" fill="none" strokeWidth={renderStrokeWidth(size)} />
  </StyledSpinner>
);

export default Spinner;
