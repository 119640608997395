import React, { useContext, useRef, useState } from 'react';
import Spinner from 'components/common/Spinner';
import { format, parseISO } from 'date-fns';
import { useAuth } from 'hooks';
import { ThemeContext } from 'styled-components';
import { Checkbox } from 'components/common';
import { ChartTooltip } from 'components/common/Charts/ChartToolTip';
import { useCalculatorFilter } from 'components/common/HeatMapCorrelation/FilterContext';
import { CSVIMGExport } from 'components/common/DataExport/CSVIMGExport';
import { Col, Row } from 'react-grid-system';
import { DataExportRow } from 'components/common/DataExport/styles';
import { useCorrelationApi } from './useCorrelationApi';
import { ComposedChart, Bar, XAxis, YAxis, Cell, Tooltip, Line, Label, ResponsiveContainer } from 'recharts';
import {
  AveragePeriodFilterValue,
  AveragePeriodTime,
  CalculatorFilterValue,
  TimeWindowFilterValue,
} from 'components/common/HeatMapCorrelation/types';
import {
  InstrumentSwitch,
  VisibilityController,
  InstrumentIndicator,
  PercentChangeWrapper,
} from 'components/common/CorrelationChart/styles';

const capitalizeFirstLetter = (str: string) => {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
};

export interface ICorrelationChart {
  rechartsWidth?: number;
  exportFunctionality?: boolean;
}

export const CorrelationChart: React.FC<ICorrelationChart> = ({ rechartsWidth, exportFunctionality = true }) => {
  const { useCorrelationAndPriceData } = useCorrelationApi();
  const [xVisible, setXVisible] = useState<boolean>(true);
  const [yVisible, setYVisible] = useState<boolean>(true);
  const [isPercentChangeVisible, setIsPercentChangeVisible] = useState<boolean>(true);

  const { authorized } = useAuth();

  const theme = useContext(ThemeContext);

  const { dateFilter, rolling, timeWindow, calculatorName, selectedInstrument } = useCalculatorFilter();

  const { data, isValidating } = useCorrelationAndPriceData({
    endDate: dateFilter.endDate,
    instruments: selectedInstrument,
    timeWindow,
    rolling: AveragePeriodTime[rolling!],
  });

  const chartRef = useRef(null);
  if (isValidating || !data) return <Spinner />;
  if (!data.data.length) return <div>No data</div>;

  const chartStyles = {
    axis: {
      fill: theme.palette.white,
      fontSize: '0.75rem',
      color: theme.palette.white,
    },
  };

  const toolTipLabels = {
    price_change__instrument_y: `${data.instrumentsPair?.instrument_y.short_name} - ${data.formatChartLabels(
      data.instrumentsPair?.instrument_y.instrument_type
    )} Since ${data.sinceDate}:`,
    price_change__instrument_x: `${data.instrumentsPair?.instrument_x.short_name} - ${data.formatChartLabels(
      data.instrumentsPair?.instrument_x.instrument_type
    )} Since ${data.sinceDate}:`,
    correlation__value: 'Correlation:',
  };
  const toggleLeft = (): void => {
    setXVisible(prevState => !prevState);
  };

  const toggleRight = (): void => {
    setYVisible(prevState => !prevState);
  };

  const toggleAllIntruments = (): void => {
    setIsPercentChangeVisible(prevState => !prevState);
  };

  const timeWindowText: { [key: string]: string } = {
    [TimeWindowFilterValue.OneMonth]: '1',
    [TimeWindowFilterValue.ThreeMonths]: '3',
    [TimeWindowFilterValue.SixMonths]: '6',
  };
  const averageTimeText: { [key: string]: string } = {
    [AveragePeriodFilterValue.FiveDay]: '5',
    [AveragePeriodFilterValue.TenDay]: '10',
    [AveragePeriodFilterValue.OneMonth]: '1',
    [AveragePeriodFilterValue.ThreeMonths]: '3',
    [AveragePeriodFilterValue.SixMonths]: '6',
  };

  const renderChartLabel = () => {
    if (!rolling) return undefined;
    if (calculatorName === CalculatorFilterValue.Average) {
      if (rolling === AveragePeriodFilterValue.FiveDay || rolling === AveragePeriodFilterValue.TenDay) {
        return `${averageTimeText[rolling]}-day Average`;
      }
      return `${averageTimeText[rolling]} ${
        rolling === AveragePeriodFilterValue.OneMonth ? 'month' : 'months'
      } Average`;
    }
    return `${rolling}-day Rolling over ${timeWindowText[timeWindow]} ${
      timeWindowText[timeWindow] === '1' ? 'month' : 'months'
    }`;
  };

  const csvHeading = `Calculator,"${capitalizeFirstLetter(calculatorName)} ${rolling}${
    rolling === AveragePeriodFilterValue.FiveDay || rolling === AveragePeriodFilterValue.TenDay ? 'D' : ''
  }${timeWindowText[timeWindow] ? `, ${timeWindowText[timeWindow]}M` : ''}"`;
  const csvData = {
    fields: [
      'Date',
      'Correlation',
      data.instrumentsPair?.instrument_x.full_name,
      data.instrumentsPair?.instrument_y.full_name,
    ],
    data: data.csvData,
  };
  return (
    <>
      <div ref={chartRef} id="correlation-chart" data-testid="correlation-chart">
        <Row>
          <Col>
            {authorized && exportFunctionality && (
              <DataExportRow justify="end">
                <Col md={6} data-testid="correlation-export-row">
                  <CSVIMGExport
                    csvHeading={csvHeading}
                    chartRef={chartRef}
                    data={csvData}
                    fileName={`${data.instrumentsPair?.instrument_x.full_name} - ${data.instrumentsPair?.instrument_y.full_name} Correlation`}
                  />
                </Col>
              </DataExportRow>
            )}
          </Col>
        </Row>

        <PercentChangeWrapper>
          {renderChartLabel()}
          <Checkbox checked={isPercentChangeVisible} onChange={toggleAllIntruments} aria-label="percent-change">
            Percent change
          </Checkbox>
        </PercentChangeWrapper>
        <VisibilityController>
          <InstrumentSwitch active={xVisible} type="button" onClick={toggleLeft}>
            <InstrumentIndicator color={theme.palette.brand} /> {data.instrumentsPair?.instrument_x.full_name}
            {' - '}
            {data.formatChartLabels(data.instrumentsPair?.instrument_x.instrument_type)} Since {data.sinceDate}
          </InstrumentSwitch>
          <InstrumentSwitch active={yVisible} type="button" onClick={toggleRight}>
            <InstrumentIndicator color={theme.palette.white} /> {data.instrumentsPair?.instrument_y.full_name}
            {' - '}
            {data.formatChartLabels(data.instrumentsPair?.instrument_y.instrument_type)} Since {data.sinceDate}
          </InstrumentSwitch>
        </VisibilityController>

        <ResponsiveContainer width={rechartsWidth || '100%'} height={210} id="correlation-responsive-chart">
          <ComposedChart data={data.data}>
            <Bar dataKey="correlation__value" yAxisId="left" isAnimationActive={false}>
              {data.data.map(entry => (
                <Cell
                  key={entry.date}
                  fill={Number(entry.correlation__value) > 0 ? theme.palette.success : theme.palette.error}
                />
              ))}
            </Bar>
            <Line
              hide={!xVisible || !isPercentChangeVisible}
              dataKey="price_change__instrument_x"
              dot={false}
              activeDot={false}
              yAxisId="right"
              stroke={theme.palette.brand}
              type="monotone"
              isAnimationActive={false}
            />
            <Line
              hide={!yVisible || !isPercentChangeVisible}
              dataKey="price_change__instrument_y"
              dot={false}
              activeDot={false}
              yAxisId="right"
              stroke="white"
              isAnimationActive={false}
            />
            <YAxis
              yAxisId="left"
              dataKey="correlation__value"
              tickSize={1}
              ticks={[-1, 0, 1]}
              tickLine={false}
              axisLine={false}
              dx={-10}
              style={chartStyles.axis}
            >
              <Label
                value="Correlation"
                style={{ fill: 'white', textAnchor: 'middle', fontSize: '0.75rem' }}
                angle={-90}
                position="insideLeft"
              />
            </YAxis>
            <YAxis
              yAxisId="right"
              dataKey="price_change__instrument_y"
              domain={[-data.percentMaxValue + 1, data.percentMaxValue + 1]}
              tickCount={10}
              orientation="right"
              tickLine={false}
              axisLine={false}
              dx={10}
              style={chartStyles.axis}
            >
              <Label
                value={data.formatChartLabels(data.instrumentsPair?.instrument_x.instrument_type)}
                offset={10}
                style={{ fill: 'white', textAnchor: 'middle', fontSize: '0.75rem' }}
                angle={90}
                dx={10}
                position="insideRight"
              />
            </YAxis>
            <XAxis
              dataKey="date"
              tickLine={false}
              axisLine={false}
              tickFormatter={value => format(parseISO(value), "MMM ''yy")}
              interval={30}
              dy={15}
              style={chartStyles.axis}
            />
            <Tooltip
              cursor={{ stroke: theme.palette.brand }}
              content={<ChartTooltip toolTipLabels={toolTipLabels} />}
              isAnimationActive={false}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};
