import { SWRConfiguration } from 'swr/dist/types';
import useSWR from 'swr';
import { IRollingHeatMapResponse } from 'api/types';
import { AveragePeriodTime, IFilteredHeatMapParams, IHeatMapParams } from '../types';
import { useDataVariantParam } from 'hooks';
import heatMapApi from './HeatMapApi';

export const useHeatMapApi = () => {
  const { dataVariant } = useDataVariantParam();
  const useHeatMapData = (keys: IHeatMapParams, config?: SWRConfiguration) => {
    const { key, rolling, ...params } = keys;

    return useSWR<IRollingHeatMapResponse>(
      [key || 'get_heatmap', rolling, ...Object.values(params), dataVariant.value],
      () =>
        heatMapApi.getHeatMap({
          ...params,
          rolling: rolling && AveragePeriodTime[rolling],
        }),
      config
    );
  };

  const useFilteredHeatMapData = (keys: IFilteredHeatMapParams, config?: SWRConfiguration) => {
    const { key, rolling, ...params } = keys;

    return useSWR<IRollingHeatMapResponse>(
      [key || 'get_filtered_heatmap', rolling, ...Object.values(params), dataVariant.value],
      () =>
        heatMapApi.getFilteredHeatMap({
          ...params,
          rolling: rolling && AveragePeriodTime[rolling],
        }),
      config
    );
  };
  return { useHeatMapData, useFilteredHeatMapData };
};
