import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useSWRConfig } from 'swr';

export const useAuth = () => {
  const { cache } = useSWRConfig();
  const [cookies, setCookie, removeCookie] = useCookies(['authorizationToken']);
  const cookieToken = cookies.authorizationToken;
  const [authorized, setAuthorized] = useState<boolean>(!!cookieToken);
  const [token, setToken] = useState<string | undefined>(undefined);

  const authorize = async (apiToken: () => Promise<{ token: string }>): Promise<void> => {
    const { token: JWTToken } = await apiToken();
    if (JWTToken) {
      setCookie('authorizationToken', JWTToken);
    }
  };

  const logout = () => {
    removeCookie('authorizationToken');
    cache.clear();
    sessionStorage.clear();
  };

  useEffect(() => {
    if (cookieToken) {
      setAuthorized(true);
      setToken(cookieToken);
    } else {
      setAuthorized(false);
    }
  }, [cookieToken]);

  return { authorized, token, authorize, logout };
};

export default useAuth;
