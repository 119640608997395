import theme from 'styles/theme';
import { ComposedChart, Label, Line, Tooltip, XAxis, YAxis } from 'recharts';
import { format, parseISO } from 'date-fns';
import React, { memo, useRef, useState } from 'react';
import { ChartTooltip } from 'components/common/Charts/ChartToolTip';
import { chartStyles } from 'components/RegimeChange/styles';
import Spinner from 'components/common/Spinner';
import { ChartLegend } from 'components/Charts/ChartLegend';
import { IInstrument } from 'components/common/Charts/types';
import { Chart } from 'components/RegimeChange/Chart';
import { CSVIMGExport } from 'components/common/DataExport/CSVIMGExport';
import { DataExportRow } from 'components/common/DataExport/styles';
import { Col } from 'react-grid-system';
import { DEFAULT_OPTIONS } from '../ChartOptions';
import { IGraph } from '../types';
import { useRegimeChangeApi } from '../useRegimeChangeApi';
import { ChartTitle } from '../ChartTitle';

interface IMarkovStateChart extends IGraph {
  label: string;
}

export const MarkovStateChart: React.FC<IMarkovStateChart> = memo(
  ({ instrument, options = DEFAULT_OPTIONS, label, instrumentVariants }) => {
    const { useMarkovStateData } = useRegimeChangeApi();
    const [percentChangeVisible, setPercentChangeVisible] = useState<boolean>(true);
    const [markov1Visible, setMarkov1Visible] = useState<boolean>(true);
    const [markov2Visible, setMarkov2Visible] = useState<boolean>(true);

    const toggleMarkov1 = (): void => {
      setMarkov1Visible(prevState => !prevState);
    };

    const toggleMarkov2 = (): void => {
      setMarkov2Visible(prevState => !prevState);
    };

    const togglePercentChange = (): void => {
      setPercentChangeVisible(prevState => !prevState);
    };

    const { data, isValidating, error } = useMarkovStateData({ instrument: instrument?.value });

    const chartRef = useRef(null);

    if (error) return <div>Markov - Error has occurred</div>;
    if (isValidating || !data) return <Spinner />;
    if (!data.data.length) return null;

    const toolTipLabels = {
      bull: 'Markov State 1:',
      bear: 'Markov State 2:',
      priceChanges: `${label} ${format(data.minDate, 'MM/dd/yyyy')}:`,
    };

    const instrumentsChartConfig: IInstrument[] = [
      {
        name: 'bull',
        label: 'Markov State 1',
        color: '#66b3ff',
        isVisible: markov1Visible,
        onClick: toggleMarkov1,
      },
      {
        name: 'bear',
        label: 'Markov State 2',
        color: '#cc0000',
        isVisible: markov2Visible,
        onClick: toggleMarkov2,
        yAxisId: 'left',
      },
      {
        name: 'priceChanges',
        label: `${label} ${format(data.minDate, 'MM/dd/yyyy')}`,
        color: theme.palette.brand,
        isVisible: percentChangeVisible,
        onClick: togglePercentChange,
        yAxisId: 'right',
      },
    ];

    const chartTitle = `Markov State 1/State 2 Signals Vs. Price Movement`;
    const csvHeading = `Instrument, ${instrument?.label}`;
    const csvData = {
      fields: ['Date', 'Markov State 1', 'Markov State 2', 'Price Change'],
      data: data.csvData,
    };
    return (
      <>
        {options?.header && (
          <DataExportRow justify="end">
            <Col xs={2}>
              <CSVIMGExport fileName={chartTitle} chartRef={chartRef} data={csvData} csvHeading={csvHeading} />
            </Col>
          </DataExportRow>
        )}
        <Chart
          overflow={options.overflow}
          margin={options?.margin}
          ref={chartRef}
          chartName="markov-chart"
          title={
            options?.header && (
              <ChartTitle
                title={chartTitle}
                instrumentName={instrument?.label}
                instrumentVariants={instrumentVariants}
              />
            )
          }
          legend={<ChartLegend instruments={instrumentsChartConfig} />}
          chart={
            <ComposedChart
              data={data.chartData}
              width={options?.width}
              height={options?.height}
              syncId={options?.syncId}
            >
              <Line
                hide={!markov1Visible}
                dataKey="bull"
                dot={false}
                activeDot={false}
                yAxisId="left"
                stroke="#66b3ff"
                isAnimationActive={false}
              />
              <Line
                hide={!markov2Visible}
                dataKey="bear"
                dot={false}
                activeDot={false}
                yAxisId="left"
                stroke="#cc0000"
                isAnimationActive={false}
              />
              <Line
                hide={!percentChangeVisible}
                dataKey="priceChanges"
                dot={false}
                activeDot={false}
                yAxisId="right"
                stroke={theme.palette.brand}
                type="monotone"
                isAnimationActive={false}
              />
              <YAxis
                yAxisId="left"
                dataKey="markovValue"
                domain={[0, 1]}
                tickCount={11}
                tickLine={false}
                axisLine={false}
                dx={-10}
                style={chartStyles.axis}
              >
                {options?.sideLabels && (
                  <Label
                    value="Markov Value"
                    offset={10}
                    style={{ fill: 'white', textAnchor: 'middle', fontSize: '0.8rem' }}
                    angle={-90}
                    dx={-10}
                    position="insideLeft"
                  />
                )}
              </YAxis>
              <YAxis
                yAxisId="right"
                dataKey="priceChanges"
                domain={[Math.floor(data.minPriceChange), Math.ceil(data.maxPriceChange / 5) * 5]}
                orientation="right"
                tickLine={false}
                axisLine={false}
                tickCount={data.maxPriceChange / 4}
                dx={10}
                style={chartStyles.axis}
              >
                {options?.sideLabels && (
                  <Label
                    value={`${label} ${format(data.minDate, 'MM/dd/yyyy')}`}
                    offset={10}
                    style={{ fill: 'white', textAnchor: 'middle', fontSize: '0.8rem' }}
                    angle={90}
                    dx={10}
                    position="insideRight"
                  />
                )}
              </YAxis>

              <XAxis
                hide={options?.xAxisHide}
                dataKey="date"
                tickLine={false}
                axisLine={false}
                tickFormatter={value => {
                  return value && format(parseISO(value), "MMM ''yy");
                }}
                dy={15}
                dx={15}
                interval={30}
                style={chartStyles.axis}
              />

              <Tooltip
                cursor={{ stroke: theme.palette.brand }}
                content={<ChartTooltip toolTipLabels={toolTipLabels} />}
                isAnimationActive={false}
              />
            </ComposedChart>
          }
        />
      </>
    );
  }
);
