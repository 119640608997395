import React, { createContext, FC, ReactElement, useContext } from 'react';
import { DataVariantContextData, DataVariantOption, dataVariantOptions } from './types';
import { useLocalStorage } from 'hooks';

export const DataVariantParamContext = createContext<DataVariantContextData>({
  dataVariant: new DataVariantOption(dataVariantOptions[0]),
  setDataVariant: () => null,
});

export const DataVariantParamProvider: FC = ({ children }): ReactElement => {
  const [dataVariant, setDataVariant] = useLocalStorage<DataVariantOption>(
    'dataVariantType',
    dataVariantOptions[0],
    DataVariantOption
  );

  return (
    <DataVariantParamContext.Provider value={{ dataVariant, setDataVariant }}>
      {children}
    </DataVariantParamContext.Provider>
  );
};

export const useDataVariantParam = (): DataVariantContextData => useContext(DataVariantParamContext);
