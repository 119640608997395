import styled from 'styled-components';

export const Wrapper = styled.div<{ disabled?: boolean }>`
  ${({ theme, disabled }) => `
    color: ${disabled ? theme.palette.gray[500] : 'unset'};
    margin-bottom: ${theme.spacing(1)};
  `}
  button {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
`;

export const Row = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const ModalRow = styled.div`
  display: flex;
  justify-content: center;
`;

export const PickedCategories = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: start;
  text-transform: capitalize;
  p {
    margin-top: ${({ theme }) => theme.spacing(0.5)};
    margin-right: ${({ theme }) => theme.spacing(2)};
    margin-bottom: 0;
  }
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  margin-right: 6px;
  color: ${({ theme }) => theme.palette.brand};
  font-weight: 700;
  border: 1px solid transparent;
`;

export const Options = styled.div`
  li {
    label {
      color: ${({ theme }) => theme.palette.white};
      font-weight: 500 !important;
      border: 1px solid transparent;
    }
  }
`;
