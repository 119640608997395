import styled from 'styled-components';

export const Label = styled.label`
  display: flex;
  align-items: center;
  margin-right: 6px;
  color: ${({ theme }) => theme.palette.brand};
  font-weight: 700;
  border: 1px solid transparent;
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 14px;
  height: 14px;
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const StyledCheckbox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.white};
  cursor: pointer;

  &:before {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
    border-style: solid;
    border-width: 1px;
  }
`;

export const HiddenCheckbox = styled.input<{ type: string }>`
  position: absolute;
  width: 100%;
  height: 100%;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  border: 0;
  cursor: pointer;
  clip: rect(0px, 0px, 0px, 0px);

  &:checked {
    + div:before {
      content: 'X';
      ${({ theme }) => `
        color: ${theme.palette.black};
        border-color: ${theme.palette.brand};
      `}
    }
  }
`;
