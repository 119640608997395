import styled from 'styled-components';
import BaseButton from 'components/common/BaseButton';
import Input from 'components/common/Input';

export const SendButton = styled(BaseButton)`
  width: 200px;
  margin: 0;
  padding: 15px;
  color: ${({ theme }) => theme.palette.gray['500']};
  background-color: ${({ theme }) => theme.palette.brand};

  :hover {
    color: ${({ theme }) => theme.palette.black};
  }
`;

export const EmailInput = styled(Input)`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  color: black;
  font-size: 1rem;
  background-color: white;

  &::placeholder {
    color: ${({ theme }) => theme.palette.black};
    opacity: 1;
  }
`;
export const ContactWrapper = styled.div`
  margin-left: -15px;
  @media (min-width: 1440px) {
    margin-left: 0;
  }
`;
export const MessageInput = styled.textarea`
  width: 100%;
  height: 150px;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(2)};
  color: black;
  font-size: 1rem;
  background-color: white;
  border: 0;
  outline: none;
  resize: none;

  &::placeholder {
    color: ${({ theme }) => theme.palette.black};
    opacity: 1;
  }
`;

export const FormError = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
  color: ${({ theme }) => theme.palette.error};
`;

export const FormSuccess = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
  color: ${({ theme }) => theme.palette.success};
`;
