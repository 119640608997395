import React, { FC } from 'react';
import { useMatch } from 'react-router-dom';

import { StyledLink } from 'styles/StyledLink';
import { LinkProps } from './types';

const Link: FC<LinkProps> = ({ children, to = '', target, external, disabled }) => {
  const match = useMatch({
    path: to,
  });

  if (external) {
    return (
      <StyledLink as="a" href={to} target={target} disabled={disabled}>
        {children}
      </StyledLink>
    );
  }

  return (
    <StyledLink to={to} target={target} active={match} disabled={disabled}>
      {children}
    </StyledLink>
  );
};

export default Link;
