import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from 'styled-components';
import DataFetchingConfig from 'components/DataFetchingConfig';
import TopBar from 'components/common/TopBar';
import Routes from 'routes';
import { ModalProvider } from 'hooks/useModal';
import GlobalStyle from 'styles/GlobalStyle';
import theme from 'styles/theme';
import { MainContainer } from './styles';
import { Col, Row } from 'react-grid-system';
import { DataVariantParamProvider } from 'hooks';
import { Slide, ToastContainer } from 'react-toastify';
import ErrorBoundary from '../ErrorBoundary';

function App() {
  return (
    <ErrorBoundary>
      <DataVariantParamProvider>
        <DataFetchingConfig>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <ModalProvider>
              <ToastContainer
                position="top-center"
                autoClose={3500}
                hideProgressBar={true}
                transition={Slide}
                theme="dark"
              />
              <MainContainer>
                <Row>
                  <Col>
                    <TopBar />
                  </Col>
                </Row>
              </MainContainer>
              <Routes />
            </ModalProvider>
          </ThemeProvider>
        </DataFetchingConfig>
      </DataVariantParamProvider>
    </ErrorBoundary>
  );
}

export default App;
