import Api from 'utils/Api';
import { ITrendingResponse } from 'api/types';

class TrendingApi extends Api {
  async getTrending(lower: string, higher: string, watchlistId: number | null): Promise<ITrendingResponse> {
    try {
      const response = await this.getWithDataVariantParam<ITrendingResponse>('/v1/trending', {
        params: {
          lower_rolling: lower,
          higher_rolling: higher,
          watchlist_id: watchlistId,
        },
      });
      return response.data;
    } catch (error) {
      throw error.response.status;
    }
  }
}

export const trendingApi = new TrendingApi();
