import styled from 'styled-components';
import { Row } from 'react-grid-system';
import BaseButton from 'components/common/BaseButton';

export const DataExportRow = styled(Row)`
  padding: 5px 15px;
  button + button {
    margin-left: 10px;
  }
`;

export const DownArrow = styled.img`
  width: 15px;
  margin: auto;
`;

export const DownloadButton = styled(BaseButton)`
  color: white;
  font-size: 0.875rem;
`;
