import styled from 'styled-components';
import { RadioOptionsSize } from './type';

const renderFontSize = (size: RadioOptionsSize) => {
  switch (size) {
    case 'sm':
      return '1.2rem';
    case 'md':
      return '1.375rem';
    case 'lg':
      return '1.5rem';
    default:
      return '1.375rem';
  }
};
export const RadioLabel = styled.label<{ disabled?: boolean; size: RadioOptionsSize }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 12px;
  color: ${({ theme, disabled }) => (disabled ? theme.palette.gray['500'] : theme.palette.white)};
  font-size: ${({ size }) => renderFontSize(size)};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  user-select: none;

  input {
    position: fixed;
    cursor: pointer;
    opacity: 0;
  }

  input ~ span {
    margin-top: 10px;
  }

  :hover input ~ span {
    background-color: ${({ theme, disabled }) => (disabled ? theme.palette.gray['500'] : theme.palette.gray[300])};
  }

  input:checked ~ span {
    background-color: ${({ theme, disabled }) => (disabled ? theme.palette.gray['500'] : theme.palette.brand)};
  }
`;

const renderCheckmarkSize = (size: RadioOptionsSize) => {
  switch (size) {
    case 'sm':
      return '18px';
    case 'md':
      return '25px';
    case 'lg':
      return '30px';
    default:
      return '25px';
  }
};
export const Checkmark = styled.span<{ color: string; disabled?: boolean; size?: RadioOptionsSize }>`
  width: ${({ size }) => renderCheckmarkSize(size || 'md')};
  height: ${({ size }) => renderCheckmarkSize(size || 'md')};
  background-color: ${({ color, theme, disabled }) => (disabled ? theme.palette.gray['500'] : color)};
  border-radius: 50%;
`;
