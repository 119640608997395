import Api from 'utils/Api';

class ChangePasswordApi extends Api {
  async changePassword({ oldPassword, newPassword }: { oldPassword: string; newPassword: string }) {
    try {
      const response = await this.api.post('/v1/password/change', {
        old_password: oldPassword,
        new_password: newPassword,
      });
      return response.data;
    } catch (error) {
      throw error.response.status;
    }
  }

  resetPassword({ password, code }: { password: string; code: string }) {
    return this.api.post('/v1/password/reset', { password, code }).then(res => res.data);
  }
}

export const changePasswordApi = new ChangePasswordApi();
