import React, { memo, useMemo, useRef } from 'react';
import { MovingAveragesRSIData } from 'components/RegimeChange/MovingAveragesRSI/helpers';
import { Bar, ComposedChart, Label, Line, Tooltip, XAxis, YAxis } from 'recharts';
import { chartStyles, FilterName, FilterRow } from 'components/RegimeChange/styles';
import { format, parseISO } from 'date-fns';
import Spinner from 'components/common/Spinner';
import { useBoolean } from 'hooks/useBoolean';
import theme from 'styles/theme';
import { ChartTooltip } from 'components/common/Charts/ChartToolTip';
import { IInstrument } from 'components/common/Charts/types';
import { ChartLegend } from 'components/Charts/ChartLegend';
import { Chart } from 'components/RegimeChange/Chart';
import { CSVIMGExport } from 'components/common/DataExport/CSVIMGExport';
import { Col, Row } from 'react-grid-system';
import { selectStyles } from 'components/RegimeChange/MACD/styles';
import Select from 'react-select';
import { movingAvgSelectStyles, Separator } from 'components/RegimeChange/MovingAveragesRSI/styles';
import { useImmer } from 'use-immer';
import { IChartOptions, IGraph, IMovingAverageOptions, InstrumentOptionType, IRSIOptions } from '../types';
import { DEFAULT_OPTIONS } from '../ChartOptions';
import { useLocalStorage } from 'hooks';
import { useRegimeChangeApi } from '../useRegimeChangeApi';
import { ChartTitle } from '../ChartTitle';

const rsiValues = Array.from({ length: 49 }, (_, index) => index + 2);

const rsiOptions: IRSIOptions[] = [
  { label: 'None', value: 0 },
  ...rsiValues.map(value => ({ label: value.toString(), value })),
];

const movingAveragesOptions: IMovingAverageOptions[] = [
  { label: '5-day MA', value: 5 },
  { label: '10-day MA', value: 10 },
  { label: '20-day MA', value: 20 },
  { label: '50-day MA', value: 50 },
  { label: '100-day MA', value: 100 },
  { label: '200-day MA', value: 200 },
];

const RSI_KEY = 'RSIFilterState';

const MovingAveragesRSIChart: React.FC<{
  data: MovingAveragesRSIData;
  chartTitle: string;
  instrument: InstrumentOptionType;
  rsi?: number;
  options?: IChartOptions;
}> = ({ data, chartTitle, rsi, instrument, options = DEFAULT_OPTIONS }) => {
  const [isRSIVisible, toggleRSI] = useBoolean(true);
  const [isPriceVisible, togglePrice] = useBoolean(true);

  const [visibleLines, setVisibleLines] = useImmer<Set<string>>(new Set());

  const toggleLineVisibility = (line: string) => {
    if (visibleLines.has(line)) {
      setVisibleLines(lines => {
        lines.delete(line);
      });
    } else {
      setVisibleLines(lines => {
        lines.add(line);
      });
    }
  };

  const chartRef = useRef(null);

  const colors = ['#ff8806', '#90ee90', '#6d17e6', '#ff0', '#ccc', '#ec008c'];

  let instrumentsChartConfig = [
    {
      key: 'rsi',
      name: 'rsi',
      label: options?.withFilterValues ? `RSI - ${rsi}` : 'RSI',
      color: 'green',
      isVisible: isRSIVisible,
      onClick: toggleRSI,
      yAxisId: 'right',
    },
    {
      key: 'prices',
      name: 'prices',
      label: 'Price',
      color: 'yellow',
      isVisible: isPriceVisible,
      onClick: togglePrice,
      yAxisId: 'left',
    },
  ];

  const ma = data.chartData.days.map((days, index) => ({
    key: `moving_averages${index}`,
    name: `moving_averages${days}`,
    label: `${days}-day MA`,
    color: colors[index],
    isVisible: !visibleLines.has(days),
    onClick: () => toggleLineVisibility(days),
    yAxisId: 'left',
  }));

  instrumentsChartConfig = instrumentsChartConfig.concat(ma);

  const toolTipLabels = useMemo(() => {
    const initialValue = {};
    return instrumentsChartConfig.reduce((obj, item) => {
      return {
        ...obj,
        [item.name]: `${item.label}:`,
      };
    }, initialValue);
  }, [instrumentsChartConfig]);

  const csvHeading = `Instrument, ${instrument?.label}`;
  const csvData = {
    fields: ['Date', ...data.data.days.map(day => `${day}-day MA`), rsi ? `${rsi}-day RSI` : 'RSI', 'Price'],
    data: data.csvData,
  };
  return (
    <>
      {options?.header && (
        <Row justify="end">
          <Col xs={2}>
            <CSVIMGExport data={csvData} fileName={chartTitle} chartRef={chartRef} csvHeading={csvHeading} />
          </Col>
        </Row>
      )}
      <Chart
        overflow={options.overflow}
        margin={options?.margin}
        ref={chartRef}
        chartName="moving-average-rsi-chart"
        legend={<ChartLegend instruments={instrumentsChartConfig as IInstrument[]} />}
        chart={
          <ComposedChart
            data={data.chartData.data}
            width={options?.width}
            height={options?.height}
            syncId={options?.syncId}
          >
            {instrumentsChartConfig.map(i => {
              if (i.key === 'rsi' && isRSIVisible) {
                return <Bar key={i.key} dataKey="rsi" fill="green" yAxisId="right" isAnimationActive={false} />;
              }
              return (
                <Line
                  key={i.key}
                  name={i.key}
                  dataKey={i.name}
                  hide={!i.isVisible}
                  dot={false}
                  activeDot={false}
                  yAxisId="left"
                  stroke={i.color}
                  isAnimationActive={false}
                />
              );
            })}
            <YAxis
              yAxisId="left"
              dataKey="moving_averages"
              domain={[
                Math.floor(Math.min(data.minMovingAverage, data.minPrice) * 100) / 100,
                Math.ceil(Math.max(data.maxMovingAverage, data.maxPrice) * 100) / 100,
              ]}
              orientation="left"
              tickCount={11}
              tickFormatter={val => (!options?.sideLabels ? val : '')}
              tickLine={false}
              width={!options?.sideLabels ? undefined : 35}
              axisLine={false}
              style={chartStyles.axis}
            >
              {options?.sideLabels && (
                <Label
                  value="Price Levels"
                  offset={10}
                  style={{ fill: 'white', textAnchor: 'middle', fontSize: '0.75rem' }}
                  angle={-90}
                  position="insideLeft"
                />
              )}
            </YAxis>
            <YAxis
              yAxisId="right"
              dataKey="rsi"
              domain={[Math.floor(data.minRSI), Math.ceil(data.maxRSI)]}
              orientation="right"
              tickLine={false}
              axisLine={false}
              tickCount={11}
              tickFormatter={val => (!options?.sideLabels ? val : '')}
              dx={10}
              width={!options?.sideLabels ? undefined : 13}
              style={chartStyles.axis}
            />

            <XAxis
              hide={options?.xAxisHide}
              dataKey="date"
              tickLine={false}
              axisLine={false}
              tickFormatter={value => {
                return value && format(parseISO(value), "MMM ''yy");
              }}
              dy={15}
              dx={30}
              interval={30}
              style={chartStyles.axis}
            />

            <Tooltip
              cursor={{ stroke: theme.palette.brand }}
              content={<ChartTooltip toolTipLabels={toolTipLabels} />}
              isAnimationActive={false}
            />
          </ComposedChart>
        }
      />
    </>
  );
};
const CHART_TITLE = 'Moving Averages and RSI (Relative Strength Index)';

export const MovingAveragesRSI: React.FC<IGraph> = memo(
  ({ instrument, options = DEFAULT_OPTIONS, instrumentVariants }) => {
    const { useMovingAveragesRSIData } = useRegimeChangeApi();
    const [rsi, setRSI] = useLocalStorage<IRSIOptions | null>(RSI_KEY, rsiOptions[13]);
    const [movingAverages, setMovingAverages] = useLocalStorage<readonly IMovingAverageOptions[] | null>(
      `${RSI_KEY}MovingAverages`,
      [movingAveragesOptions[3], movingAveragesOptions[4], movingAveragesOptions[5]]
    );
    const { data, isValidating } = useMovingAveragesRSIData({
      instrument: instrument?.value,
      rsi: rsi?.value,
      movingAverages,
    });

    return (
      <>
        {options?.header && (
          <>
            <Row>
              <ChartTitle
                title={CHART_TITLE}
                instrumentName={instrument?.label}
                instrumentVariants={instrumentVariants}
              />
            </Row>
            <FilterRow align="center" justify="between">
              <Col>
                <Row align="center">
                  <FilterName>Moving Averages:</FilterName>
                  <Select
                    defaultValue={[movingAveragesOptions[0], movingAveragesOptions[1]]}
                    value={movingAverages}
                    isMulti
                    name="movingAvgs"
                    options={movingAveragesOptions}
                    styles={movingAvgSelectStyles}
                    onChange={values => setMovingAverages(values)}
                  />
                  <Separator>|</Separator>
                  <FilterName>RSI:</FilterName>
                  <Select value={rsi} name="rsi" options={rsiOptions} styles={selectStyles} onChange={setRSI} />
                </Row>
              </Col>
            </FilterRow>
          </>
        )}
        {data && data.chartData.movingAverages.length ? (
          <MovingAveragesRSIChart
            data={data}
            chartTitle={CHART_TITLE}
            rsi={rsi?.value}
            instrument={instrument}
            options={options}
          />
        ) : null}
        {(isValidating || !data.chartData.movingAverages.length) && <Spinner />}
      </>
    );
  }
);
