import useSWR from 'swr';
import { useMemo } from 'react';
import { useDataVariantParam } from 'hooks';
import { SWRConfiguration } from 'swr/dist/types';
import { ICorrelationResponse } from 'api/types';
import { ICorrelationAndPriceChangeParams } from './types';
import { correlationApi } from './CorrelationApi';
import { CorrelationData } from './helpers';

export const useCorrelationApi = () => {
  const { dataVariant } = useDataVariantParam();
  const useCorrelationAndPriceData = (keys: ICorrelationAndPriceChangeParams, config?: SWRConfiguration) => {
    const { key, ...params } = keys;

    const { data, ...rest } = useSWR<ICorrelationResponse>(
      [key || 'get_correlation', ...Object.values(params), dataVariant.value],
      () =>
        correlationApi.getCorrelationAndPriceChange({
          ...params,
        }),
      config
    );

    const correlationData = useMemo(() => CorrelationData.fromCorrelationResponse(data), [data]);

    return { data: correlationData, ...rest };
  };
  return { useCorrelationAndPriceData };
};
