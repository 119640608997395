import { useState } from 'react';

export const useBoolean = (initialValue: boolean): [boolean, () => void, (value: boolean) => void] => {
  const [value, setValue] = useState<boolean>(initialValue);

  const toggleValue = (): void => {
    setValue(prevState => !prevState);
  };

  return [value, toggleValue, setValue];
};
