import styled from 'styled-components';

export const Wrapper = styled.div<{ isWide: boolean }>`
  width: ${({ isWide }) => (isWide ? '900px' : '450px')};
`;

export const MatrixLinkWrapper = styled.div<{ disabled: boolean }>`
  margin-right: 10px;
  ${({ disabled }) => {
    if (disabled) {
      return `pointer-events: none;`;
    }
    return null;
  }}
`;
export const HeatMapTilesWrapper = styled.div`
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
`;
export const HeatMapTileWrapper = styled.div`
  width: calc(25% - 30px);
`;
