import Api from 'utils/Api';
import { IWatchlistResponse } from 'api/types';

class WatchlistApi extends Api {
  async listWatchLists(): Promise<IWatchlistResponse[]> {
    try {
      const response = await this.getWithDataVariantParam<IWatchlistResponse[]>('/v1/watchlists');
      return response.data;
    } catch (error) {
      throw error.response.status;
    }
  }

  async getDemoWatchLists(): Promise<IWatchlistResponse[]> {
    try {
      const response = await this.getWithDataVariantParam<IWatchlistResponse[]>('/v1/demo_watchlists');
      return response.data;
    } catch (error) {
      throw error.response.status;
    }
  }

  async createWatchlist(watchlistData: {
    instruments: { left: string; right: string }[];
    name: string;
  }): Promise<IWatchlistResponse> {
    try {
      const response = await this.api.post<IWatchlistResponse>('/v1/watchlists', watchlistData);
      return response.data;
    } catch (error) {
      throw error.response.status;
    }
  }

  async deleteWatchlist(id: number) {
    try {
      await this.api.delete(`/v1/watchlists/${id}`);
    } catch (error) {
      throw error.response.status;
    }
  }

  async updateWatchlist(
    id: number,
    watchlistData: {
      instruments: { left: string; right: string }[];
      name: string;
    }
  ) {
    try {
      const response = await this.api.patch<IWatchlistResponse>(`/v1/watchlists/${id}`, watchlistData);
      return response.data;
    } catch (error) {
      throw error.response.status;
    }
  }
}

export default new WatchlistApi();
