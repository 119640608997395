import styled from 'styled-components';
import themeBase from 'styles/theme';
import { Container, Row } from 'react-grid-system';
import BaseButton from '../common/BaseButton';

export const StyledContainer = styled(Container)`
  width: 100%;
  padding: 50px 0 0;
`;

export const Title = styled.h1`
  padding: 0;
  color: ${({ theme }) => theme.palette.white};
  font-weight: 500;
  font-size: 1.25rem;
  font-family: ${({ theme }) => theme.fonts.body};
  text-align: left;
  text-transform: uppercase;
`;

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-left: ${({ theme }) => theme.spacing(2)};
  }

  margin-right: ${({ theme }) => theme.spacing(2)};
`;

export const chartStyles = {
  axis: {
    fill: themeBase.palette.white,
    fontSize: '0.75rem',
    color: themeBase.palette.white,
    width: '30px',
  },
};

export const RegimePopoverContent = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${({ theme }) => theme.spacing(2)};
  background: black;
  border: 1px solid #808080;
`;

export const RegimePopoverColumn = styled.div`
  flex-direction: column;

  & + & {
    margin-left: ${({ theme }) => theme.spacing(2)};
  }
`;

export const RegimePopoverColumnTitle = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const RegimePopoverInstrumentsList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    display: flex;
    padding: ${({ theme }) => theme.spacing(1)} 0;
    white-space: nowrap;
  }
`;
export const FilterRow = styled(Row)`
  margin-top: ${({ theme }) => theme.spacing(2)};
`;
export const FilterName = styled.p`
  margin-right: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.palette.brand};
  text-decoration: underline;
`;
export const MomentumOpenButton = styled(BaseButton)`
  border: 1px solid #808080;
  color: ${({ theme }) => theme.palette.white};
  font-size: 16px;
  margin: 8px 0 !important;
  padding: 6px 8px;
  transition: 0.1s ease-in-out;
  width: 100%;

  &:hover {
    color: ${({ theme }) => theme.palette.brand};
  }
`;
