import useSWR from 'swr';
import Select, { components } from 'react-select';
import React, { useEffect, useMemo, useState } from 'react';
import escapeRegExp from 'lodash.escaperegexp';
import { IGetInstrumentsQueryParams, IInstrument, instrumentApi } from './InstrumentSelectApi';
import ReactTooltip from 'react-tooltip';

export const InstrumentSelect: React.FC<{
  value?: string | null;
  onChange?: (value: string | null, entireData?: IInstrument | null) => void;
  placeholder?: string;
  params?: IGetInstrumentsQueryParams;
  styles: any;
  hover?: string;
  testId?: string;
  disabled?: boolean;
  queryKey?: string;
  onInstrumentChange?: (instrument?: IInstrument | null) => void;
}> = ({
  value,
  onChange,
  placeholder = '',
  params,
  styles,
  hover,
  testId,
  disabled = false,
  queryKey = 'get_instruments',
  onInstrumentChange,
}) => {
  const [inputValue, setInputValue] = useState('');
  const { data } = useSWR<IInstrument[]>(queryKey, () => instrumentApi.getInstruments(params), {
    fallbackData: [],
    revalidateOnMount: true,
  });
  const options = useMemo(() => data!.map(({ ticker, short_name }) => ({ value: ticker, label: short_name })), [data]);

  const valueOption = options.find(option => option.value === value) || null;

  const filteredOptions = useMemo(() => {
    if (!inputValue) {
      return options;
    }

    const matchByStart = [];
    const matchByInclusion = [];

    const regByInclusion = new RegExp(escapeRegExp(inputValue), 'i');
    const regByStart = new RegExp(`^${escapeRegExp(inputValue)}`, 'i');

    for (const option of options) {
      if (regByInclusion.test(option.label)) {
        if (regByStart.test(option.label)) {
          matchByStart.push(option);
        } else {
          matchByInclusion.push(option);
        }
      }
    }

    return [...matchByStart, ...matchByInclusion];
  }, [options, inputValue]);
  const IndicatorsContainer = (props: any) => {
    return (
      <components.IndicatorsContainer {...props}>
        <components.DropdownIndicator {...props}>
          <span>&#9660;</span>
        </components.DropdownIndicator>
      </components.IndicatorsContainer>
    );
  };
  const findInstrumentByTicker = (ticker?: string | null) => {
    if (!data || !ticker) return null;
    const index = data.findIndex(el => el.ticker === ticker);

    if (index > -1) return data[index];

    return null;
  };
  const CustomOption = (props: any) => {
    const { onMouseMove, onMouseOver, ...newInnerProps } = props.innerProps;

    return <components.Option {...{ ...props, innerProps: { ...newInnerProps } }}>{props.children}</components.Option>;
  };

  useEffect(() => {
    onInstrumentChange && onInstrumentChange(findInstrumentByTicker(valueOption?.value));
  }, [valueOption]);

  const CustomValueContainer = (props: any) => {
    return (
      <>
        <components.SingleValue
          {...{
            ...props,
            innerProps: {
              ...props.innerProps,
              'data-tip': `${props.hover || props.children}`,
              'data-for': `tool-tip`,
            },
          }}
        >
          {props.children}
        </components.SingleValue>
        <ReactTooltip place="top" type="dark" effect="float" id="tool-tip" getContent={dataTip => `${dataTip}`} />
      </>
    );
  };

  return (
    <>
      <Select
        value={valueOption}
        options={filteredOptions}
        components={{
          IndicatorsContainer,
          Option: CustomOption,
          SingleValue: props => (
            <CustomValueContainer {...props} hover={hover || findInstrumentByTicker(value)?.full_name} />
          ),
        }}
        data-testid={testId}
        onInputChange={optionValue => setInputValue(optionValue)}
        onChange={optionValue =>
          !disabled && onChange?.(optionValue!.value, findInstrumentByTicker(optionValue!.value))
        }
        placeholder={placeholder}
        styles={styles}
      />
    </>
  );
};
