import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

const GlobalStyle = createGlobalStyle`
  ${normalize}
  
  * {
    box-sizing: border-box;
  }
  
  html {
    height: 100%;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
  }
  
  body {
    height: 100%;
    ${({ theme }) => `
      color: ${theme.palette.text};
      background-color: ${theme.palette.background};
      font-family: ${theme.fonts.body};
    `}
  }
  
  #root {
    height: 100%;
  }
  
  button {
    outline: none;
    cursor: pointer;
  }
`;

export default GlobalStyle;
