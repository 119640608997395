import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import Input from 'components/common/Input';
import BaseButton from 'components/common/BaseButton';
import { emailPattern, FormErrors, StatusCodes } from 'utils/constants';
import { ActionWrapper, SuccessMessage } from './styles';
import { forgotPasswordApi } from 'components/Authentication/ForgotPassword/ForgotPasswordApi';
import { useIsMounted } from 'hooks';

const ForgotPassword = () => {
  const isMounted = useIsMounted();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { register, handleSubmit, errors, setError } = useForm();

  const resetPassword: SubmitHandler<{ email: string }> = async (formData): Promise<void> => {
    setIsLoading(true);
    setIsSuccess(false);
    try {
      await forgotPasswordApi.resetPassword(formData);
    } catch (error) {
      if (error.response && error.response.status === StatusCodes.UNPROCESSABLE_ENTITY) {
        setError('email', {
          type: 'manual',
          message: 'Wrong email format',
        });
      }
    } finally {
      if (isMounted()) {
        setIsSuccess(true);
        setIsLoading(false);
      }
    }
  };
  return (
    <form onSubmit={handleSubmit(resetPassword)}>
      {isSuccess ? (
        <SuccessMessage>If account exists, an email will be sent with further instructions.</SuccessMessage>
      ) : (
        <>
          <Input
            name="email"
            aria-label="email-input"
            placeholder="Email"
            ref={register({
              required: FormErrors.REQUIRED_FIELD,
              pattern: {
                value: emailPattern,
                message: FormErrors.INCORRECT_EMAIL,
              },
            })}
            label="Please enter your email and we will send you reset password instructions!"
            error={errors.email && errors.email.message}
          />
          <ActionWrapper>
            <div>
              <BaseButton type="submit" disabled={isLoading}>
                Send instructions
              </BaseButton>
            </div>
          </ActionWrapper>
        </>
      )}
    </form>
  );
};

export default ForgotPassword;
