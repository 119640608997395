import { HeatMapCorrelation } from 'components/common/HeatMapCorrelation';
import { Col, Container, Row } from 'react-grid-system';
import React from 'react';
import { MetadataProvider } from '../MetadataContext';

export const HeatMapCorrelationSection: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  return (
    <MetadataProvider>
      <Container style={{ width: '100%', height: '100px', padding: 0 }}>
        <Row>
          <Col xxl={4} sm={12} style={{ marginBottom: '24px' }}>
            {children}
          </Col>
          <Col xxl={8} sm={12}>
            <HeatMapCorrelation disabled />
          </Col>
        </Row>
      </Container>
    </MetadataProvider>
  );
};
