export enum ModalTypes {
  INFO = 'INFO',
  CONFIRM = 'CONFIRM',
}

export enum ActionKind {
  OpenModal = 'OPEN_MODAL',
  OpenInfoModal = 'OPEN_INFO_MODAL',
  OpenConfirmModal = 'OPEN_CONFIRM_MODAL',
  CloseModal = 'CLOSE_MODAL',
}
