import styled from 'styled-components';

export const Info = styled.div`
  flex-grow: 0;
  overflow-y: auto;

  p {
    &:first-child {
      color: ${({ theme }) => theme.palette.brand};
    }
  }

  a {
    color: ${({ theme }) => theme.palette.white};
    text-decoration: none;
  }

  a:hover {
    color: ${({ theme }) => theme.palette.brand};
  }

  @media (min-width: 1440px) {
    height: 600px;
    width: 100%;
    margin-left: 0;
    padding-right: ${({ theme }) => theme.spacing(2)};
  } ;
`;
