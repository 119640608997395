import {
  defaultAverageCalculatorFilter,
  defaultEquallyWeightedMethodOfCalculation,
  defaultExponentiallyWeightedMethodOfCalculation,
  defaultRollingCalculatorFilter,
} from 'components/common/Filters/filtersDefaults';
import { CalculatorProvider } from 'components/common/HeatMapCorrelation/FilterContext';
import { Views } from 'components/common/HeatMapCorrelation/HeatMap/constants';
import useAuth from 'hooks/useAuth';
import React, { useContext, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { Updater } from 'use-immer';
import { useLocalStorage } from 'hooks';
import Correlation from './Correlation';
import { HeatMap } from './HeatMap';
import {
  assertCalculatorIsAverage,
  assertCalculatorIsRolling,
  assertMethodOfCalculationIsExponentiallyWeighted,
  AveragePeriodFilterValue,
  CalculatorFilter,
  CalculatorFilterValue,
  MethodOfCalculationFilterValue,
  PeriodFilterValue,
  TimeWindowFilterValue,
} from './types';
import Watchlist from './HeatMap/Watchlist';
import { Trending } from '../../Trending/Trending';
import { PrivateColumnWrapper, PrivateRiskometerWrapper, PublicColumnWrapper } from './styles';

export const HEAT_MAP_CORRELATION_FILTER_STATE_KEY = 'heatMapCorrelationFilterState';
export const WATCH_LIST_CORRELATION_FILTER_STATE_KEY = 'watchListCorrelationFilterState';
export const changeCalculatorFilterType = (
  type: CalculatorFilterValue,
  updateCalculatorFilter: Updater<CalculatorFilter>
) => {
  updateCalculatorFilter(() => {
    if (type === CalculatorFilterValue.Average) {
      return defaultAverageCalculatorFilter;
    }
    return defaultRollingCalculatorFilter;
  });
};

export const changeTimePeriod = (period: PeriodFilterValue, updateCalculatorFilter: Updater<CalculatorFilter>) => {
  updateCalculatorFilter(draft => {
    assertCalculatorIsRolling(draft);
    draft.period = period;
  });
};

export const changeTimeWindow = (
  timeWindow: TimeWindowFilterValue,
  updateCalculatorFilter: Updater<CalculatorFilter>
) => {
  updateCalculatorFilter(draft => {
    assertCalculatorIsRolling(draft);
    draft.timeWindow = timeWindow;
  });
};

export const changeAveragePeriod = (
  period: AveragePeriodFilterValue | undefined,
  updateCalculatorFilter: Updater<CalculatorFilter>
) => {
  updateCalculatorFilter(draft => {
    assertCalculatorIsAverage(draft);
    draft.averagePeriod = period;
  });
};

export const changeMethodOfCalculation = (
  method: MethodOfCalculationFilterValue,
  updateCalculatorFilter: Updater<CalculatorFilter>
) => {
  updateCalculatorFilter(draft => {
    assertCalculatorIsRolling(draft);
    if (method === MethodOfCalculationFilterValue.EquallyWeighted) {
      draft.methodOfCalculation = defaultEquallyWeightedMethodOfCalculation;
    } else {
      draft.methodOfCalculation = defaultExponentiallyWeightedMethodOfCalculation;
    }
  });
};

export const changeTimeDecay = (decay: string, updateCalculatorFilter: Updater<CalculatorFilter>) => {
  updateCalculatorFilter(draft => {
    assertCalculatorIsRolling(draft);
    assertMethodOfCalculationIsExponentiallyWeighted(draft.methodOfCalculation);
    draft.methodOfCalculation.decayFactor = decay;
  });
};

export const HeatMapViewContext = React.createContext<{
  view: Views;
  setView: (view: Views) => void;
  previousView: Views;
  setPreviousView: (view: Views) => void;
  selectedWatchlistId: number | null;
  setSelectedWatchlistId: (id: number | null) => void;
}>({
  view: Views.HEAT_MAP,
  setView: () => {},
  previousView: Views.HEAT_MAP,
  setPreviousView: () => {},
  selectedWatchlistId: null,
  setSelectedWatchlistId: () => {},
});
const HeatMapViewContextProvider: React.FC = ({ children }) => {
  const [view, setView] = useState<Views>(Views.HEAT_MAP);
  const [previousView, setPreviousView] = useState<Views>(Views.HEAT_MAP);
  const [selectedWatchlistId, setSelectedWatchlistId] = useLocalStorage<number | null>('selectedWatchListId', null);

  return (
    <HeatMapViewContext.Provider
      value={{
        view,
        setView,
        previousView,
        setPreviousView,
        selectedWatchlistId,
        setSelectedWatchlistId,
      }}
    >
      {children}
    </HeatMapViewContext.Provider>
  );
};
export const useHeatMapView = () => useContext(HeatMapViewContext);

export const HeatMapCorrelation: React.FC<{ disabled?: boolean; children?: any; last?: boolean }> = ({
  disabled = false,
  children,
  last = false,
}) => {
  const { authorized } = useAuth();

  return (
    <HeatMapViewContextProvider>
      <HeatMapViewContext.Consumer>
        {({ view }) => (
          <>
            {authorized && (
              <Row>
                <Col>
                  <Row style={{ gap: '12px' }}>
                    {!last && (
                      <PrivateRiskometerWrapper xxl={4} sm={12}>
                        {children}
                      </PrivateRiskometerWrapper>
                    )}
                    {view === Views.WATCHLIST && (
                      <CalculatorProvider cacheKey={WATCH_LIST_CORRELATION_FILTER_STATE_KEY}>
                        <PrivateColumnWrapper xxl={4} lg={6} sm={12}>
                          <Watchlist disabled={disabled} />
                        </PrivateColumnWrapper>
                        <PrivateColumnWrapper xxl={4} lg={6} sm={12}>
                          <Correlation />
                        </PrivateColumnWrapper>
                      </CalculatorProvider>
                    )}
                    {view === Views.HEAT_MAP && (
                      <CalculatorProvider cacheKey={HEAT_MAP_CORRELATION_FILTER_STATE_KEY}>
                        <PrivateColumnWrapper xxl={4} lg={6} sm={12}>
                          <HeatMap disabled={disabled} />
                        </PrivateColumnWrapper>
                        <PrivateColumnWrapper xxl={4} lg={6} sm={12}>
                          <Correlation />
                        </PrivateColumnWrapper>
                      </CalculatorProvider>
                    )}
                    {view === Views.TRENDING && (
                      <Col xxl={8} sm={12} style={{ paddingLeft: 0 }}>
                        <Trending />
                      </Col>
                    )}
                    {last && (
                      <PrivateRiskometerWrapper xxl={4} sm={12}>
                        {children}
                      </PrivateRiskometerWrapper>
                    )}
                  </Row>
                </Col>
              </Row>
            )}
            {!authorized && (
              <Row style={{ marginRight: 0, marginLeft: 0, gap: '12px' }}>
                {view === Views.HEAT_MAP && (
                  <CalculatorProvider cacheKey={`${HEAT_MAP_CORRELATION_FILTER_STATE_KEY}Disabled`}>
                    <PublicColumnWrapper lg={6} xs={12} style={{ marginBottom: '24px' }}>
                      <HeatMap disabled />
                    </PublicColumnWrapper>
                    <PublicColumnWrapper lg={6} xs={12}>
                      <Correlation disabled />
                    </PublicColumnWrapper>
                  </CalculatorProvider>
                )}
                {view === Views.WATCHLIST && (
                  <CalculatorProvider cacheKey={`${HEAT_MAP_CORRELATION_FILTER_STATE_KEY}Disabled`}>
                    <PublicColumnWrapper lg={6} xs={12} style={{ marginBottom: '24px' }}>
                      <Watchlist disabled />
                    </PublicColumnWrapper>
                    <PublicColumnWrapper lg={6} xs={12}>
                      <Correlation disabled />
                    </PublicColumnWrapper>
                  </CalculatorProvider>
                )}
              </Row>
            )}
          </>
        )}
      </HeatMapViewContext.Consumer>
    </HeatMapViewContextProvider>
  );
};
