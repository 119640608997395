import { Row } from 'react-grid-system';
import React, { useEffect, useMemo } from 'react';
import Select from 'react-select';
import {
  Title,
  StyledHeader,
  StyledContainer,
  RegimePopoverContent,
  RegimePopoverColumn,
  RegimePopoverColumnTitle,
  RegimePopoverInstrumentsList,
  MomentumOpenButton,
} from 'components/RegimeChange/styles';
import { MarkovStateChart } from 'components/RegimeChange/MarkovState/MarkovStateChart';
import { ReturnsVsVolatility } from 'components/RegimeChange/ReturnsVsVolatility/ReturnsVSVolatilityChart';
import { MovingAverages } from 'components/RegimeChange/MovingAverages/MovingAveragesChart';
import { Frequency } from 'components/RegimeChange/Frequency/FrequencyChart';
import { Frequency3Months } from 'components/RegimeChange/Frequency/Frequency3MonthsChart';
import { MovingAveragesRSI } from 'components/RegimeChange/MovingAveragesRSI/MovingAveragesRSIChart';
import { MACDChart } from 'components/RegimeChange/MACD/MACDChart';
import Spinner from 'components/common/Spinner';
import { HelpButton, Popover, Checkbox, PopoverContext } from 'components/common';
import { useModal, useBoolean, useLocalStorage } from 'hooks';
import { RegimeChangeHelpModal } from 'components/RegimeChange/RegimeChangeHelpModal';
import { selectStyles } from 'components/RelationshipBuilding/styles';
import { MomentumPopup } from './MomentumPopup';
import { useRegimeChangeApi } from './useRegimeChangeApi';
import { IGraph } from './types';

export const RegimeChange = () => {
  const { useInstrumentsData } = useRegimeChangeApi();

  const { data, isValidating } = useInstrumentsData();

  const options = useMemo(() => data.getSelectOptions(), [data]);

  const [selectedInstrument, setSelectedInstrument] = useLocalStorage<{ value: string; label: string } | null>(
    'regimeChangeSelectedInstrument',
    null
  );

  const { setInfoModal, setModal } = useModal();
  const onHelpModalOpen = () => {
    setInfoModal(<RegimeChangeHelpModal />);
  };
  const onMomentumModalOpen = () => {
    setModal(
      <MomentumPopup
        instrument={selectedInstrument}
        label={data.formatChartLabels(data.getCategoryByTicker(selectedInstrument?.value || ''))}
      />,
      { fullWidth: true }
    );
  };

  const [markovStateChartVisible, toggleMarkovStateChart] = useBoolean(true);
  const [returnsVolatilityChartVisible, toggleReturnsVolatilityChart] = useBoolean(true);
  const [movingAveragesChartVisible, toggleMovingAveragesChart] = useBoolean(true);
  const [frequencyChartVisible, toggleFrequencyChart] = useBoolean(true);
  const [frequency3MonthChartVisible, toggleFrequency3MonthChart] = useBoolean(true);
  const [movingAveragesRSIChartVisible, toggleMovingAveragesRSIChart] = useBoolean(true);
  const [macdChartVisible, toggleMACDChart] = useBoolean(true);

  useEffect(() => {
    if (!selectedInstrument) {
      setSelectedInstrument(options[0]);
    }
  }, [options, selectedInstrument]);

  if (!selectedInstrument && isValidating) return <Spinner />;
  if (!selectedInstrument && data.allInstruments.length === 0) return <div>No data</div>;

  interface IChart {
    name: string;
    Chart: React.FC<IGraph & { label: string }>;
    hidden: boolean;

    toggle(): void;
  }

  const charts: IChart[] = [
    {
      name: 'Markov State 1/State 2 Signals Vs. Price Movement',
      Chart: MarkovStateChart,
      hidden: !markovStateChartVisible,
      toggle: toggleMarkovStateChart,
    },
    {
      name: 'Return vs volatility',
      Chart: ReturnsVsVolatility,
      hidden: !returnsVolatilityChartVisible,
      toggle: toggleReturnsVolatilityChart,
    },
    {
      name: 'Moving Averages',
      Chart: MovingAverages,
      hidden: !movingAveragesChartVisible,
      toggle: toggleMovingAveragesChart,
    },
    {
      name: '1 Month Frequency',
      Chart: Frequency,
      hidden: !frequencyChartVisible,
      toggle: toggleFrequencyChart,
    },
    {
      name: '3 Month Frequency',
      Chart: Frequency3Months,
      hidden: !frequency3MonthChartVisible,
      toggle: toggleFrequency3MonthChart,
    },
    {
      name: 'Moving Averages and RSI',
      Chart: MovingAveragesRSI,
      hidden: !movingAveragesRSIChartVisible,
      toggle: toggleMovingAveragesRSIChart,
    },
    {
      name: 'MACD',
      Chart: MACDChart,
      hidden: !macdChartVisible,
      toggle: toggleMACDChart,
    },
  ];
  return (
    <StyledContainer data-testid="regime-change-wrapper">
      <Row align="center" justify="start">
        <StyledHeader>
          <Title>Regime Change</Title>
          <HelpButton onClick={onHelpModalOpen} />
          <Popover label="Control Panel">
            <RegimePopoverContent>
              <RegimePopoverColumn>
                <RegimePopoverColumnTitle>Instrument:</RegimePopoverColumnTitle>
                <Select
                  inputId="regime-change-select"
                  defaultValue={selectedInstrument || options[0]}
                  name="instruments"
                  options={options}
                  width="300px"
                  styles={selectStyles}
                  onChange={setSelectedInstrument}
                />
                <PopoverContext.Consumer>
                  {value => (
                    <MomentumOpenButton
                      data-testid="momentum-open-btn"
                      onClick={() => {
                        value.closePopover();
                        onMomentumModalOpen();
                      }}
                      type="button"
                    >
                      Momentum Change
                    </MomentumOpenButton>
                  )}
                </PopoverContext.Consumer>
              </RegimePopoverColumn>
              <RegimePopoverColumn>
                <RegimePopoverColumnTitle>Show below:</RegimePopoverColumnTitle>
                <RegimePopoverInstrumentsList>
                  {charts.map(chart => (
                    <li key={chart.name}>
                      <Checkbox checked={!chart.hidden} data-testid={`${chart.name}-checkbox`} onChange={chart.toggle}>
                        {chart.name}
                      </Checkbox>
                    </li>
                  ))}
                </RegimePopoverInstrumentsList>
              </RegimePopoverColumn>
            </RegimePopoverContent>
          </Popover>
        </StyledHeader>
      </Row>
      {selectedInstrument && (
        <>
          <>
            {charts.map(chart => (
              <div key={chart.name} hidden={chart.hidden}>
                <chart.Chart
                  instrumentVariants={data.getVariantsByTicker(selectedInstrument.value)}
                  instrument={selectedInstrument}
                  label={data.formatChartLabels(data.getCategoryByTicker(selectedInstrument.value))}
                />
              </div>
            ))}
          </>
        </>
      )}
    </StyledContainer>
  );
};
