import React from 'react';
import theme from 'styles/theme';
import { Checkmark, RadioLabel } from './RadioOptions.styles';
import { IRadioOptions } from './type';

export const RadioOptions: React.FC<IRadioOptions> = ({ options, name, onChange, disabled, size = 'md', value }) => {
  return (
    <>
      {options.map(option => {
        return (
          <RadioLabel key={option.value} disabled={disabled} size={size} aria-label={option.label}>
            {option.label}
            <input
              type="radio"
              disabled={disabled}
              name={name}
              value={option.value}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onChange(event.target.value);
              }}
              checked={value && option.value === value.value}
            />
            <Checkmark color={theme.palette.white} disabled={disabled} />
          </RadioLabel>
        );
      })}
    </>
  );
};
