import React, { ReactElement, FC, useReducer, useContext, createContext } from 'react';

import Modal from 'components/common/Modal';
import { ModalTypes, ActionKind } from 'hooks/useModal/constants';

import { ModalContextData, ModalState, ModalAction } from './types';

const initialState = { isOpen: false, component: null, type: undefined };

const reducer = (state: ModalState, action: ModalAction): ModalState => {
  switch (action.type) {
    case ActionKind.OpenInfoModal:
      return { isOpen: true, component: action.component, ...action.options, type: ModalTypes.INFO };
    case ActionKind.OpenConfirmModal:
      return { isOpen: true, component: action.component, ...action.options, type: ModalTypes.CONFIRM };
    case ActionKind.OpenModal:
      return { isOpen: true, component: action.component, ...action.options };
    case ActionKind.CloseModal:
      return { isOpen: false, component: null, type: undefined };
    default:
      throw new Error();
  }
};

export const ModalContext = createContext<ModalContextData>({
  setModal: () => {},
  setInfoModal: () => {},
  setConfirmModal: () => {},
  onCloseModal: () => {},
});

export const ModalProvider: FC = ({ children }): ReactElement => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const contextValue: ModalContextData = {
    setModal: (component, options) => dispatch({ type: ActionKind.OpenModal, component, options }),
    setInfoModal: (component, options) => dispatch({ type: ActionKind.OpenInfoModal, component, options }),
    setConfirmModal: (component, options) => dispatch({ type: ActionKind.OpenConfirmModal, component, options }),
    onCloseModal: () => dispatch({ type: ActionKind.CloseModal }),
  };

  return (
    <ModalContext.Provider value={contextValue}>
      <Modal
        isOpen={state.isOpen}
        title={state.title}
        yesText={state.yesText}
        cancelText={state.cancelText}
        onClose={contextValue.onCloseModal}
        onYes={state.onYes}
        type={state.type}
        fullWidth={state.fullWidth}
      >
        {state.component}
      </Modal>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = (): ModalContextData => useContext(ModalContext);
