import React from 'react';
import logo from 'assets/full_logo.png';
import hamburgerMenu from 'assets/icons/hamburgerMenu.svg';
import { Login } from 'components/Authentication';
import { ChangePassword } from 'components/Authentication/ChangePassword/ChangePassword';
import Link from 'components/common/Link';
import { Popover, LinkButton } from 'components/common';
import { useModal, useAuth } from 'hooks';
import { Col } from 'react-grid-system';
import { useNavigate } from 'react-router-dom';
import { burgerPrivateLinks, burgerPublicLinks, privateLinks, publicLinks } from './constants';
import {
  BurgerMenuContent,
  BurgerWrapper,
  HamburgerIcon,
  Logo,
  Menu,
  MenuContentLink,
  Wrapper,
  SideMenuWrapper,
  LinkWrapper,
} from './styles';

const TopBar = () => {
  const { setModal, onCloseModal } = useModal();
  const { authorized, logout } = useAuth();

  const openLoginForm = () => {
    setModal(<Login />, { title: 'Sign in' });
  };

  const openChangePasswordForm = () => {
    setModal(<ChangePassword closeModal={onCloseModal} />, { title: 'Password Change' });
  };

  const navigate = useNavigate();

  const onLogout = () => {
    logout();
    navigate('/about');
  };

  return (
    <Wrapper align="center" justify="around" style={{ margin: 0 }}>
      <Col style={{ paddingLeft: 0, maxWidth: '330px' }}>
        <Link to={authorized ? '/dashboard' : '/about'}>
          <Logo src={logo} alt="Excalibur Pro" />
        </Link>
        Discovering the magic of data relationships.
      </Col>
      <Menu>
        <SideMenuWrapper>
          {(authorized ? privateLinks : publicLinks).map(link => (
            <LinkWrapper key={link.name + link.path}>
              <Link key={link.name + link.path} to={link.path} target={link.target} external={link.external}>
                {link.name}
              </Link>
            </LinkWrapper>
          ))}
          <Popover
            label={
              <BurgerWrapper>
                <HamburgerIcon src={hamburgerMenu} data-testid="menu" />{' '}
                <span
                  style={{
                    textDecoration: 'none',
                    textTransform: 'uppercase',
                    marginLeft: '10px',
                    fontSize: '1.25rem',
                  }}
                >
                  menu
                </span>
              </BurgerWrapper>
            }
            closeable={true}
            position={9}
            menu={true}
          >
            <BurgerMenuContent data-testid="menu-content">
              {(authorized ? burgerPrivateLinks : burgerPublicLinks).map(link => (
                <MenuContentLink key={link.name + link.path}>
                  <Link key={link.name + link.path} to={link.path} target={link.target} external={link.external}>
                    {link.name}
                  </Link>
                </MenuContentLink>
              ))}
              {authorized ? (
                <MenuContentLink>
                  <LinkButton onClick={openChangePasswordForm}>Change password</LinkButton>
                </MenuContentLink>
              ) : null}

              {authorized ? (
                <MenuContentLink>
                  <Link key="Contact us" to="/contact">
                    Contact us
                  </Link>
                </MenuContentLink>
              ) : null}

              {!authorized && (
                <MenuContentLink>
                  <LinkButton onClick={openLoginForm}>Login</LinkButton>
                </MenuContentLink>
              )}
              {authorized && (
                <MenuContentLink>
                  <LinkButton onClick={onLogout}>Logout</LinkButton>
                </MenuContentLink>
              )}
            </BurgerMenuContent>
          </Popover>
        </SideMenuWrapper>
      </Menu>
    </Wrapper>
  );
};

export default TopBar;
