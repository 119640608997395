import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 35px;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  font-weight: 700;
  font-size: 0.75rem;
  border-bottom: 1px solid gray;
`;

export const Row = styled.div`
  position: relative;
  flex-grow: 1;
  text-align: left;
  word-wrap: break-word;
  overflow-wrap: break-word;

  &:last-child {
    text-align: right;
  }
`;

export const InstrumentValue = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 6px;
  width: 30px;
`;
export const Value = styled.button<{ positive?: boolean }>`
  display: block;
  width: 100%;
  padding: 0;
  color: ${({ positive, theme }) => (positive ? theme.palette.success : theme.palette.error)};
  font-weight: 700;
  font-size: 0.8rem;
  text-align: right;
  background-color: transparent;
  border: 0;
`;

export const Arrow = styled.span`
  position: absolute;
  top: 50%;
  left: 0;
  display: inline-block;
  width: 0;
  height: 0;
  margin: auto;
  border-color: ${({ theme }) => theme.palette.gray['400']} transparent transparent transparent;
  border-style: solid;
  border-width: 4px 4px 0 4px;
  transform: translateY(-50%);
`;

export const CrossIcon = styled.img`
  display: block;
  width: 6px;
`;

export const ActiveResult = styled.span`
  background-color: ${({ theme }) => theme.palette.white};
`;

export const instrumentSelectStyles = {
  option: (styles: any, state: any) => {
    const nonSelectedBackground = state.isFocused ? '#aaa' : 'black';
    return {
      ...styles,
      background: state.isSelected ? 'white' : nonSelectedBackground,
      color: state.isSelected ? 'black' : 'white',
    };
  },
  control: (styles: any) => {
    return {
      ...styles,
      fontSize: '10px',
      flexGrow: 1,
      background: 'black',
      border: 'none',
      borderColor: 'black !important',
      boxShadow: 'none',
      paddingLeft: 0,
    };
  },
  singleValue: (styles: any) => {
    return {
      ...styles,
      textOverflow: 'none',
      whiteSpace: 'wrap',
      color: 'white',
    };
  },
  valueContainer: (styles: any) => {
    return {
      ...styles,
      padding: '2px 0',
      overflow: 'visible',
    };
  },
  menu: (styles: any) => {
    return {
      ...styles,
      marginTop: 0,
      borderRadius: 0,
      background: 'black',
      width: '150%',
    };
  },
  input: (styles: any) => {
    return {
      ...styles,
      color: 'white',
    };
  },
};
