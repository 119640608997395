import styled from 'styled-components';

const renderSize = (size: 'sm' | 'lg') => {
  switch (size) {
    case 'sm':
      return 16;
    case 'lg':
      return 100;
    default:
      return 100;
  }
};

export const StyledSpinner = styled.svg<{ size: 'sm' | 'lg' }>`
  display: block;
  width: ${({ size }) => `${renderSize(size)}px`};
  height: ${({ size }) => `${renderSize(size)}px`};
  margin: 0 auto;
  animation: rotate 1s linear infinite;

  circle {
    animation: dash 1.5s ease-in-out infinite;
    stroke: ${({ theme }) => theme.palette.white};
    stroke-linecap: round;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 30, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    85% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -180;
    }
  }
`;
