import { IMACDResponse } from 'api/types';
import zipWith from 'lodash.zipwith';
import { min } from 'date-fns';

interface IMACDChartDataPoint {
  date: string;
  firstMovingAverage: string;
  secondMovingAverage: string;
  histogram: string;
  macd: string;
  signal: string;
}

export class MACD {
  public static fromMACDResponse(macdResponse?: IMACDResponse) {
    if (macdResponse) {
      const firstMovingAverage = macdResponse.first_moving_average;
      const secondMovingAverage = macdResponse.second_moving_average;
      const { histogram, macd, signal } = macdResponse;
      const mergedMACD = zipWith(
        firstMovingAverage,
        secondMovingAverage,
        histogram,
        macd,
        signal,
        (firstMA, secondMA, h, m, s) => {
          return {
            date: firstMA.date,
            firstMovingAverage: firstMA.value,
            secondMovingAverage: secondMA.value,
            histogram: h.value,
            macd: m.value,
            signal: s.value,
          };
        }
      );
      return new MACD(mergedMACD.reverse());
    }
    return new MACD([]);
  }

  private constructor(public data: IMACDChartDataPoint[]) {}

  get chartData() {
    return this.data;
  }

  get minDate() {
    return min(this.data.map(element => new Date(element.date)));
  }

  get csvData() {
    return this.data.map(element => {
      const { firstMovingAverage, secondMovingAverage, ...rest } = element;
      return [...Object.values(rest)];
    });
  }

  get minMACD() {
    return Math.min(...this.data.map(element => parseFloat(element.macd)));
  }

  get maxMACD() {
    return Math.max(...this.data.map(element => parseFloat(element.macd)));
  }

  get minHistogram() {
    return Math.min(...this.data.map(element => parseFloat(element.histogram)));
  }

  get maxHistogram() {
    return Math.max(...this.data.map(element => parseFloat(element.histogram)));
  }
}
