import React, { FC, ReactElement, useEffect } from 'react';
import crossIcon from 'assets/icons/cross.svg';

import { CloseButton, StyledModal, Title } from './styles';
import { ModalProps } from '../types';

const DefaultModal: FC<ModalProps> = ({ isOpen, title, fullWidth = false, onClose, children }): ReactElement => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.cssText = 'overflow: hidden;';
    } else {
      document.body.style.cssText = '';
    }
    return () => {
      document.body.style.cssText = '';
    };
  }, [isOpen]);
  return (
    <StyledModal
      isOpen={isOpen}
      contentLabel={title || ''}
      onRequestClose={onClose}
      ariaHideApp={false}
      fullWidth={fullWidth}
    >
      <CloseButton onClick={onClose}>
        <img src={crossIcon} alt="Close" />
      </CloseButton>
      {title && <Title>{title}</Title>}
      {children}
    </StyledModal>
  );
};

export default DefaultModal;
