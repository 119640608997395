import styled from 'styled-components';

export const Separator = styled.p`
  margin: 0 20px;
`;

export const selectStyles = {
  option: (styles: any, state: any) => {
    const nonSelectedBackground = state.isFocused ? '#aaa' : 'black';
    return {
      ...styles,
      background: state.isSelected ? 'white' : nonSelectedBackground,
      color: state.isSelected ? 'black' : 'white',
    };
  },
  control: (styles: any, state: any) => {
    return {
      ...styles,
      width: '100px',
      background: 'black',
      border: '1px solid #aaa',
      borderRadius: '0',
    };
  },
  singleValue: (styles: any) => {
    return {
      ...styles,
      color: 'white',
    };
  },
  menu: (styles: any) => {
    return {
      ...styles,
      width: '100px',
      marginTop: 0,
      borderRadius: 0,
      background: 'black',
    };
  },
  input: (styles: any) => {
    return {
      ...styles,
      color: 'white',
    };
  },
};

export const movingAvgSelectStyles = {
  option: (styles: any, state: any) => {
    const nonSelectedBackground = state.isFocused ? '#aaa' : 'black';
    return {
      ...styles,
      background: state.isSelected ? 'white' : nonSelectedBackground,
      color: state.isSelected ? 'black' : 'white',
    };
  },
  control: (styles: any, state: any) => {
    return {
      ...styles,
      maxWidth: '620px',
      minWidth: '250px',
      background: 'black',
      border: '1px solid #aaa',
      borderRadius: '0',
    };
  },
  singleValue: (styles: any) => {
    return {
      ...styles,
      color: 'white',
    };
  },
  menu: (styles: any) => {
    return {
      ...styles,
      maxWidth: '620px',
      minWidth: '250px',
      marginTop: 0,
      borderRadius: 0,
      background: 'black',
    };
  },
  input: (styles: any) => {
    return {
      ...styles,
      color: 'white',
    };
  },
};
