import { Component } from 'react';

interface IState {
  hasError: boolean;
}

class ErrorBoundary extends Component<{}, IState> {
  constructor(props: any) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '64px' }}>
          <h1 style={{ marginBottom: '8px' }}>Ups! Something went wrong.</h1>
          <p>Please refresh page and try again.</p>
          <button type="button" onClick={() => window.location.assign(window.location.origin)}>
            Refresh
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
