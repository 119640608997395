import styled from 'styled-components';
import { Col } from 'react-grid-system';

export const Navigation = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(1)};

  button {
    margin-right: ${({ theme }) => theme.spacing(2)};
  }

  @media (max-width: 510px) {
    button,
    a {
      font-size: 16px;
    }
  }
`;
export const PublicColumnWrapper = styled(Col)`
  @media (min-width: 992px) {
    max-width: calc(50% - 6px) !important;
  }
`;
export const PrivateColumnWrapper = styled(Col)`
  @media (min-width: 992px) {
    max-width: calc(50% - 6px) !important;
  }
  @media (min-width: 1440px) {
    max-width: calc(33.333% - 4px) !important;
  }
`;
export const PrivateRiskometerWrapper = styled(Col)`
  @media (min-width: 1440px) {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }
`;
