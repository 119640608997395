import styled from 'styled-components';

export const StyledButton = styled.button`
  width: 17px;
  height: 17px;
  font-weight: 700;
  font-size: 10px;
  text-align: center;
  background-color: transparent;
  border-radius: 50%;
  cursor: pointer;
  ${({ theme: { palette } }) => `
  color: ${palette.white};
    border: 1px solid ${palette.gray['400']};
  `}
`;
