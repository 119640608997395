import styled from 'styled-components';
import { Col, Row } from 'react-grid-system';
import React, { forwardRef } from 'react';

interface IProps {
  title?: React.ReactNode;
  legend?: React.ReactNode;
  chart: React.ReactNode;
  chartName?: string;
  margin?: number;
  width?: string;
  overflow?: boolean;
}

export const Chart = forwardRef<HTMLDivElement, IProps>(
  ({ chartName, title, legend, margin = 40, overflow = true, width = 'auto', chart }, ref) => {
    return (
      <Row style={{ marginBottom: margin, width }}>
        <div ref={ref} id={chartName} data-testid={chartName} style={{ width: '100%' }}>
          <Col>
            {title && <ChartTitle>{title}</ChartTitle>}
            {legend && <Row justify="center">{legend}</Row>}
            <ChartWrapper id="chart-wrapper" overflow={overflow}>
              {chart}
            </ChartWrapper>
          </Col>
        </div>
      </Row>
    );
  }
);

const ChartTitle = styled(Row)`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;
const ChartWrapper = styled(Row)<{ overflow: boolean }>`
  overflow-x: ${({ overflow }) => (overflow ? 'auto' : 'visible')};
  overflow-y: ${({ overflow }) => (overflow ? 'clip' : 'visible')};
  padding-bottom: 12px;
`;
