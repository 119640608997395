import React, { Suspense } from 'react';
import { lazyImport } from 'utils/lazyImport';
import { Navigate, Outlet } from 'react-router-dom';
import { Dashboard } from 'components/Dashboard';
import { Col, Row } from 'react-grid-system';
import { HeatMapCorrelation } from 'components/common/HeatMapCorrelation';
import { MainContainer } from 'components/App/styles';
import { MetadataProvider } from '../components/common/MetadataContext';
import { ContactWrapper } from 'components/Contact/styles';

const { Matrix } = lazyImport(() => import('components/Matrix/Matrix'), 'Matrix');
const { Alerts } = lazyImport(() => import('components/Alerts/Alerts'), 'Alerts');
const { Contact } = lazyImport(() => import('components/Contact/Contact'), 'Contact');
const { Tutorials } = lazyImport(() => import('components/Tutorials/Tutotrials'), 'Tutorials');
const { SettingsPage } = lazyImport(() => import('components'), 'SettingsPage');
const { FAQPage } = lazyImport(() => import('components'), 'FAQPage');
const { Correlation } = lazyImport(() => import('components/PairTrading'), 'Correlation');
const { Distance } = lazyImport(() => import('components/PairTrading'), 'Distance');
const { Cointegration } = lazyImport(() => import('components/PairTrading'), 'Cointegration');
const { PairTrading } = lazyImport(() => import('components/PairTrading'), 'PairTrading');

const App = () => (
  <Suspense fallback="">
    <Outlet />
  </Suspense>
);

export const privateRoutes = [
  {
    path: '/',
    element: <Navigate to="/dashboard" />,
  },
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/dashboard',
        element: <Dashboard />,
      },
      {
        path: '/settings',
        element: <SettingsPage />,
      },
      {
        path: '/pair-trading/cointegration',
        element: (
          <PairTrading>
            <Cointegration />
          </PairTrading>
        ),
      },
      {
        path: '/pair-trading/correlation',
        element: (
          <PairTrading>
            <Correlation />
          </PairTrading>
        ),
      },
      {
        path: '/pair-trading/distance',
        element: (
          <PairTrading>
            <Distance />
          </PairTrading>
        ),
      },
      {
        path: '/matrix',
        element: (
          <MetadataProvider>
            <Matrix />
          </MetadataProvider>
        ),
      },
      {
        path: '/contact',
        element: (
          <MainContainer>
            <Row style={{ marginLeft: 0 }}>
              <Col>
                <HeatMapCorrelation>
                  <ContactWrapper>
                    <Contact />
                  </ContactWrapper>
                </HeatMapCorrelation>
              </Col>
            </Row>
          </MainContainer>
        ),
      },
      {
        path: '/faq',
        element: <FAQPage />,
      },
      {
        path: '/tutorials',
        element: <Tutorials />,
      },
      {
        path: '/alerts',
        element: <Alerts />,
      },
      {
        path: '*',
        element: <Navigate to="/dashboard" />,
      },
    ],
  },
];
