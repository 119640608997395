import { IFrequencyResponse } from 'api/types';
import zipWith from 'lodash.zipwith';
import { min } from 'date-fns';
import { RESPONSE_DATE_REGEX } from '../RegimeChangeApi';

interface IFrequencyChartData {
  date: string;
  bull: string;
  bear: string;
  priceChanges: string;
}

export class FrequencyData {
  public static fromFrequencyResponse(frequencyResponse?: IFrequencyResponse) {
    if (frequencyResponse) {
      const { frequency } = frequencyResponse;
      const priceChanges = frequencyResponse.price_change;
      const mergedFrequency = zipWith(frequency, priceChanges, (f, p) => {
        if (!f || !RESPONSE_DATE_REGEX.test(f.date) || !p || !RESPONSE_DATE_REGEX.test(p.date)) return undefined;
        return {
          date: f.date,
          bull: f.bull,
          bear: f.bear,
          priceChanges: p.value,
        };
      });
      const filteredMergedFrequency = mergedFrequency.filter((el): el is IFrequencyChartData => !!el);
      return new FrequencyData(filteredMergedFrequency);
    }
    return new FrequencyData([]);
  }

  private constructor(public data: IFrequencyChartData[]) {}

  get chartData() {
    return this.data;
  }

  get minDate() {
    return min(this.data.map(element => new Date(element.date)));
  }

  get csvData() {
    return this.data.map(element => [...Object.values(element)]);
  }

  get priceChanges() {
    return this.data.map(element => ({
      date: element.date,
      priceChanges: element.priceChanges,
    }));
  }

  get priceChangesValues() {
    return this.priceChanges.map(e => parseFloat(e.priceChanges));
  }

  get minPriceChange() {
    return Math.min(...this.priceChangesValues);
  }

  get maxPriceChange() {
    return Math.max(...this.priceChangesValues);
  }

  get maxFrequency() {
    return Math.max(
      ...this.data.map(element => parseFloat(element.bull)),
      ...this.data.map(element => parseFloat(element.bear))
    );
  }
}
