import styled from 'styled-components';

export const StyledSliderChart = styled.div`
  position: relative;
  width: 100%;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  border: 1px solid #fff;
  border-radius: 20px;
  background-color: #000;
  padding: 2px 16px;
  overflow: hidden;
`;
export const StyledSliderCharValue = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: ${({ left }: { left: number }) => `${left}%`};
  z-index: 1;
  color: #fff;
  font-size: 14px;
`;
export const StyledSlider = styled.div`
  position: absolute;
  z-index: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: ${({ value }: { value: number }) => `${100 - value * 100}%`};
  background-color: red;
`;
