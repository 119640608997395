import { MutableRefObject } from 'react';
import styled from 'styled-components';

import BaseButton from 'components/common/BaseButton';

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;
`;

export const Label = styled(BaseButton)<{ disabled: boolean }>`
  font-size: inherit;
  text-decoration: underline;
  ${({ theme, disabled }) => `
    color: ${theme.palette.brand};
    cursor: ${disabled ? 'unset' : 'pointer'};
  `}
`;

export const List = styled.ul<{ ref?: MutableRefObject<HTMLUListElement> }>`
  min-width: 200px;
  margin: 0;
  padding: 0;
  ${({ theme }) => `
    background-color: ${theme.palette.background};
    border: 1px solid ${theme.palette.gray['300']};
  `}
`;

export const ListItem = styled.li`
  position: relative;
  display: block;
  padding: ${({ theme }) => theme.spacing(1, 1)};
  list-style: none;
  cursor: pointer;
`;

export const NestedList = styled(List)`
  position: relative;
  border: none;
`;
