import axios, { AxiosRequestConfig } from 'axios';
import { SWRConfig } from 'swr';
import { PublicConfiguration } from 'swr/dist/types';
import React from 'react';
import { BASE_URL } from 'utils/constants';
import useAuth from 'hooks/useAuth';
import { useNavigate } from 'react-router-dom';

export const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

export function dataFetcher<T = unknown>(requestConfig: AxiosRequestConfig) {
  return axiosInstance(requestConfig).then(res => res.data as T);
}

export interface DataFetchingConfigProps {
  config?: Partial<{ provider: () => Map<any, any> } & PublicConfiguration>;
}

const DataFetchingConfig: React.FunctionComponent<DataFetchingConfigProps> = ({ children, config }) => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  return (
    <SWRConfig
      value={{
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        shouldRetryOnError: false,
        fetcher: dataFetcher,
        onError: error => {
          if (error === 401) {
            logout();
            navigate('/about');
          }
        },
        ...config,
      }}
    >
      {children}
    </SWRConfig>
  );
};

export default DataFetchingConfig;
