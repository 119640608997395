import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2px;
`;

export const LabelIndicator = styled.div<{ color?: string }>`
  width: 11px;
  height: 11px;
  margin-right: 2px;
  ${({ color, theme }) => `
    border: 1px solid ${theme.palette.white};
    background-color: ${color};
  `}
`;
