import React from 'react';
import ReactDOM from 'react-dom';
import { Normalize } from 'styled-normalize';
import { enableMapSet } from 'immer';
import { setConfiguration } from 'react-grid-system';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

setConfiguration({ breakpoints: [576, 768, 992, 1200, 1440] });

ReactDOM.render(
  <React.StrictMode>
    <Normalize />
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
enableMapSet();
export { ChartTooltip } from 'components/common/Charts/ChartToolTip';
