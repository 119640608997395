import { ComposedChart, Label, Line, Tooltip, XAxis, YAxis } from 'recharts';
import { format, parseISO } from 'date-fns';
import React, { memo, useRef } from 'react';
import theme from 'styles/theme';
import Spinner from 'components/common/Spinner';
import { Col } from 'react-grid-system';
import { ChartTooltip } from 'components/common/Charts/ChartToolTip';
import { ChartLegend } from 'components/Charts/ChartLegend';
import { chartStyles } from 'components/RegimeChange/styles';
import { useBoolean } from 'hooks/useBoolean';
import { IInstrument } from 'components/common/Charts/types';
import { Chart } from 'components/RegimeChange/Chart';
import { CSVIMGExport } from 'components/common/DataExport/CSVIMGExport';
import { DataExportRow } from 'components/common/DataExport/styles';
import { useRegimeChangeApi } from '../useRegimeChangeApi';
import { IGraph } from '../types';
import { ChartTitle } from '../ChartTitle';

interface IMovingAverages extends Omit<IGraph, 'options'> {
  label: string;
}

export const MovingAverages: React.FC<IMovingAverages> = memo(({ instrument, label, instrumentVariants }) => {
  const { useMovingAveragesData } = useRegimeChangeApi();
  const [isMarkowState1Visible, toggleMarkowState1] = useBoolean(true);
  const [isMarkowState2Visible, toggleMarkowState2] = useBoolean(true);
  const [isPercentChangeVisible, togglePercentChange] = useBoolean(true);

  const { data, isValidating, error } = useMovingAveragesData({ instrument: instrument?.value });

  const chartRef = useRef(null);

  if (error) return <div>Moving averages chart - Error has occurred</div>;
  if (isValidating || !data) return <Spinner />;
  if (!data.data.length) return null;

  const toolTipLabels = {
    bull: '60 Day Markov State 1 MA: ',
    bear: '60 Day Markov State 2 MA: ',
    priceChanges: `${label} ${format(data.minDate, 'MM/dd/yyyy')}:`,
  };

  const instrumentsChartConfig = [
    {
      name: 'bull',
      label: '60 Day Markov State 1 MA',
      color: '#66b3ff',
      isVisible: isMarkowState1Visible,
      onClick: toggleMarkowState1,
      yAxisId: 'left',
    },
    {
      name: 'bear',
      label: '60 Day Markov State 2 MA',
      color: '#cc0000',
      isVisible: isMarkowState2Visible,
      onClick: toggleMarkowState2,
      yAxisId: 'left',
    },
    {
      name: 'priceChanges',
      label: `${label} ${format(data.minDate, 'MM/dd/yyyy')}`,
      color: theme.palette.brand,
      isVisible: isPercentChangeVisible,
      onClick: togglePercentChange,
      yAxisId: 'right',
    },
  ];

  const chartTitle = '60 Day Moving Averages for Markov values';
  const csvHeading = `Instrument, ${instrument?.label}`;
  const csvData = {
    fields: ['Date', 'Markov State 1', 'Markov State 2', 'Price Change'],
    data: data.csvData,
  };
  return (
    <>
      <DataExportRow justify="end">
        <Col xs={2}>
          <CSVIMGExport data={csvData} fileName={chartTitle} chartRef={chartRef} csvHeading={csvHeading} />
        </Col>
      </DataExportRow>
      <Chart
        chartName="moving-averages-chart"
        ref={chartRef}
        title={
          <ChartTitle title={chartTitle} instrumentName={instrument?.label} instrumentVariants={instrumentVariants} />
        }
        legend={<ChartLegend instruments={instrumentsChartConfig as IInstrument[]} />}
        chart={
          <ComposedChart data={data.chartData} width={1400} height={300}>
            {instrumentsChartConfig.map(i => (
              <Line
                key={i.name}
                dataKey={i.name}
                hide={!i.isVisible}
                dot={false}
                activeDot={false}
                yAxisId={i.yAxisId}
                stroke={i.color}
                isAnimationActive={false}
              />
            ))}
            <YAxis
              yAxisId="left"
              dataKey="markovValue"
              domain={[0, 1]}
              tickCount={11}
              tickLine={false}
              axisLine={false}
              dx={-10}
              style={chartStyles.axis}
            >
              <Label
                value="60 Day Markov State 1 MA"
                offset={10}
                style={{ fill: 'white', textAnchor: 'middle', fontSize: '0.75rem' }}
                angle={-90}
                position="insideLeft"
              />
            </YAxis>
            <YAxis
              yAxisId="right"
              dataKey="price_change"
              domain={[Math.floor(data.minPriceChange), Math.ceil(data.maxPriceChange / 5) * 5]}
              orientation="right"
              tickLine={false}
              axisLine={false}
              dx={10}
              style={chartStyles.axis}
            >
              <Label
                value={`${label} ${format(data.minDate, 'MM/dd/yyyy')}`}
                offset={10}
                style={{ fill: 'white', textAnchor: 'middle', fontSize: '0.75rem' }}
                angle={90}
                position="insideRight"
              />
            </YAxis>
            <XAxis
              dataKey="date"
              tickLine={false}
              axisLine={false}
              tickFormatter={value => {
                return value && format(parseISO(value), "MMM ''yy");
              }}
              dy={15}
              dx={30}
              interval={30}
              style={chartStyles.axis}
            />
            <Tooltip
              cursor={{ stroke: theme.palette.brand }}
              content={<ChartTooltip toolTipLabels={toolTipLabels} />}
              isAnimationActive={false}
            />
          </ComposedChart>
        }
      />
    </>
  );
});
