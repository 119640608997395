import styled from 'styled-components';

export const Wrapper = styled.label`
  position: relative;
  display: block;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const StyledInput = styled.input`
  width: 100%;
  height: 50px;
  font-size: 2rem;
  border: 0;
  outline: none;

  ${({ theme }) => `
    background-color: ${theme.palette.gray[600]};
    padding: ${theme.spacing(0.5, 2)};
    color: ${theme.palette.white};
  `}
  &::placeholder {
    color: ${({ theme }) => theme.palette.white};
    opacity: 1;
  }
`;

export const Error = styled.div`
  position: relative;
  padding-top: 4px;
  color: ${({ theme }) => theme.palette.error};
  font-size: 0.9rem;
`;

export const Label = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  font-size: 0.9rem;
`;
