import React from 'react';

const CorrelationHelpModal = () => (
  <p>
    The bar-chart <strong>GRAPH</strong> is launched when a correlation-value tile has been selected in either the
    <strong> HEAT MAP</strong> or the <strong>WATCHLIST</strong>. The <strong>GRAPH</strong> offers a view of the past
    six-months and the data displayed shows a daily value for the calculation method selected in
    <strong> Heat Map</strong> or <strong>Watchlist</strong>. Underneath the bar chart is a line chart that shows the
    percent price movement of the underlying pairs that have been selected. <strong>Percentile Ranking </strong>
    displays the percentile rankings of the distribution of correlation values using the time period and correlation
    calculations selected in either <strong>Heat Map</strong> or <strong>WATCHLIST</strong>.{' '}
    <strong>Crossing The Median</strong> measures the frequency that the correlation values of the two time series being
    compared cross or touch the median correlation value over the past six months.
  </p>
);

export default CorrelationHelpModal;
