import React, { FC, ReactElement, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useModal } from 'hooks/useModal';
import { useCalculatorFilter, useRelationshipProvider } from 'components/common/HeatMapCorrelation/FilterContext';
import { CheckboxOption, IModalOption, IOption, isOptionGroup } from 'types';
import { getDay, sub } from 'date-fns';
import { ModalRow, Options, PickedCategories, Row, Wrapper } from 'components/common/Filters/styles';
import Dropdown from 'components/common/Dropdown';
import { CheckboxListItem, CheckboxListItemGroup, StringListItem } from 'components/common/Dropdown/Dropdown';
import range from 'lodash.range';
import { HeatMapFiltersProps } from 'components/common/Filters/types';
import { enableMapSet } from 'immer';
import { Views } from 'components/common/HeatMapCorrelation/HeatMap/constants';
import { defaultAverageCalculatorFilter } from 'components/common/Filters/filtersDefaults';
import { useMetadata } from 'components/common/MetadataContext';
import isEqual from 'lodash.isequal';
import { formatRange, replaceDashToSlash } from 'utils/Date';
import {
  assertCalculatorIsAverage,
  assertCalculatorIsRolling,
  assertMethodOfCalculationIsExponentiallyWeighted,
  AveragePeriodFilterValue,
  CalculatorFilterValue,
  DateFilter,
  DecayFactorFilter,
  MethodOfCalculationFilterValue,
  PeriodFilterValue,
  TimeWindowFilterValue,
} from 'components/common/HeatMapCorrelation/types';
import { useDataVariantParam } from 'hooks';

const calculatorOptions = {
  [CalculatorFilterValue.Average]: { label: 'Average', value: CalculatorFilterValue.Average },
  [CalculatorFilterValue.Rolling]: { label: 'Rolling', value: CalculatorFilterValue.Rolling },
};

export const Filters: FC<HeatMapFiltersProps> = ({
  disabled,
  view,
  disableCategory,
  filtersDisabled = {
    calculator: false,
    date: false,
    rollingFilters: false,
    averageFilters: false,
    categoriesFilters: false,
  },
}): ReactElement => {
  const { dateFilter, calculatorFilter, changeCalculatorFilterType, updateCalculatorFilter } = useCalculatorFilter();
  enableMapSet();

  const { calculator } = calculatorFilter;

  const changeFilterType = (event: MouseEvent, calc: IOption<CalculatorFilterValue>) =>
    changeCalculatorFilterType(calc.value, updateCalculatorFilter);

  useEffect(() => {
    if (dateFilter.startDate && calculatorFilter.calculator === CalculatorFilterValue.Rolling) {
      updateCalculatorFilter(() => {
        return defaultAverageCalculatorFilter;
      });
    }
  }, [dateFilter.startDate]);

  return (
    <Wrapper disabled={disabled}>
      {!filtersDisabled.date && <DatePickFilter disabled={disabled} />}
      {!filtersDisabled.calculator && (
        <Row>
          <Dropdown<IOption<CalculatorFilterValue>>
            label="Calculator: "
            options={Object.values(calculatorOptions)}
            data-testid="calculator-options"
            disabled={disabled || !!dateFilter.startDate}
          >
            {Object.values(calculatorOptions).map(option => (
              <StringListItem key={option.label} option={option} onChange={changeFilterType} />
            ))}
          </Dropdown>
          {calculatorOptions[calculator].label}
        </Row>
      )}
      {!filtersDisabled.rollingFilters && calculator === CalculatorFilterValue.Rolling && (
        <RollingFilters disabled={disabled} />
      )}
      {!filtersDisabled.averageFilters && calculator === CalculatorFilterValue.Average && (
        <AverageFilters disabled={disabled} />
      )}
      {!filtersDisabled.categoriesFilters && (view === Views.HEAT_MAP || view === Views.MATRIX) && (
        <HeatMapCategoriesFilter disabled={disabled} disableCategory={disableCategory} />
      )}
    </Wrapper>
  );
};

const DatePickFilter: React.FC<{ disabled: boolean | undefined }> = ({ disabled }) => {
  const { setInfoModal, onCloseModal } = useModal();

  const { dateFilter, updateDateFilter } = useCalculatorFilter();

  const changeDate = (option: IModalOption<Date | undefined>) => {
    if (option.value) {
      updateDateFilter(_prevState => ({ startDate: undefined, endDate: option.value! }));
      return;
    }
    setInfoModal(option.modal);
  };

  const { latestDate } = useMetadata();

  const isWeekday = (date: Date) => {
    const day = getDay(date);
    return day !== 0 && day !== 6;
  };

  const PickDateModal = () => {
    return (
      <DatePicker
        selected={dateFilter.endDate}
        onChange={(endDate: Date) => {
          updateDateFilter((prevState: DateFilter) => ({ ...prevState, endDate }));
          onCloseModal();
        }}
        showMonthDropdown
        showYearDropdown
        filterDate={isWeekday}
        minDate={new Date('2007')}
        maxDate={replaceDashToSlash(latestDate)}
        dropdownMode="select"
        inline
      />
    );
  };

  const PickRangeModal = () => {
    return (
      <ModalRow>
        <DatePicker
          selected={dateFilter.startDate}
          onChange={(startDate: Date) => {
            updateDateFilter((prevState: DateFilter) => ({ ...prevState, startDate }));
          }}
          selectsStart
          showMonthDropdown
          showYearDropdown
          startDate={dateFilter.startDate}
          endDate={dateFilter.startDate ? dateFilter.endDate : sub(new Date(dateFilter.endDate!), { months: 1 })}
          filterDate={isWeekday}
          minDate={new Date('2007')}
          maxDate={replaceDashToSlash(latestDate)}
          dropdownMode="select"
          inline
          dayClassName={() => 'calendar-day'}
        />
        <DatePicker
          selected={dateFilter.endDate}
          onChange={(endDate: Date) => {
            updateDateFilter((prevState: DateFilter) => ({ ...prevState, endDate }));
            onCloseModal();
          }}
          selectsEnd
          showMonthDropdown
          showYearDropdown
          startDate={dateFilter.startDate}
          endDate={dateFilter.endDate}
          filterDate={isWeekday}
          minDate={new Date('2007')}
          maxDate={replaceDashToSlash(latestDate)}
          dropdownMode="select"
          inline
        />
      </ModalRow>
    );
  };

  const datePickerOptions = [
    { label: 'Current', modal: null, value: replaceDashToSlash(latestDate) },
    { label: '--/--/--', modal: <PickDateModal />, value: undefined },
    { label: 'Custom range', modal: <PickRangeModal />, value: undefined },
  ];

  const changePickedDate = (event: MouseEvent, option: IModalOption) => {
    changeDate(option);
  };

  const formatDateLabel = () => {
    if (!dateFilter.endDate || !latestDate || dateFilter.startDate) return '';
    if (formatRange(undefined, dateFilter.endDate) === formatRange(undefined, replaceDashToSlash(latestDate)!)) {
      return 'Latest Available ';
    }

    return '';
  };
  return (
    <Row>
      {formatDateLabel()}
      <Dropdown<IModalOption<Date | undefined>> label="Date:" options={datePickerOptions} disabled={disabled}>
        {' '}
        {Object.values(datePickerOptions).map(option => (
          <StringListItem key={option.label} option={option} onChange={changePickedDate} />
        ))}
      </Dropdown>
      {dateFilter.endDate && formatRange(dateFilter.startDate!, dateFilter.endDate!)}
    </Row>
  );
};

const RollingFilters: React.FC<{ disabled: boolean | undefined }> = ({ disabled }) => {
  const {
    calculatorFilter,
    changeTimePeriod,
    changeTimeWindow,
    changeMethodOfCalculation,
    updateCalculatorFilter,
  } = useCalculatorFilter();
  assertCalculatorIsRolling(calculatorFilter);

  const {
    period,
    timeWindow,
    methodOfCalculation: { method },
  } = calculatorFilter;

  const periodOptions = {
    [PeriodFilterValue.FiveDay]: { label: '5 days', value: PeriodFilterValue.FiveDay },
    [PeriodFilterValue.TenDay]: { label: '10 days', value: PeriodFilterValue.TenDay },
  };

  const timeWindowOptions = {
    [TimeWindowFilterValue.OneMonth]: { label: '1 Month', value: TimeWindowFilterValue.OneMonth },
    [TimeWindowFilterValue.ThreeMonths]: { label: '3 Month', value: TimeWindowFilterValue.ThreeMonths },
    [TimeWindowFilterValue.SixMonths]: { label: '6 Month', value: TimeWindowFilterValue.SixMonths },
  };

  const methodOfCalculationOptions = {
    [MethodOfCalculationFilterValue.EquallyWeighted]: {
      label: 'Equally weighted',
      value: MethodOfCalculationFilterValue.EquallyWeighted,
    },
    [MethodOfCalculationFilterValue.ExponentiallyWeighted]: {
      label: 'Exponentially weighted',
      value: MethodOfCalculationFilterValue.ExponentiallyWeighted,
    },
  };

  const changePeriodFilter = (event: MouseEvent, timePeriod: IOption<PeriodFilterValue>) => {
    changeTimePeriod(timePeriod.value, updateCalculatorFilter);
  };

  const changeTimeWindowFilter = (event: MouseEvent, timePeriod: IOption<TimeWindowFilterValue>) =>
    changeTimeWindow(timePeriod.value, updateCalculatorFilter);

  const changeCalculationMethod = (event: MouseEvent, calculationMethod: IOption<MethodOfCalculationFilterValue>) =>
    changeMethodOfCalculation(calculationMethod.value, updateCalculatorFilter);

  return (
    <>
      <Row>
        <Dropdown<IOption<PeriodFilterValue>>
          label="Period:"
          options={Object.values(periodOptions)}
          data-testid="period-options"
          disabled={disabled}
        >
          {Object.values(periodOptions).map(option => (
            <StringListItem key={option.label} option={option} onChange={changePeriodFilter} />
          ))}
        </Dropdown>
        {periodOptions[period].label}
        &nbsp; | &nbsp;
        <Dropdown<IOption<TimeWindowFilterValue>>
          label="Time Window:"
          options={Object.values(timeWindowOptions)}
          data-testid="time-window-options"
          disabled={disabled}
        >
          {Object.values(timeWindowOptions).map(option => (
            <StringListItem key={option.label} option={option} onChange={changeTimeWindowFilter} />
          ))}
        </Dropdown>
        {timeWindowOptions[timeWindow].label}
      </Row>
      <Row>
        <Dropdown
          label="Method of calculation:"
          options={Object.values(methodOfCalculationOptions)}
          data-testid="method-of-calculation-options"
          disabled={disabled}
        >
          {Object.values(methodOfCalculationOptions).map(option => (
            <StringListItem key={option.label} option={option} onChange={changeCalculationMethod} />
          ))}
        </Dropdown>
        {methodOfCalculationOptions[method].label}
      </Row>
      {method === MethodOfCalculationFilterValue.ExponentiallyWeighted && <DecayFilters disabled={disabled} />}
    </>
  );
};

const AverageFilters: React.FC<{ disabled: boolean | undefined }> = ({ disabled }) => {
  const { calculatorFilter, changeAveragePeriod, updateCalculatorFilter, dateFilter } = useCalculatorFilter();
  assertCalculatorIsAverage(calculatorFilter);
  const { averagePeriod } = calculatorFilter;

  const averagePeriodOptions = {
    [AveragePeriodFilterValue.FiveDay]: { label: '5 Day', value: AveragePeriodFilterValue.FiveDay },
    [AveragePeriodFilterValue.TenDay]: { label: '10 Day', value: AveragePeriodFilterValue.TenDay },
    [AveragePeriodFilterValue.OneMonth]: { label: '1 Month', value: AveragePeriodFilterValue.OneMonth },
    [AveragePeriodFilterValue.ThreeMonths]: { label: '3 Months', value: AveragePeriodFilterValue.ThreeMonths },
    [AveragePeriodFilterValue.SixMonths]: { label: '6 Months', value: AveragePeriodFilterValue.SixMonths },
  };

  const changeAveragePeriodFilter = (event: MouseEvent, period: IOption<AveragePeriodFilterValue>) =>
    changeAveragePeriod(period.value, updateCalculatorFilter);

  // useEffect(() => {
  //   if (dateFilter.startDate && dateFilter.endDate) {
  //     changeAveragePeriod(undefined, updateCalculatorFilter);
  //   }
  // }, [dateFilter]);

  return (
    <Row>
      <Dropdown<IOption<AveragePeriodFilterValue | undefined>>
        label="Average Period:"
        options={Object.values(averagePeriodOptions)}
        data-testid="average-period-options"
        disabled={dateFilter.startDate && dateFilter.endDate ? true : disabled}
      >
        {Object.values(averagePeriodOptions).map(option => (
          <StringListItem key={option.label} option={option} onChange={changeAveragePeriodFilter} />
        ))}
      </Dropdown>
      {dateFilter.startDate && dateFilter.endDate
        ? 'Range'
        : averagePeriod && averagePeriodOptions[averagePeriod].label}
    </Row>
  );
};
const DecayFilters: React.FC<{ disabled: boolean | undefined }> = ({ disabled }) => {
  const { calculatorFilter, changeTimeDecay, updateCalculatorFilter } = useCalculatorFilter();
  assertCalculatorIsRolling(calculatorFilter);
  const { methodOfCalculation } = calculatorFilter;
  assertMethodOfCalculationIsExponentiallyWeighted(methodOfCalculation);

  const decayOptions = range(0.94, 0.8, -0.01).map(number => ({
    label: number.toFixed(2),
    value: number.toFixed(2),
  }));

  const changeTimeDecayFilter = (event: MouseEvent, option: IOption<DecayFactorFilter>) =>
    changeTimeDecay(option.value, updateCalculatorFilter);

  return (
    <Row>
      <Dropdown<IOption<DecayFactorFilter>>
        label="Decay:"
        options={decayOptions}
        data-testid="decay-options"
        disabled={disabled}
      >
        {Object.values(decayOptions).map(option => (
          <StringListItem key={option.label} option={option} onChange={changeTimeDecayFilter} />
        ))}
      </Dropdown>
      {methodOfCalculation.decayFactor}
    </Row>
  );
};

const filterOptions: CheckboxOption<string[]>[] = [
  {
    value: ['fx'],
    label: 'FX(All)',
    options: [
      { label: 'FX Majors', value: ['fx', 'fx_other'] },
      { label: 'FX Emerging Markets', value: ['fx_america', 'fx_asia', 'fx_emea'] },
    ],
  },
  {
    value: ['commodity_agri', 'commodity_energy', 'commodity_industrial_metals', 'commodity_precious_metals'],
    label: 'Commodities',
  },
  { value: ['rates'], label: 'Rates' },
  { value: ['equities'], label: 'Equities' },
  { value: ['crypto'], label: 'Cryptocurrencies' },
];

//TODO - change categories on the backend instead of filtering and formatting here. We do not need that subcategories eg. fx_other,fx_america itp
const findParentCategory = (targetCategory: string, categories: CheckboxOption<string[]>[]) => {
  return categories.findIndex(category => {
    let i: number;
    if (isOptionGroup(category)) {
      i = findParentCategory(targetCategory, category.options);
    } else {
      i = category.value.findIndex(subcategory => subcategory === targetCategory);
    }

    return i >= 0;
  });
};
//TODO - change categories on the backend instead of filtering and formatting here. We do not need that subcategories eg. fx_other,fx_america itp
const mergeCategories = (currentCategories: string[][]) => {
  return currentCategories.map((cat, index) => {
    const parent = filterOptions[findParentCategory(cat[0], filterOptions)] || null;
    if (parent && isOptionGroup(parent)) {
      const subParent = parent.options[findParentCategory(cat[0], parent.options)] || null;
      if (subParent) return subParent.label;
    }
    if (!parent) return cat[index];
    return parent.label;
  });
};

const HeatMapCategoriesFilter: React.FC<{ disabled: boolean | undefined; disableCategory?: string | string[] }> = ({
  disabled,
  disableCategory,
}) => {
  const { dataVariant } = useDataVariantParam();
  const { categoriesFilter, updateCategoriesFilter } = useCalculatorFilter();

  const filterCategories = (categories: CheckboxOption<string[]>[]) => {
    if (!disableCategory) return categories;
    return categories.filter(el => !disableCategory.includes(el.label));
  };
  const toggleOption = (option: CheckboxOption<string[]>, value: boolean) => {
    if (isOptionGroup(option)) {
      option.options.forEach(nestedOption => toggleOption(nestedOption, value));
    } else {
      updateCategoriesFilter(filters => {
        if (value) {
          //Issue with different reference in case of objects and arrays - parse it into string, create new Set, remove duplicates and create new Set again after parsing into object.
          const newSet = new Set([...Array.from(filters), option.value].map(el => JSON.stringify(el)));

          return new Set(Array.from(newSet).map(el => JSON.parse(el)));
        }
        return new Set(Array.from(filters).filter(el => !isEqual(el, option.value)));
      });
    }
  };

  const isChecked = (option: CheckboxOption<string[]>): boolean => {
    if (isOptionGroup(option)) {
      return option.options.every(isChecked);
    }
    const index = Array.from(categoriesFilter).findIndex(el => {
      return isEqual(el, option.value);
    });
    return index > -1;
  };

  const ListItem = ({ option }: { option: CheckboxOption<string[]> }) => {
    const onChange = (value: boolean) => {
      toggleOption(option, value);
    };
    const checked = isChecked(option);

    if (isOptionGroup(option)) {
      return (
        <CheckboxListItemGroup<CheckboxOption<string[]>>
          key={option.label}
          option={option}
          checked={checked}
          onChange={onChange}
        >
          {option.options.map(nestedOption => (
            <ListItem key={nestedOption.label} option={nestedOption} />
          ))}
        </CheckboxListItemGroup>
      );
    }

    return <CheckboxListItem key={option.label} option={option} checked={checked} onChange={onChange} />;
  };

  const AllOption = () => {
    const onChange = (value: boolean) => {
      filterCategories(filterOptions).forEach(option => toggleOption(option, value));
    };

    const checked = filterCategories(filterOptions).every(isChecked);

    return <CheckboxListItem option={{ value: ['all'], label: 'All' }} checked={checked} onChange={onChange} />;
  };

  return (
    <Row>
      <Dropdown<CheckboxOption<string[]>>
        label="Filter:"
        options={filterCategories(filterOptions)}
        data-testid="categories-filter-options"
        disabled={disabled}
      >
        <Options>
          <AllOption />
          {Object.values(filterCategories(filterOptions)).map(option => (
            <ListItem key={option.label} option={option} />
          ))}
        </Options>
      </Dropdown>
      {categoriesFilter.size} filters selected
      <PickedCategories>
        {mergeCategories(Array.from(categoriesFilter)).map(category => (
          <p key={category}>
            {category}
            {category === 'Commodities' && ` (${dataVariant.label})`};
          </p>
        ))}
      </PickedCategories>
    </Row>
  );
};

const relationshipFilterOptions = [
  {
    value: ['fx', 'fx_other', 'fx_america', 'fx_asia', 'fx_emea'],
    label: 'FX(All)',
  },
  {
    value: ['commodity_agri', 'commodity_energy', 'commodity_industrial_metals', 'commodity_precious_metals'],
    label: 'Commodities',
  },
  { value: ['rates'], label: 'Rates' },
  { value: ['equities'], label: 'Equities' },
  { value: ['crypto'], label: 'Cryptocurrencies' },
];

export const RelationShipBuildingCategoriesFilter: React.FC = () => {
  const { dataVariant } = useDataVariantParam();
  const { categoriesFilter, updateCategoriesFilter } = useRelationshipProvider();
  const [tooManyItemsInfo, setTooManyItemsInfo] = useState<string | null>(null);

  const toggleOption = (option: CheckboxOption<string[]>, value: boolean) => {
    const addFilter = (filters: Set<string[]>, v: string[]) => {
      if (filters.size < 2) {
        return filters.add(v);
      }
      setTooManyItemsInfo('Select up to 2 filters.');
      return filters;
    };

    const deleteFilter = (filters: Set<string[]>, v: string[]) => {
      const filtered = new Set(
        Array.from(filters).filter(el => {
          return !isEqual(el, v);
        })
      );
      setTooManyItemsInfo(null);
      return filtered;
    };

    updateCategoriesFilter(filters => {
      if (value) {
        return addFilter(filters, option.value);
      }
      return deleteFilter(filters, option.value);
    });
  };

  const isChecked = (option: CheckboxOption<string[]>): boolean => {
    const index = Array.from(categoriesFilter).findIndex(el => isEqual(el, option.value));
    return index > -1;
  };

  const ListItem = ({ option }: { option: CheckboxOption<string[]> }) => {
    const onChange = (value: boolean) => {
      toggleOption(option, value);
    };
    const checked = isChecked(option);
    return <CheckboxListItem key={option.label} option={option} checked={checked} onChange={onChange} />;
  };

  useEffect(() => {
    if (categoriesFilter.size === 0) {
      updateCategoriesFilter(filters => {
        filters.add(relationshipFilterOptions[0].value);
      });
    }
  });
  const addDataSourceParamToLabel = (option: string) => {
    const index = relationshipFilterOptions.findIndex(el => el.label === option);
    if (index < 0) return relationshipFilterOptions;

    const newOption = { ...relationshipFilterOptions[index] };
    newOption.label = `${newOption.label} (${dataVariant.label})`;

    const tmp = [...relationshipFilterOptions];
    tmp[index] = newOption;
    return tmp;
  };
  return (
    <Row style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
      {Object.values(addDataSourceParamToLabel('Commodities')).map(option => (
        <ListItem key={option.label} option={option} />
      ))}
      {tooManyItemsInfo}
    </Row>
  );
};
export default Filters;
