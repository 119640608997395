import axios, { AxiosRequestConfig } from 'axios';
import { BASE_URL } from 'utils/constants';
import { Cookies } from 'react-cookie';
import { DataVariant } from 'hooks/useDataVariantParam/types';

export default abstract class Api {
  private static get authToken() {
    return new Cookies().get('authorizationToken');
  }

  protected api = axios.create({
    baseURL: BASE_URL,
    responseType: 'json',
  });

  constructor() {
    this.api.interceptors.request.use(request => {
      if (Api.authToken) request.headers.Authorization = `Bearer ${Api.authToken}`;
      return request;
    });
  }

  getWithDataVariantParam = async <T>(url: string, config?: AxiosRequestConfig) => {
    try {
      const dataVariant = localStorage.getItem('dataVariantType');
      if (dataVariant) {
        const value = JSON.parse(dataVariant).value;

        if (!value || !Object.values(DataVariant).includes(value)) {
          throw new Error("DataVariant doesn't exist or has incorrect value");
        }

        const configWithDataVariant = config
          ? {
              ...config,
              params: { ...config.params, data_variant: value },
            }
          : { params: { data_variant: value } };

        return this.api.get<T>(url, configWithDataVariant);
      }
      throw new Error("DataVariant doesn't exist");
    } catch (err) {
      return this.api.get<T>(url, config);
    }
  };
}
