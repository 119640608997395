import React from 'react';

const HeatMapHelpModal = () => (
  <p>
    In the <strong>Heat Map</strong>, Excalibur Pro compares time series that represent the major asset classes to
    discover what correlation relationships exist between them. It defaults to FX but can be changed to other asset
    classes. The Heat Map identifies the four most positively correlated pairs and the four most negatively correlated
    pairs. The <strong>Watchlist</strong> is where you can set up which pairs you would like to follow.
    <strong> Matrix</strong> allows you to view our entire universe of 10,000+ pairs and correlation values.
    <strong> Trending</strong> shows the amount of change in correlation value that has occurred between two instruments
    or time series by using different correlation calculation time periods. If you clicked <strong>Heat Map </strong>
    before <strong>Trending</strong>, this will show the instruments with the most changes. If you clicked{' '}
    <strong>Watchlist</strong> before <strong>Trending</strong>, this will show correlation changes on your{' '}
    <strong>Watchlist</strong>.
  </p>
);

export default HeatMapHelpModal;
