import Filters from 'components/common/Filters/Filters';
import { useCalculatorFilter } from 'components/common/HeatMapCorrelation/FilterContext';
import Spinner from 'components/common/Spinner';
import React, { useMemo, useEffect } from 'react';
import { Col, Row } from 'react-grid-system';
import { Views } from './constants';
import { HeatMapTilesWrapper } from './styles';
import { useHeatMapApi } from './useHeatMapApi';
import HeatMapHeader from '../HeatMapHeader';
import HeatMapTile from './HeatMapTile';

export const HeatMap: React.FC<{ disabled?: boolean }> = ({ disabled = false }) => {
  const { useHeatMapData } = useHeatMapApi();
  const {
    setSelectedInstrument,
    selectedInstrument,
    dateFilter,
    calculatorFilter,
    categoriesFilter,
    rolling,
    timeWindow,
    timeDecay,
  } = useCalculatorFilter();
  const categories = useMemo(() => Array.from(categoriesFilter).flat(), [categoriesFilter]);
  const { data, isValidating } = useHeatMapData({
    calculator: calculatorFilter.calculator,
    endDate: dateFilter.endDate,
    startDate: dateFilter.startDate,
    rolling,
    timeWindow,
    timeDecay,
    categories,
  });

  useEffect(() => {
    if (data) {
      const index = data.heat_map.findIndex(
        ({ instrument_y, instrument_x }) =>
          instrument_x.ticker === selectedInstrument?.instrument_x.ticker &&
          instrument_y.ticker === selectedInstrument.instrument_y.ticker
      );
      if (index > -1) return;
      const firstPair = data.heat_map[0];
      setSelectedInstrument(
        firstPair
          ? {
              instrument_x: firstPair.instrument_x,
              instrument_y: firstPair.instrument_y,
            }
          : null
      );
    }
  }, [data]);

  return (
    <Row style={{ height: '100%' }}>
      <Col>
        <Row style={{ flexDirection: 'column' }}>
          <HeatMapHeader disabled={disabled} />
          <Filters view={Views.HEAT_MAP} disabled={disabled} />
        </Row>

        {!isValidating && data ? (
          <HeatMapTilesWrapper>
            {data.heat_map.map(item => (
              <Col xs={3} key={item.instrument_x.ticker + item.instrument_y.ticker} width="calc(25% - 13px)">
                <HeatMapTile data={item} />
              </Col>
            ))}
          </HeatMapTilesWrapper>
        ) : (
          <Spinner />
        )}
      </Col>
    </Row>
  );
};
