import styled from 'styled-components';

export const MomentumPopupWrapper = styled.div`
  padding: 24px 0 12px 0;
`;
export const MomentumPopupChartsWrapper = styled.div`
  max-width: 100%;
  height: 100%;
  min-height: 40vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;

  & > div {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  #markov-chart,
  #moving-average-rsi-chart,
  #macd-chart,
  #returns-vs-volatility-chart {
    .recharts-tooltip-wrapper {
      top: -23px !important;
    }
  }

  #moving-average-rsi-chart {
    #chart-tooltip-wrapper {
      display: grid;
      grid-template-columns: 1fr max-content;
      grid-template-rows: repeat(3, max-content);
      column-gap: 4px;
      grid-auto-flow: column;
    }
  }
`;
export const MomentumPopupHeader = styled.h2`
  font-size: 16px;
  margin: 4px 0;
  color: ${({ theme }) => theme.palette.brand};
`;
