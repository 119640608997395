import React, { ReactElement, ReactNode, useContext } from 'react';
import { IOption } from 'types';
import { DropdownProps } from './types';
import { List, ListItem as StyledListItem, NestedList } from './styles';
import { Popover, Checkbox, PopoverContext } from 'components/common';

function Dropdown<T extends IOption>({
  label,
  disabled = false,
  options,
  children,
  ...props
}: DropdownProps<T>): ReactElement {
  return (
    <Popover label={label} disabled={disabled} {...props}>
      {options && !!options.length && <List role="listbox">{children}</List>}
    </Popover>
  );
}

export const StringListItem = ({ option, onChange }: { option: any; onChange: any }) => {
  const { closePopover } = useContext(PopoverContext);

  return (
    <StyledListItem
      role="option"
      onClick={(event: any) => {
        onChange(event, option);
        closePopover();
      }}
      data-testid={`option-${option.label}`}
    >
      {option.label}
    </StyledListItem>
  );
};

export function CheckboxListItem<T extends IOption<string[]>>({
  option,
  onChange,
  checked,
}: {
  option: T;
  onChange: (checked: boolean) => void;
  checked: boolean;
}) {
  return (
    <StyledListItem role="option">
      <Checkbox
        checked={checked}
        onChange={event => {
          onChange(event.target.checked);
        }}
        data-testid={`option-${option.label}`}
      >
        {option.label}
      </Checkbox>
    </StyledListItem>
  );
}

export function CheckboxListItemGroup<T extends IOption<string[]>>({
  option,
  onChange,
  checked,
  children,
}: {
  option: T;
  onChange: (checked: boolean) => void;
  checked: boolean;
  children: ReactNode;
}) {
  return (
    <StyledListItem role="option">
      <Checkbox
        checked={checked}
        onChange={event => {
          onChange(event.target.checked);
        }}
        data-testid={`option-${option.label}`}
      >
        {option.label}
      </Checkbox>
      <NestedList role="listbox">{children}</NestedList>
    </StyledListItem>
  );
}

export default Dropdown;
