import { IMarkovStateResponse } from 'api/types';
import zipWith from 'lodash.zipwith';
import { min } from 'date-fns';
import { RESPONSE_DATE_REGEX } from '../RegimeChangeApi';

interface IMarkovStateChartData {
  date: string;
  bull: string;
  bear: string;
  priceChanges: string;
}

export class MarkovState {
  public static fromMarkovStateResponse(markovStateResponse?: IMarkovStateResponse) {
    if (markovStateResponse) {
      const { regime } = markovStateResponse;
      const priceChange = markovStateResponse.price_change;
      const mergedMovingAverages = zipWith(regime, priceChange, (r, p) => {
        if (!r || !RESPONSE_DATE_REGEX.test(r.date) || !p || !RESPONSE_DATE_REGEX.test(p.date)) return undefined;
        return {
          date: r.date,
          bull: r.bull,
          bear: r.bear,
          priceChanges: p.value,
        };
      });
      const filteredMergedMovingAverages = mergedMovingAverages.filter((el): el is IMarkovStateChartData => !!el);
      return new MarkovState(filteredMergedMovingAverages);
    }
    return new MarkovState([]);
  }

  private constructor(public data: IMarkovStateChartData[]) {}

  get chartData() {
    return this.data;
  }

  get minDate() {
    return min(this.data.map(element => new Date(element.date)));
  }

  get csvData() {
    return this.data.map(element => [...Object.values(element)]);
  }

  get priceChanges() {
    return this.data.map(element => ({
      date: element.date,
      priceChanges: element.priceChanges,
    }));
  }

  get priceChangesValues() {
    return this.priceChanges.map(e => parseFloat(e.priceChanges));
  }

  get minPriceChange() {
    return Math.min(...this.priceChangesValues);
  }

  get maxPriceChange() {
    return Math.max(...this.priceChangesValues);
  }
}
