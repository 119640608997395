import React from 'react';
import {
  DeviationChartImg,
  StyledArrow,
  StyledChartInfo,
  StyledDeviationChartWrapper,
  StyledValue,
} from './Deviation.styles';
import DeviationImg from 'assets/deviation-chart.png';
import ArrowImg from 'assets/deviation-chart-arrow.png';
import { IDeviationResponse } from 'api/types';
import theme from '../../../styles/theme';

interface DeviationChartBodyProps {
  data: IDeviationResponse;
}

export const DeviationChartBody: React.FC<DeviationChartBodyProps> = ({ data }) => {
  return (
    <StyledDeviationChartWrapper>
      <DeviationChartImg src={DeviationImg} alt="deviation chart" />
      <StyledArrow src={ArrowImg} />
      <StyledValue top={47} left={50} value="50" color="#fff">
        50%
      </StyledValue>
      <StyledValue top={70} left={50} value="90" color="#fff">
        90%
      </StyledValue>

      <StyledValue top={57} left={13} value={data.std_dev_minus_2}>
        {data.std_dev_minus_2}
      </StyledValue>
      <StyledValue top={30} left={21} value={data.std_dev_minus}>
        {data.std_dev_minus}
      </StyledValue>
      <StyledValue top={-13} left={50} value={data.median}>
        {data.median}
      </StyledValue>
      <StyledValue top={30} left={78} value={data.std_dev_plus}>
        {data.std_dev_plus}
      </StyledValue>
      <StyledValue top={57} left={88} value={data.std_dev_plus_2}>
        {data.std_dev_plus_2}
      </StyledValue>
      <StyledChartInfo>
        <span>
          5<sup>th</sup>
        </span>
        <span>
          25<sup>th</sup>
        </span>
        <span style={{ color: theme.palette.brand }}>Median</span>
        <span>
          75<sup>th</sup>
        </span>
        <span>
          100<sup>th</sup>
        </span>
      </StyledChartInfo>
    </StyledDeviationChartWrapper>
  );
};
