import React, { createContext, useContext, useEffect, useState } from 'react';
import useSWR from 'swr';
import { IMetaDataResponse } from 'api/types';
import { metadataApi } from './MetadataApi';

interface IMetadataContext {
  latestDate: string | null;
  setLatestDate: (value: string) => void;
}

export const MetadataContext = createContext<IMetadataContext>({
  setLatestDate: () => {},
  latestDate: null,
});

export const useMetadata = () => useContext(MetadataContext);

export const MetadataProvider: React.FC = ({ children }) => {
  const [latestDate, setLatestDate] = useState<string | null>(null);

  const { data } = useSWR<IMetaDataResponse>('get_metadata', () => metadataApi.getMetaData());

  useEffect(() => {
    if (data) {
      setLatestDate(data.latest_date);
    }
  }, [data]);
  return <MetadataContext.Provider value={{ latestDate, setLatestDate }}>{children}</MetadataContext.Provider>;
};
